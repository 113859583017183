export const PROVINCE = [
  {
      "id": 1,
      "name": "Hà Nội",
      "region": "north",
      "created_at": "2024-01-28T17:20:42.137Z",
      "updated_at": "2024-10-29T10:15:06.378Z",
      "lat": 21.0284793,
      "lon": 105.8500046,
      "deleted": null,
      "slug": "ha-noi",
      "district": [
          {
              "id": 1,
              "name": "Ba Đình",
              "province_id": 1,
              "created_at": "2024-01-28T17:20:42.975Z",
              "updated_at": "2024-10-29T10:15:06.845Z",
              "popular": true,
              "slug": "ba-dinh",
              "deleted": null
          },
          {
              "id": 2,
              "name": "Hoàn Kiếm",
              "province_id": 1,
              "created_at": "2024-01-28T17:20:42.991Z",
              "updated_at": "2024-10-29T10:15:06.852Z",
              "popular": true,
              "slug": "hoan-kiem",
              "deleted": null
          },
          {
              "id": 3,
              "name": "Tây Hồ",
              "province_id": 1,
              "created_at": "2024-01-28T17:20:43.003Z",
              "updated_at": "2024-10-29T10:15:06.857Z",
              "popular": true,
              "slug": "tay-ho",
              "deleted": null
          },
          {
              "id": 4,
              "name": "Long Biên",
              "province_id": 1,
              "created_at": "2024-01-28T17:20:43.016Z",
              "updated_at": "2024-10-29T10:15:06.862Z",
              "popular": true,
              "slug": "long-bien",
              "deleted": null
          },
          {
              "id": 5,
              "name": "Cầu Giấy",
              "province_id": 1,
              "created_at": "2024-01-28T17:20:43.033Z",
              "updated_at": "2024-10-29T10:15:06.870Z",
              "popular": true,
              "slug": "cau-giay",
              "deleted": null
          },
          {
              "id": 6,
              "name": "Đống Đa",
              "province_id": 1,
              "created_at": "2024-01-28T17:20:43.046Z",
              "updated_at": "2024-10-29T10:15:06.876Z",
              "popular": true,
              "slug": "dong-da",
              "deleted": null
          },
          {
              "id": 7,
              "name": "Hai Bà Trưng",
              "province_id": 1,
              "created_at": "2024-01-28T17:20:43.060Z",
              "updated_at": "2024-10-29T10:15:06.883Z",
              "popular": true,
              "slug": "hai-ba-trung",
              "deleted": null
          },
          {
              "id": 8,
              "name": "Hoàng Mai",
              "province_id": 1,
              "created_at": "2024-01-28T17:20:43.076Z",
              "updated_at": "2024-10-29T10:15:06.890Z",
              "popular": true,
              "slug": "hoang-mai",
              "deleted": null
          },
          {
              "id": 9,
              "name": "Thanh Xuân",
              "province_id": 1,
              "created_at": "2024-01-28T17:20:43.087Z",
              "updated_at": "2024-10-29T10:15:06.896Z",
              "popular": true,
              "slug": "thanh-xuan",
              "deleted": null
          },
          {
              "id": 16,
              "name": "Sóc Sơn",
              "province_id": 1,
              "created_at": "2024-01-28T17:20:43.100Z",
              "updated_at": "2024-10-29T10:15:06.901Z",
              "popular": false,
              "slug": "soc-son",
              "deleted": null
          },
          {
              "id": 17,
              "name": "Đông Anh",
              "province_id": 1,
              "created_at": "2024-01-28T17:20:43.116Z",
              "updated_at": "2024-10-29T10:15:06.905Z",
              "popular": false,
              "slug": "dong-anh",
              "deleted": null
          },
          {
              "id": 18,
              "name": "Gia Lâm",
              "province_id": 1,
              "created_at": "2024-01-28T17:20:43.129Z",
              "updated_at": "2024-10-29T10:15:06.913Z",
              "popular": false,
              "slug": "gia-lam",
              "deleted": null
          },
          {
              "id": 19,
              "name": "Nam Từ Liêm",
              "province_id": 1,
              "created_at": "2024-01-28T17:20:43.143Z",
              "updated_at": "2024-10-29T10:15:06.920Z",
              "popular": true,
              "slug": "nam-tu-liem",
              "deleted": null
          },
          {
              "id": 20,
              "name": "Thanh Trì",
              "province_id": 1,
              "created_at": "2024-01-28T17:20:43.155Z",
              "updated_at": "2024-10-29T10:15:06.926Z",
              "popular": true,
              "slug": "thanh-tri",
              "deleted": null
          },
          {
              "id": 21,
              "name": "Bắc Từ Liêm",
              "province_id": 1,
              "created_at": "2024-01-28T17:20:43.168Z",
              "updated_at": "2024-10-29T10:15:06.930Z",
              "popular": true,
              "slug": "bac-tu-liem",
              "deleted": null
          },
          {
              "id": 250,
              "name": "Mê Linh",
              "province_id": 1,
              "created_at": "2024-01-28T17:20:43.184Z",
              "updated_at": "2024-10-29T10:15:06.935Z",
              "popular": true,
              "slug": "me-linh",
              "deleted": null
          },
          {
              "id": 268,
              "name": "Hà Đông",
              "province_id": 1,
              "created_at": "2024-01-28T17:20:43.196Z",
              "updated_at": "2024-10-29T10:15:06.939Z",
              "popular": true,
              "slug": "ha-dong",
              "deleted": null
          },
          {
              "id": 269,
              "name": "Sơn Tây",
              "province_id": 1,
              "created_at": "2024-01-28T17:20:43.211Z",
              "updated_at": "2024-10-29T10:15:06.943Z",
              "popular": false,
              "slug": "son-tay",
              "deleted": null
          },
          {
              "id": 271,
              "name": "Ba Vì",
              "province_id": 1,
              "created_at": "2024-01-28T17:20:43.223Z",
              "updated_at": "2024-10-29T10:15:06.947Z",
              "popular": false,
              "slug": "ba-vi",
              "deleted": null
          },
          {
              "id": 272,
              "name": "Phúc Thọ",
              "province_id": 1,
              "created_at": "2024-01-28T17:20:43.238Z",
              "updated_at": "2024-10-29T10:15:06.952Z",
              "popular": false,
              "slug": "phuc-tho",
              "deleted": null
          },
          {
              "id": 273,
              "name": "Đan Phượng",
              "province_id": 1,
              "created_at": "2024-01-28T17:20:43.250Z",
              "updated_at": "2024-10-29T10:15:06.956Z",
              "popular": false,
              "slug": "dan-phuong",
              "deleted": null
          },
          {
              "id": 274,
              "name": "Hoài Đức",
              "province_id": 1,
              "created_at": "2024-01-28T17:20:43.264Z",
              "updated_at": "2024-10-29T10:15:06.961Z",
              "popular": false,
              "slug": "hoai-duc",
              "deleted": null
          },
          {
              "id": 275,
              "name": "Quốc Oai",
              "province_id": 1,
              "created_at": "2024-01-28T17:20:43.279Z",
              "updated_at": "2024-10-29T10:15:06.966Z",
              "popular": false,
              "slug": "quoc-oai",
              "deleted": null
          },
          {
              "id": 276,
              "name": "Thạch Thất",
              "province_id": 1,
              "created_at": "2024-01-28T17:20:43.291Z",
              "updated_at": "2024-10-29T10:15:06.972Z",
              "popular": false,
              "slug": "thach-that",
              "deleted": null
          },
          {
              "id": 277,
              "name": "Chương Mỹ",
              "province_id": 1,
              "created_at": "2024-01-28T17:20:43.307Z",
              "updated_at": "2024-10-29T10:15:06.977Z",
              "popular": false,
              "slug": "chuong-my",
              "deleted": null
          },
          {
              "id": 278,
              "name": "Thanh Oai",
              "province_id": 1,
              "created_at": "2024-01-28T17:20:43.319Z",
              "updated_at": "2024-10-29T10:15:06.982Z",
              "popular": false,
              "slug": "thanh-oai",
              "deleted": null
          },
          {
              "id": 279,
              "name": "Thường Tín",
              "province_id": 1,
              "created_at": "2024-01-28T17:20:43.332Z",
              "updated_at": "2024-10-29T10:15:06.987Z",
              "popular": false,
              "slug": "thuong-tin",
              "deleted": null
          },
          {
              "id": 280,
              "name": "Phú Xuyên",
              "province_id": 1,
              "created_at": "2024-01-28T17:20:43.348Z",
              "updated_at": "2024-10-29T10:15:06.993Z",
              "popular": false,
              "slug": "phu-xuyen",
              "deleted": null
          },
          {
              "id": 281,
              "name": "Ứng Hòa",
              "province_id": 1,
              "created_at": "2024-01-28T17:20:43.360Z",
              "updated_at": "2024-10-29T10:15:06.998Z",
              "popular": false,
              "slug": "ung-hoa",
              "deleted": null
          },
          {
              "id": 282,
              "name": "Mỹ Đức",
              "province_id": 1,
              "created_at": "2024-01-28T17:20:43.374Z",
              "updated_at": "2024-10-29T10:15:07.004Z",
              "popular": false,
              "slug": "my-duc",
              "deleted": null
          }
      ]
  },
  {
      "id": 2,
      "name": "Hà Giang",
      "region": "north",
      "created_at": "2024-01-28T17:20:42.169Z",
      "updated_at": "2024-10-29T10:15:06.384Z",
      "lat": 22.802558,
      "lon": 104.978449,
      "deleted": null,
      "slug": "ha-giang",
      "district": [
          {
              "id": 24,
              "name": "Thành phố Hà Giang",
              "province_id": 2,
              "created_at": "2024-01-28T17:20:43.386Z",
              "updated_at": "2024-10-29T10:15:07.009Z",
              "popular": false,
              "slug": "thanh-pho-ha-giang",
              "deleted": null
          },
          {
              "id": 26,
              "name": "Huyện Đồng Văn",
              "province_id": 2,
              "created_at": "2024-01-28T17:20:43.399Z",
              "updated_at": "2024-10-29T10:15:07.014Z",
              "popular": false,
              "slug": "huyen-dong-van",
              "deleted": null
          },
          {
              "id": 27,
              "name": "Huyện Mèo Vạc",
              "province_id": 2,
              "created_at": "2024-01-28T17:20:43.412Z",
              "updated_at": "2024-10-29T10:15:07.019Z",
              "popular": false,
              "slug": "huyen-meo-vac",
              "deleted": null
          },
          {
              "id": 28,
              "name": "Huyện Yên Minh",
              "province_id": 2,
              "created_at": "2024-01-28T17:20:43.426Z",
              "updated_at": "2024-10-29T10:15:07.025Z",
              "popular": false,
              "slug": "huyen-yen-minh",
              "deleted": null
          },
          {
              "id": 29,
              "name": "Huyện Quản Bạ",
              "province_id": 2,
              "created_at": "2024-01-28T17:20:43.439Z",
              "updated_at": "2024-10-29T10:15:07.030Z",
              "popular": false,
              "slug": "huyen-quan-ba",
              "deleted": null
          },
          {
              "id": 30,
              "name": "Huyện Vị Xuyên",
              "province_id": 2,
              "created_at": "2024-01-28T17:20:43.452Z",
              "updated_at": "2024-10-29T10:15:07.036Z",
              "popular": false,
              "slug": "huyen-vi-xuyen",
              "deleted": null
          },
          {
              "id": 31,
              "name": "Huyện Bắc Mê",
              "province_id": 2,
              "created_at": "2024-01-28T17:20:43.466Z",
              "updated_at": "2024-10-29T10:15:07.041Z",
              "popular": false,
              "slug": "huyen-bac-me",
              "deleted": null
          },
          {
              "id": 32,
              "name": "Huyện Hoàng Su Phì",
              "province_id": 2,
              "created_at": "2024-01-28T17:20:43.480Z",
              "updated_at": "2024-10-29T10:15:07.047Z",
              "popular": false,
              "slug": "huyen-hoang-su-phi",
              "deleted": null
          },
          {
              "id": 33,
              "name": "Huyện Xín Mần",
              "province_id": 2,
              "created_at": "2024-01-28T17:20:43.492Z",
              "updated_at": "2024-10-29T10:15:07.053Z",
              "popular": false,
              "slug": "huyen-xin-man",
              "deleted": null
          },
          {
              "id": 34,
              "name": "Huyện Bắc Quang",
              "province_id": 2,
              "created_at": "2024-01-28T17:20:43.504Z",
              "updated_at": "2024-10-29T10:15:07.060Z",
              "popular": false,
              "slug": "huyen-bac-quang",
              "deleted": null
          },
          {
              "id": 35,
              "name": "Huyện Quang Bình",
              "province_id": 2,
              "created_at": "2024-01-28T17:20:43.516Z",
              "updated_at": "2024-10-29T10:15:07.067Z",
              "popular": false,
              "slug": "huyen-quang-binh",
              "deleted": null
          }
      ]
  },
  {
      "id": 4,
      "name": "Cao Bằng",
      "region": "north",
      "created_at": "2024-01-28T17:20:42.187Z",
      "updated_at": "2024-10-29T10:15:06.391Z",
      "lat": 22.665575,
      "lon": 106.25784,
      "deleted": null,
      "slug": "cao-bang",
      "district": [
          {
              "id": 40,
              "name": "Thành phố Cao Bằng",
              "province_id": 4,
              "created_at": "2024-01-28T17:20:43.532Z",
              "updated_at": "2024-10-29T10:15:07.072Z",
              "popular": false,
              "slug": "thanh-pho-cao-bang",
              "deleted": null
          },
          {
              "id": 42,
              "name": "Huyện Bảo Lâm",
              "province_id": 4,
              "created_at": "2024-01-28T17:20:43.543Z",
              "updated_at": "2024-10-29T10:15:07.079Z",
              "popular": false,
              "slug": "huyen-bao-lam",
              "deleted": null
          },
          {
              "id": 43,
              "name": "Huyện Bảo Lạc",
              "province_id": 4,
              "created_at": "2024-01-28T17:20:43.556Z",
              "updated_at": "2024-10-29T10:15:07.085Z",
              "popular": false,
              "slug": "huyen-bao-lac",
              "deleted": null
          },
          {
              "id": 45,
              "name": "Huyện Hà Quảng",
              "province_id": 4,
              "created_at": "2024-01-28T17:20:43.570Z",
              "updated_at": "2024-10-29T10:15:07.090Z",
              "popular": false,
              "slug": "huyen-ha-quang",
              "deleted": null
          },
          {
              "id": 47,
              "name": "Huyện Trùng Khánh",
              "province_id": 4,
              "created_at": "2024-01-28T17:20:43.584Z",
              "updated_at": "2024-10-29T10:15:07.096Z",
              "popular": false,
              "slug": "huyen-trung-khanh",
              "deleted": null
          },
          {
              "id": 48,
              "name": "Huyện Hạ Lang",
              "province_id": 4,
              "created_at": "2024-01-28T17:20:43.601Z",
              "updated_at": "2024-10-29T10:15:07.101Z",
              "popular": false,
              "slug": "huyen-ha-lang",
              "deleted": null
          },
          {
              "id": 49,
              "name": "Huyện Quảng Hòa",
              "province_id": 4,
              "created_at": "2024-01-28T17:20:43.613Z",
              "updated_at": "2024-10-29T10:15:07.107Z",
              "popular": false,
              "slug": "huyen-quang-hoa",
              "deleted": null
          },
          {
              "id": 51,
              "name": "Huyện Hoà An",
              "province_id": 4,
              "created_at": "2024-01-28T17:20:43.624Z",
              "updated_at": "2024-10-29T10:15:07.111Z",
              "popular": false,
              "slug": "huyen-hoa-an",
              "deleted": null
          },
          {
              "id": 52,
              "name": "Huyện Nguyên Bình",
              "province_id": 4,
              "created_at": "2024-01-28T17:20:43.640Z",
              "updated_at": "2024-10-29T10:15:07.117Z",
              "popular": false,
              "slug": "huyen-nguyen-binh",
              "deleted": null
          },
          {
              "id": 53,
              "name": "Huyện Thạch An",
              "province_id": 4,
              "created_at": "2024-01-28T17:20:43.652Z",
              "updated_at": "2024-10-29T10:15:07.122Z",
              "popular": false,
              "slug": "huyen-thach-an",
              "deleted": null
          }
      ]
  },
  {
      "id": 6,
      "name": "Bắc Kạn",
      "region": "north",
      "created_at": "2024-01-28T17:20:42.198Z",
      "updated_at": "2024-10-29T10:15:06.396Z",
      "lat": 22.148512,
      "lon": 105.834722,
      "deleted": null,
      "slug": "bac-kan",
      "district": [
          {
              "id": 58,
              "name": "Thành Phố Bắc Kạn",
              "province_id": 6,
              "created_at": "2024-01-28T17:20:43.664Z",
              "updated_at": "2024-10-29T10:15:07.127Z",
              "popular": false,
              "slug": "thanh-pho-bac-kan",
              "deleted": null
          },
          {
              "id": 60,
              "name": "Huyện Pác Nặm",
              "province_id": 6,
              "created_at": "2024-01-28T17:20:43.680Z",
              "updated_at": "2024-10-29T10:15:07.133Z",
              "popular": false,
              "slug": "huyen-pac-nam",
              "deleted": null
          },
          {
              "id": 61,
              "name": "Huyện Ba Bể",
              "province_id": 6,
              "created_at": "2024-01-28T17:20:43.691Z",
              "updated_at": "2024-10-29T10:15:07.138Z",
              "popular": false,
              "slug": "huyen-ba-be",
              "deleted": null
          },
          {
              "id": 62,
              "name": "Huyện Ngân Sơn",
              "province_id": 6,
              "created_at": "2024-01-28T17:20:43.704Z",
              "updated_at": "2024-10-29T10:15:07.144Z",
              "popular": false,
              "slug": "huyen-ngan-son",
              "deleted": null
          },
          {
              "id": 63,
              "name": "Huyện Bạch Thông",
              "province_id": 6,
              "created_at": "2024-01-28T17:20:43.716Z",
              "updated_at": "2024-10-29T10:15:07.150Z",
              "popular": false,
              "slug": "huyen-bach-thong",
              "deleted": null
          },
          {
              "id": 64,
              "name": "Huyện Chợ Đồn",
              "province_id": 6,
              "created_at": "2024-01-28T17:20:43.728Z",
              "updated_at": "2024-10-29T10:15:07.156Z",
              "popular": false,
              "slug": "huyen-cho-don",
              "deleted": null
          },
          {
              "id": 65,
              "name": "Huyện Chợ Mới",
              "province_id": 6,
              "created_at": "2024-01-28T17:20:43.742Z",
              "updated_at": "2024-10-29T10:15:07.161Z",
              "popular": false,
              "slug": "huyen-cho-moi",
              "deleted": null
          },
          {
              "id": 66,
              "name": "Huyện Na Rì",
              "province_id": 6,
              "created_at": "2024-01-28T17:20:43.752Z",
              "updated_at": "2024-10-29T10:15:07.166Z",
              "popular": false,
              "slug": "huyen-na-ri",
              "deleted": null
          }
      ]
  },
  {
      "id": 8,
      "name": "Tuyên Quang",
      "region": "north",
      "created_at": "2024-01-28T17:20:42.212Z",
      "updated_at": "2024-10-29T10:15:06.402Z",
      "lat": 21.823811,
      "lon": 105.216866,
      "deleted": null,
      "slug": "tuyen-quang",
      "district": [
          {
              "id": 70,
              "name": "Thành phố Tuyên Quang",
              "province_id": 8,
              "created_at": "2024-01-28T17:20:43.764Z",
              "updated_at": "2024-10-29T10:15:07.171Z",
              "popular": false,
              "slug": "thanh-pho-tuyen-quang",
              "deleted": null
          },
          {
              "id": 71,
              "name": "Huyện Lâm Bình",
              "province_id": 8,
              "created_at": "2024-01-28T17:20:43.776Z",
              "updated_at": "2024-10-29T10:15:07.177Z",
              "popular": false,
              "slug": "huyen-lam-binh",
              "deleted": null
          },
          {
              "id": 72,
              "name": "Huyện Na Hang",
              "province_id": 8,
              "created_at": "2024-01-28T17:20:43.787Z",
              "updated_at": "2024-10-29T10:15:07.182Z",
              "popular": false,
              "slug": "huyen-na-hang",
              "deleted": null
          },
          {
              "id": 73,
              "name": "Huyện Chiêm Hóa",
              "province_id": 8,
              "created_at": "2024-01-28T17:20:43.800Z",
              "updated_at": "2024-10-29T10:15:07.188Z",
              "popular": false,
              "slug": "huyen-chiem-hoa",
              "deleted": null
          },
          {
              "id": 74,
              "name": "Huyện Hàm Yên",
              "province_id": 8,
              "created_at": "2024-01-28T17:20:43.816Z",
              "updated_at": "2024-10-29T10:15:07.193Z",
              "popular": false,
              "slug": "huyen-ham-yen",
              "deleted": null
          },
          {
              "id": 75,
              "name": "Huyện Yên Sơn",
              "province_id": 8,
              "created_at": "2024-01-28T17:20:43.829Z",
              "updated_at": "2024-10-29T10:15:07.199Z",
              "popular": false,
              "slug": "huyen-yen-son",
              "deleted": null
          },
          {
              "id": 76,
              "name": "Huyện Sơn Dương",
              "province_id": 8,
              "created_at": "2024-01-28T17:20:43.844Z",
              "updated_at": "2024-10-29T10:15:07.203Z",
              "popular": false,
              "slug": "huyen-son-duong",
              "deleted": null
          }
      ]
  },
  {
      "id": 10,
      "name": "Lào Cai",
      "region": "north",
      "created_at": "2024-01-28T17:20:42.224Z",
      "updated_at": "2024-10-29T10:15:06.408Z",
      "lat": 22.486913,
      "lon": 103.970315,
      "deleted": null,
      "slug": "lao-cai",
      "district": [
          {
              "id": 80,
              "name": "Thành phố Lào Cai",
              "province_id": 10,
              "created_at": "2024-01-28T17:20:43.861Z",
              "updated_at": "2024-10-29T10:15:07.209Z",
              "popular": false,
              "slug": "thanh-pho-lao-cai",
              "deleted": null
          },
          {
              "id": 82,
              "name": "Huyện Bát Xát",
              "province_id": 10,
              "created_at": "2024-01-28T17:20:43.872Z",
              "updated_at": "2024-10-29T10:15:07.215Z",
              "popular": false,
              "slug": "huyen-bat-xat",
              "deleted": null
          },
          {
              "id": 83,
              "name": "Huyện Mường Khương",
              "province_id": 10,
              "created_at": "2024-01-28T17:20:43.884Z",
              "updated_at": "2024-10-29T10:15:07.221Z",
              "popular": false,
              "slug": "huyen-muong-khuong",
              "deleted": null
          },
          {
              "id": 84,
              "name": "Huyện Si Ma Cai",
              "province_id": 10,
              "created_at": "2024-01-28T17:20:43.897Z",
              "updated_at": "2024-10-29T10:15:07.227Z",
              "popular": false,
              "slug": "huyen-si-ma-cai",
              "deleted": null
          },
          {
              "id": 85,
              "name": "Huyện Bắc Hà",
              "province_id": 10,
              "created_at": "2024-01-28T17:20:43.908Z",
              "updated_at": "2024-10-29T10:15:07.233Z",
              "popular": false,
              "slug": "huyen-bac-ha",
              "deleted": null
          },
          {
              "id": 86,
              "name": "Huyện Bảo Thắng",
              "province_id": 10,
              "created_at": "2024-01-28T17:20:43.924Z",
              "updated_at": "2024-10-29T10:15:07.238Z",
              "popular": false,
              "slug": "huyen-bao-thang",
              "deleted": null
          },
          {
              "id": 87,
              "name": "Huyện Bảo Yên",
              "province_id": 10,
              "created_at": "2024-01-28T17:20:43.939Z",
              "updated_at": "2024-10-29T10:15:07.243Z",
              "popular": false,
              "slug": "huyen-bao-yen",
              "deleted": null
          },
          {
              "id": 88,
              "name": "Thị xã Sa Pa",
              "province_id": 10,
              "created_at": "2024-01-28T17:20:43.951Z",
              "updated_at": "2024-10-29T10:15:07.248Z",
              "popular": false,
              "slug": "thi-xa-sa-pa",
              "deleted": null
          },
          {
              "id": 89,
              "name": "Huyện Văn Bàn",
              "province_id": 10,
              "created_at": "2024-01-28T17:20:43.965Z",
              "updated_at": "2024-10-29T10:15:07.254Z",
              "popular": false,
              "slug": "huyen-van-ban",
              "deleted": null
          }
      ]
  },
  {
      "id": 11,
      "name": "Điện Biên",
      "region": "north",
      "created_at": "2024-01-28T17:20:42.238Z",
      "updated_at": "2024-10-29T10:15:06.414Z",
      "lat": 21.38602,
      "lon": 103.016618,
      "deleted": null,
      "slug": "dien-bien",
      "district": [
          {
              "id": 94,
              "name": "Thành phố Điện Biên Phủ",
              "province_id": 11,
              "created_at": "2024-01-28T17:20:43.984Z",
              "updated_at": "2024-10-29T10:15:07.259Z",
              "popular": false,
              "slug": "thanh-pho-dien-bien-phu",
              "deleted": null
          },
          {
              "id": 95,
              "name": "Thị xã Mường Lay",
              "province_id": 11,
              "created_at": "2024-01-28T17:20:43.997Z",
              "updated_at": "2024-10-29T10:15:07.265Z",
              "popular": false,
              "slug": "thi-xa-muong-lay",
              "deleted": null
          },
          {
              "id": 96,
              "name": "Huyện Mường Nhé",
              "province_id": 11,
              "created_at": "2024-01-28T17:20:44.011Z",
              "updated_at": "2024-10-29T10:15:07.270Z",
              "popular": false,
              "slug": "huyen-muong-nhe",
              "deleted": null
          },
          {
              "id": 97,
              "name": "Huyện Mường Chà",
              "province_id": 11,
              "created_at": "2024-01-28T17:20:44.024Z",
              "updated_at": "2024-10-29T10:15:07.276Z",
              "popular": false,
              "slug": "huyen-muong-cha",
              "deleted": null
          },
          {
              "id": 98,
              "name": "Huyện Tủa Chùa",
              "province_id": 11,
              "created_at": "2024-01-28T17:20:44.036Z",
              "updated_at": "2024-10-29T10:15:07.280Z",
              "popular": false,
              "slug": "huyen-tua-chua",
              "deleted": null
          },
          {
              "id": 99,
              "name": "Huyện Tuần Giáo",
              "province_id": 11,
              "created_at": "2024-01-28T17:20:44.050Z",
              "updated_at": "2024-10-29T10:15:07.287Z",
              "popular": false,
              "slug": "huyen-tuan-giao",
              "deleted": null
          },
          {
              "id": 100,
              "name": "Huyện Điện Biên",
              "province_id": 11,
              "created_at": "2024-01-28T17:20:44.065Z",
              "updated_at": "2024-10-29T10:15:07.292Z",
              "popular": false,
              "slug": "huyen-dien-bien",
              "deleted": null
          },
          {
              "id": 101,
              "name": "Huyện Điện Biên Đông",
              "province_id": 11,
              "created_at": "2024-01-28T17:20:44.081Z",
              "updated_at": "2024-10-29T10:15:07.300Z",
              "popular": false,
              "slug": "huyen-dien-bien-dong",
              "deleted": null
          },
          {
              "id": 102,
              "name": "Huyện Mường Ảng",
              "province_id": 11,
              "created_at": "2024-01-28T17:20:44.093Z",
              "updated_at": "2024-10-29T10:15:07.305Z",
              "popular": false,
              "slug": "huyen-muong-ang",
              "deleted": null
          },
          {
              "id": 103,
              "name": "Huyện Nậm Pồ",
              "province_id": 11,
              "created_at": "2024-01-28T17:20:44.109Z",
              "updated_at": "2024-10-29T10:15:07.311Z",
              "popular": false,
              "slug": "huyen-nam-po",
              "deleted": null
          }
      ]
  },
  {
      "id": 12,
      "name": "Lai Châu",
      "region": "north",
      "created_at": "2024-01-28T17:20:42.251Z",
      "updated_at": "2024-10-29T10:15:06.422Z",
      "lat": 22.401093,
      "lon": 103.471098,
      "deleted": null,
      "slug": "lai-chau",
      "district": [
          {
              "id": 105,
              "name": "Thành phố Lai Châu",
              "province_id": 12,
              "created_at": "2024-01-28T17:20:44.127Z",
              "updated_at": "2024-10-29T10:15:07.316Z",
              "popular": false,
              "slug": "thanh-pho-lai-chau",
              "deleted": null
          },
          {
              "id": 106,
              "name": "Huyện Tam Đường",
              "province_id": 12,
              "created_at": "2024-01-28T17:20:44.140Z",
              "updated_at": "2024-10-29T10:15:07.321Z",
              "popular": false,
              "slug": "huyen-tam-duong",
              "deleted": null
          },
          {
              "id": 107,
              "name": "Huyện Mường Tè",
              "province_id": 12,
              "created_at": "2024-01-28T17:20:44.154Z",
              "updated_at": "2024-10-29T10:15:07.327Z",
              "popular": false,
              "slug": "huyen-muong-te",
              "deleted": null
          },
          {
              "id": 108,
              "name": "Huyện Sìn Hồ",
              "province_id": 12,
              "created_at": "2024-01-28T17:20:44.168Z",
              "updated_at": "2024-10-29T10:15:07.332Z",
              "popular": false,
              "slug": "huyen-sin-ho",
              "deleted": null
          },
          {
              "id": 109,
              "name": "Huyện Phong Thổ",
              "province_id": 12,
              "created_at": "2024-01-28T17:20:44.184Z",
              "updated_at": "2024-10-29T10:15:07.337Z",
              "popular": false,
              "slug": "huyen-phong-tho",
              "deleted": null
          },
          {
              "id": 110,
              "name": "Huyện Than Uyên",
              "province_id": 12,
              "created_at": "2024-01-28T17:20:44.201Z",
              "updated_at": "2024-10-29T10:15:07.344Z",
              "popular": false,
              "slug": "huyen-than-uyen",
              "deleted": null
          },
          {
              "id": 111,
              "name": "Huyện Tân Uyên",
              "province_id": 12,
              "created_at": "2024-01-28T17:20:44.215Z",
              "updated_at": "2024-10-29T10:15:07.348Z",
              "popular": false,
              "slug": "huyen-tan-uyen",
              "deleted": null
          },
          {
              "id": 112,
              "name": "Huyện Nậm Nhùn",
              "province_id": 12,
              "created_at": "2024-01-28T17:20:44.228Z",
              "updated_at": "2024-10-29T10:15:07.352Z",
              "popular": false,
              "slug": "huyen-nam-nhun",
              "deleted": null
          }
      ]
  },
  {
      "id": 14,
      "name": "Sơn La",
      "region": "north",
      "created_at": "2024-01-28T17:20:42.265Z",
      "updated_at": "2024-10-29T10:15:06.329Z",
      "lat": 21.328668,
      "lon": 103.914399,
      "deleted": null,
      "slug": "son-la",
      "district": [
          {
              "id": 116,
              "name": "Thành phố Sơn La",
              "province_id": 14,
              "created_at": "2024-01-28T17:20:44.240Z",
              "updated_at": "2024-10-29T10:15:07.357Z",
              "popular": false,
              "slug": "thanh-pho-son-la",
              "deleted": null
          },
          {
              "id": 118,
              "name": "Huyện Quỳnh Nhai",
              "province_id": 14,
              "created_at": "2024-01-28T17:20:44.257Z",
              "updated_at": "2024-10-29T10:15:07.363Z",
              "popular": false,
              "slug": "huyen-quynh-nhai",
              "deleted": null
          },
          {
              "id": 119,
              "name": "Huyện Thuận Châu",
              "province_id": 14,
              "created_at": "2024-01-28T17:20:44.269Z",
              "updated_at": "2024-10-29T10:15:07.368Z",
              "popular": false,
              "slug": "huyen-thuan-chau",
              "deleted": null
          },
          {
              "id": 120,
              "name": "Huyện Mường La",
              "province_id": 14,
              "created_at": "2024-01-28T17:20:44.283Z",
              "updated_at": "2024-10-29T10:15:07.373Z",
              "popular": false,
              "slug": "huyen-muong-la",
              "deleted": null
          },
          {
              "id": 121,
              "name": "Huyện Bắc Yên",
              "province_id": 14,
              "created_at": "2024-01-28T17:20:44.296Z",
              "updated_at": "2024-10-29T10:15:07.379Z",
              "popular": false,
              "slug": "huyen-bac-yen",
              "deleted": null
          },
          {
              "id": 122,
              "name": "Huyện Phù Yên",
              "province_id": 14,
              "created_at": "2024-01-28T17:20:44.310Z",
              "updated_at": "2024-10-29T10:15:07.383Z",
              "popular": false,
              "slug": "huyen-phu-yen",
              "deleted": null
          },
          {
              "id": 123,
              "name": "Huyện Mộc Châu",
              "province_id": 14,
              "created_at": "2024-01-28T17:20:44.322Z",
              "updated_at": "2024-10-29T10:15:07.388Z",
              "popular": false,
              "slug": "huyen-moc-chau",
              "deleted": null
          },
          {
              "id": 124,
              "name": "Huyện Yên Châu",
              "province_id": 14,
              "created_at": "2024-01-28T17:20:44.336Z",
              "updated_at": "2024-10-29T10:15:07.393Z",
              "popular": false,
              "slug": "huyen-yen-chau",
              "deleted": null
          },
          {
              "id": 125,
              "name": "Huyện Mai Sơn",
              "province_id": 14,
              "created_at": "2024-01-28T17:20:44.348Z",
              "updated_at": "2024-10-29T10:15:07.398Z",
              "popular": false,
              "slug": "huyen-mai-son",
              "deleted": null
          },
          {
              "id": 126,
              "name": "Huyện Sông Mã",
              "province_id": 14,
              "created_at": "2024-01-28T17:20:44.360Z",
              "updated_at": "2024-10-29T10:15:07.403Z",
              "popular": false,
              "slug": "huyen-song-ma",
              "deleted": null
          },
          {
              "id": 127,
              "name": "Huyện Sốp Cộp",
              "province_id": 14,
              "created_at": "2024-01-28T17:20:44.372Z",
              "updated_at": "2024-10-29T10:15:07.409Z",
              "popular": false,
              "slug": "huyen-sop-cop",
              "deleted": null
          },
          {
              "id": 128,
              "name": "Huyện Vân Hồ",
              "province_id": 14,
              "created_at": "2024-01-28T17:20:44.388Z",
              "updated_at": "2024-10-29T10:15:07.418Z",
              "popular": false,
              "slug": "huyen-van-ho",
              "deleted": null
          }
      ]
  },
  {
      "id": 15,
      "name": "Yên Bái",
      "region": "north",
      "created_at": "2024-01-28T17:20:42.280Z",
      "updated_at": "2024-10-29T10:15:06.336Z",
      "lat": 21.72241,
      "lon": 104.898348,
      "deleted": null,
      "slug": "yen-bai",
      "district": [
          {
              "id": 132,
              "name": "Thành phố Yên Bái",
              "province_id": 15,
              "created_at": "2024-01-28T17:20:44.400Z",
              "updated_at": "2024-10-29T10:15:07.423Z",
              "popular": false,
              "slug": "thanh-pho-yen-bai",
              "deleted": null
          },
          {
              "id": 133,
              "name": "Thị xã Nghĩa Lộ",
              "province_id": 15,
              "created_at": "2024-01-28T17:20:44.412Z",
              "updated_at": "2024-10-29T10:15:07.428Z",
              "popular": false,
              "slug": "thi-xa-nghia-lo",
              "deleted": null
          },
          {
              "id": 135,
              "name": "Huyện Lục Yên",
              "province_id": 15,
              "created_at": "2024-01-28T17:20:44.428Z",
              "updated_at": "2024-10-29T10:15:07.434Z",
              "popular": false,
              "slug": "huyen-luc-yen",
              "deleted": null
          },
          {
              "id": 136,
              "name": "Huyện Văn Yên",
              "province_id": 15,
              "created_at": "2024-01-28T17:20:44.439Z",
              "updated_at": "2024-10-29T10:15:07.439Z",
              "popular": false,
              "slug": "huyen-van-yen",
              "deleted": null
          },
          {
              "id": 137,
              "name": "Huyện Mù Căng Chải",
              "province_id": 15,
              "created_at": "2024-01-28T17:20:44.452Z",
              "updated_at": "2024-10-29T10:15:07.446Z",
              "popular": false,
              "slug": "huyen-mu-cang-chai",
              "deleted": null
          },
          {
              "id": 138,
              "name": "Huyện Trấn Yên",
              "province_id": 15,
              "created_at": "2024-01-28T17:20:44.464Z",
              "updated_at": "2024-10-29T10:15:07.451Z",
              "popular": false,
              "slug": "huyen-tran-yen",
              "deleted": null
          },
          {
              "id": 139,
              "name": "Huyện Trạm Tấu",
              "province_id": 15,
              "created_at": "2024-01-28T17:20:44.480Z",
              "updated_at": "2024-10-29T10:15:07.456Z",
              "popular": false,
              "slug": "huyen-tram-tau",
              "deleted": null
          },
          {
              "id": 140,
              "name": "Huyện Văn Chấn",
              "province_id": 15,
              "created_at": "2024-01-28T17:20:44.495Z",
              "updated_at": "2024-10-29T10:15:07.461Z",
              "popular": false,
              "slug": "huyen-van-chan",
              "deleted": null
          },
          {
              "id": 141,
              "name": "Huyện Yên Bình",
              "province_id": 15,
              "created_at": "2024-01-28T17:20:44.507Z",
              "updated_at": "2024-10-29T10:15:07.466Z",
              "popular": false,
              "slug": "huyen-yen-binh",
              "deleted": null
          }
      ]
  },
  {
      "id": 17,
      "name": "Hoà Bình",
      "region": "north",
      "created_at": "2024-01-28T17:20:42.293Z",
      "updated_at": "2024-10-29T10:15:06.344Z",
      "lat": 20.853889,
      "lon": 105.337556,
      "deleted": null,
      "slug": "hoa-binh",
      "district": [
          {
              "id": 148,
              "name": "Thành phố Hòa Bình",
              "province_id": 17,
              "created_at": "2024-01-28T17:20:44.523Z",
              "updated_at": "2024-10-29T10:15:07.471Z",
              "popular": false,
              "slug": "thanh-pho-hoa-binh",
              "deleted": null
          },
          {
              "id": 150,
              "name": "Huyện Đà Bắc",
              "province_id": 17,
              "created_at": "2024-01-28T17:20:44.535Z",
              "updated_at": "2024-10-29T10:15:07.477Z",
              "popular": false,
              "slug": "huyen-da-bac",
              "deleted": null
          },
          {
              "id": 152,
              "name": "Huyện Lương Sơn",
              "province_id": 17,
              "created_at": "2024-01-28T17:20:44.548Z",
              "updated_at": "2024-10-29T10:15:07.481Z",
              "popular": false,
              "slug": "huyen-luong-son",
              "deleted": null
          },
          {
              "id": 153,
              "name": "Huyện Kim Bôi",
              "province_id": 17,
              "created_at": "2024-01-28T17:20:44.563Z",
              "updated_at": "2024-10-29T10:15:07.487Z",
              "popular": false,
              "slug": "huyen-kim-boi",
              "deleted": null
          },
          {
              "id": 154,
              "name": "Huyện Cao Phong",
              "province_id": 17,
              "created_at": "2024-01-28T17:20:44.577Z",
              "updated_at": "2024-10-29T10:15:07.491Z",
              "popular": false,
              "slug": "huyen-cao-phong",
              "deleted": null
          },
          {
              "id": 155,
              "name": "Huyện Tân Lạc",
              "province_id": 17,
              "created_at": "2024-01-28T17:20:44.591Z",
              "updated_at": "2024-10-29T10:15:07.497Z",
              "popular": false,
              "slug": "huyen-tan-lac",
              "deleted": null
          },
          {
              "id": 156,
              "name": "Huyện Mai Châu",
              "province_id": 17,
              "created_at": "2024-01-28T17:20:44.604Z",
              "updated_at": "2024-10-29T10:15:07.502Z",
              "popular": false,
              "slug": "huyen-mai-chau",
              "deleted": null
          },
          {
              "id": 157,
              "name": "Huyện Lạc Sơn",
              "province_id": 17,
              "created_at": "2024-01-28T17:20:44.618Z",
              "updated_at": "2024-10-29T10:15:07.508Z",
              "popular": false,
              "slug": "huyen-lac-son",
              "deleted": null
          },
          {
              "id": 158,
              "name": "Huyện Yên Thủy",
              "province_id": 17,
              "created_at": "2024-01-28T17:20:44.632Z",
              "updated_at": "2024-10-29T10:15:07.513Z",
              "popular": false,
              "slug": "huyen-yen-thuy",
              "deleted": null
          },
          {
              "id": 159,
              "name": "Huyện Lạc Thủy",
              "province_id": 17,
              "created_at": "2024-01-28T17:20:44.644Z",
              "updated_at": "2024-10-29T10:15:07.519Z",
              "popular": false,
              "slug": "huyen-lac-thuy",
              "deleted": null
          }
      ]
  },
  {
      "id": 19,
      "name": "Thái Nguyên",
      "region": "north",
      "created_at": "2024-01-28T17:20:42.307Z",
      "updated_at": "2024-10-29T10:15:06.350Z",
      "lat": 21.594915,
      "lon": 105.848793,
      "deleted": null,
      "slug": "thai-nguyen",
      "district": [
          {
              "id": 164,
              "name": "Thành phố Thái Nguyên",
              "province_id": 19,
              "created_at": "2024-01-28T17:20:44.659Z",
              "updated_at": "2024-10-29T10:15:07.526Z",
              "popular": false,
              "slug": "thanh-pho-thai-nguyen",
              "deleted": null
          },
          {
              "id": 165,
              "name": "Thành phố Sông Công",
              "province_id": 19,
              "created_at": "2024-01-28T17:20:44.672Z",
              "updated_at": "2024-10-29T10:15:07.541Z",
              "popular": false,
              "slug": "thanh-pho-song-cong",
              "deleted": null
          },
          {
              "id": 167,
              "name": "Huyện Định Hóa",
              "province_id": 19,
              "created_at": "2024-01-28T17:20:44.692Z",
              "updated_at": "2024-10-29T10:15:07.548Z",
              "popular": false,
              "slug": "huyen-dinh-hoa",
              "deleted": null
          },
          {
              "id": 168,
              "name": "Huyện Phú Lương",
              "province_id": 19,
              "created_at": "2024-01-28T17:20:44.705Z",
              "updated_at": "2024-10-29T10:15:07.553Z",
              "popular": false,
              "slug": "huyen-phu-luong",
              "deleted": null
          },
          {
              "id": 169,
              "name": "Huyện Đồng Hỷ",
              "province_id": 19,
              "created_at": "2024-01-28T17:20:44.720Z",
              "updated_at": "2024-10-29T10:15:07.559Z",
              "popular": false,
              "slug": "huyen-dong-hy",
              "deleted": null
          },
          {
              "id": 170,
              "name": "Huyện Võ Nhai",
              "province_id": 19,
              "created_at": "2024-01-28T17:20:44.736Z",
              "updated_at": "2024-10-29T10:15:07.563Z",
              "popular": false,
              "slug": "huyen-vo-nhai",
              "deleted": null
          },
          {
              "id": 171,
              "name": "Huyện Đại Từ",
              "province_id": 19,
              "created_at": "2024-01-28T17:20:44.750Z",
              "updated_at": "2024-10-29T10:15:07.570Z",
              "popular": false,
              "slug": "huyen-dai-tu",
              "deleted": null
          },
          {
              "id": 172,
              "name": "Thành phố Phổ Yên",
              "province_id": 19,
              "created_at": "2024-01-28T17:20:44.765Z",
              "updated_at": "2024-10-29T10:15:07.575Z",
              "popular": false,
              "slug": "thanh-pho-pho-yen",
              "deleted": null
          },
          {
              "id": 173,
              "name": "Huyện Phú Bình",
              "province_id": 19,
              "created_at": "2024-01-28T17:20:44.778Z",
              "updated_at": "2024-10-29T10:15:07.581Z",
              "popular": false,
              "slug": "huyen-phu-binh",
              "deleted": null
          }
      ]
  },
  {
      "id": 20,
      "name": "Lạng Sơn",
      "region": "north",
      "created_at": "2024-01-28T17:20:42.319Z",
      "updated_at": "2024-10-29T10:15:06.355Z",
      "lat": 21.853708,
      "lon": 106.761519,
      "deleted": null,
      "slug": "lang-son",
      "district": [
          {
              "id": 178,
              "name": "Thành phố Lạng Sơn",
              "province_id": 20,
              "created_at": "2024-01-28T17:20:44.789Z",
              "updated_at": "2024-10-29T10:15:07.586Z",
              "popular": false,
              "slug": "thanh-pho-lang-son",
              "deleted": null
          },
          {
              "id": 180,
              "name": "Huyện Tràng Định",
              "province_id": 20,
              "created_at": "2024-01-28T17:20:44.802Z",
              "updated_at": "2024-10-29T10:15:07.592Z",
              "popular": false,
              "slug": "huyen-trang-dinh",
              "deleted": null
          },
          {
              "id": 181,
              "name": "Huyện Bình Gia",
              "province_id": 20,
              "created_at": "2024-01-28T17:20:44.817Z",
              "updated_at": "2024-10-29T10:15:07.596Z",
              "popular": false,
              "slug": "huyen-binh-gia",
              "deleted": null
          },
          {
              "id": 182,
              "name": "Huyện Văn Lãng",
              "province_id": 20,
              "created_at": "2024-01-28T17:20:44.832Z",
              "updated_at": "2024-10-29T10:15:07.601Z",
              "popular": false,
              "slug": "huyen-van-lang",
              "deleted": null
          },
          {
              "id": 183,
              "name": "Huyện Cao Lộc",
              "province_id": 20,
              "created_at": "2024-01-28T17:20:44.849Z",
              "updated_at": "2024-10-29T10:15:07.606Z",
              "popular": false,
              "slug": "huyen-cao-loc",
              "deleted": null
          },
          {
              "id": 184,
              "name": "Huyện Văn Quan",
              "province_id": 20,
              "created_at": "2024-01-28T17:20:44.876Z",
              "updated_at": "2024-10-29T10:15:07.611Z",
              "popular": false,
              "slug": "huyen-van-quan",
              "deleted": null
          },
          {
              "id": 185,
              "name": "Huyện Bắc Sơn",
              "province_id": 20,
              "created_at": "2024-01-28T17:20:44.897Z",
              "updated_at": "2024-10-29T10:15:07.616Z",
              "popular": false,
              "slug": "huyen-bac-son",
              "deleted": null
          },
          {
              "id": 186,
              "name": "Huyện Hữu Lũng",
              "province_id": 20,
              "created_at": "2024-01-28T17:20:44.913Z",
              "updated_at": "2024-10-29T10:15:07.621Z",
              "popular": false,
              "slug": "huyen-huu-lung",
              "deleted": null
          },
          {
              "id": 187,
              "name": "Huyện Chi Lăng",
              "province_id": 20,
              "created_at": "2024-01-28T17:20:44.928Z",
              "updated_at": "2024-10-29T10:15:07.626Z",
              "popular": false,
              "slug": "huyen-chi-lang",
              "deleted": null
          },
          {
              "id": 188,
              "name": "Huyện Lộc Bình",
              "province_id": 20,
              "created_at": "2024-01-28T17:20:44.940Z",
              "updated_at": "2024-10-29T10:15:07.631Z",
              "popular": false,
              "slug": "huyen-loc-binh",
              "deleted": null
          },
          {
              "id": 189,
              "name": "Huyện Đình Lập",
              "province_id": 20,
              "created_at": "2024-01-28T17:20:44.952Z",
              "updated_at": "2024-10-29T10:15:07.637Z",
              "popular": false,
              "slug": "huyen-dinh-lap",
              "deleted": null
          }
      ]
  },
  {
      "id": 22,
      "name": "Quảng Ninh",
      "region": "north",
      "created_at": "2024-01-28T17:20:42.333Z",
      "updated_at": "2024-10-29T10:15:06.360Z",
      "lat": 21.137422,
      "lon": 107.3716531,
      "deleted": null,
      "slug": "quang-ninh",
      "district": [
          {
              "id": 193,
              "name": "Thành phố Hạ Long",
              "province_id": 22,
              "created_at": "2024-01-28T17:20:44.969Z",
              "updated_at": "2024-10-29T10:15:07.642Z",
              "popular": false,
              "slug": "thanh-pho-ha-long",
              "deleted": null
          },
          {
              "id": 194,
              "name": "Thành phố Móng Cái",
              "province_id": 22,
              "created_at": "2024-01-28T17:20:44.984Z",
              "updated_at": "2024-10-29T10:15:07.648Z",
              "popular": false,
              "slug": "thanh-pho-mong-cai",
              "deleted": null
          },
          {
              "id": 195,
              "name": "Thành phố Cẩm Phả",
              "province_id": 22,
              "created_at": "2024-01-28T17:20:45.001Z",
              "updated_at": "2024-10-29T10:15:07.654Z",
              "popular": false,
              "slug": "thanh-pho-cam-pha",
              "deleted": null
          },
          {
              "id": 196,
              "name": "Thành phố Uông Bí",
              "province_id": 22,
              "created_at": "2024-01-28T17:20:45.019Z",
              "updated_at": "2024-10-29T10:15:07.660Z",
              "popular": false,
              "slug": "thanh-pho-uong-bi",
              "deleted": null
          },
          {
              "id": 198,
              "name": "Huyện Bình Liêu",
              "province_id": 22,
              "created_at": "2024-01-28T17:20:45.033Z",
              "updated_at": "2024-10-29T10:15:07.665Z",
              "popular": false,
              "slug": "huyen-binh-lieu",
              "deleted": null
          },
          {
              "id": 199,
              "name": "Huyện Tiên Yên",
              "province_id": 22,
              "created_at": "2024-01-28T17:20:45.048Z",
              "updated_at": "2024-10-29T10:15:07.671Z",
              "popular": false,
              "slug": "huyen-tien-yen",
              "deleted": null
          },
          {
              "id": 200,
              "name": "Huyện Đầm Hà",
              "province_id": 22,
              "created_at": "2024-01-28T17:20:45.059Z",
              "updated_at": "2024-10-29T10:15:07.677Z",
              "popular": false,
              "slug": "huyen-dam-ha",
              "deleted": null
          },
          {
              "id": 201,
              "name": "Huyện Hải Hà",
              "province_id": 22,
              "created_at": "2024-01-28T17:20:45.074Z",
              "updated_at": "2024-10-29T10:15:07.683Z",
              "popular": false,
              "slug": "huyen-hai-ha",
              "deleted": null
          },
          {
              "id": 202,
              "name": "Huyện Ba Chẽ",
              "province_id": 22,
              "created_at": "2024-01-28T17:20:45.087Z",
              "updated_at": "2024-10-29T10:15:07.688Z",
              "popular": false,
              "slug": "huyen-ba-che",
              "deleted": null
          },
          {
              "id": 203,
              "name": "Huyện Vân Đồn",
              "province_id": 22,
              "created_at": "2024-01-28T17:20:45.100Z",
              "updated_at": "2024-10-29T10:15:07.693Z",
              "popular": false,
              "slug": "huyen-van-don",
              "deleted": null
          },
          {
              "id": 205,
              "name": "Thị xã Đông Triều",
              "province_id": 22,
              "created_at": "2024-01-28T17:20:45.115Z",
              "updated_at": "2024-10-29T10:15:07.698Z",
              "popular": false,
              "slug": "thi-xa-dong-trieu",
              "deleted": null
          },
          {
              "id": 206,
              "name": "Thị xã Quảng Yên",
              "province_id": 22,
              "created_at": "2024-01-28T17:20:45.127Z",
              "updated_at": "2024-10-29T10:15:07.703Z",
              "popular": false,
              "slug": "thi-xa-quang-yen",
              "deleted": null
          },
          {
              "id": 207,
              "name": "Huyện Cô Tô",
              "province_id": 22,
              "created_at": "2024-01-28T17:20:45.140Z",
              "updated_at": "2024-10-29T10:15:07.708Z",
              "popular": false,
              "slug": "huyen-co-to",
              "deleted": null
          }
      ]
  },
  {
      "id": 24,
      "name": "Bắc Giang",
      "region": "north",
      "created_at": "2024-01-28T17:20:42.349Z",
      "updated_at": "2024-10-29T10:15:06.366Z",
      "lat": 21.273279,
      "lon": 106.1946,
      "deleted": null,
      "slug": "bac-giang",
      "district": [
          {
              "id": 213,
              "name": "Thành phố Bắc Giang",
              "province_id": 24,
              "created_at": "2024-01-28T17:20:45.157Z",
              "updated_at": "2024-10-29T10:15:07.713Z",
              "popular": false,
              "slug": "thanh-pho-bac-giang",
              "deleted": null
          },
          {
              "id": 215,
              "name": "Huyện Yên Thế",
              "province_id": 24,
              "created_at": "2024-01-28T17:20:45.169Z",
              "updated_at": "2024-10-29T10:15:07.719Z",
              "popular": false,
              "slug": "huyen-yen-the",
              "deleted": null
          },
          {
              "id": 216,
              "name": "Huyện Tân Yên",
              "province_id": 24,
              "created_at": "2024-01-28T17:20:45.183Z",
              "updated_at": "2024-10-29T10:15:07.724Z",
              "popular": false,
              "slug": "huyen-tan-yen",
              "deleted": null
          },
          {
              "id": 217,
              "name": "Huyện Lạng Giang",
              "province_id": 24,
              "created_at": "2024-01-28T17:20:45.195Z",
              "updated_at": "2024-10-29T10:15:07.735Z",
              "popular": false,
              "slug": "huyen-lang-giang",
              "deleted": null
          },
          {
              "id": 218,
              "name": "Huyện Lục Nam",
              "province_id": 24,
              "created_at": "2024-01-28T17:20:45.208Z",
              "updated_at": "2024-10-29T10:15:07.741Z",
              "popular": false,
              "slug": "huyen-luc-nam",
              "deleted": null
          },
          {
              "id": 219,
              "name": "Huyện Lục Ngạn",
              "province_id": 24,
              "created_at": "2024-01-28T17:20:45.220Z",
              "updated_at": "2024-10-29T10:15:07.748Z",
              "popular": false,
              "slug": "huyen-luc-ngan",
              "deleted": null
          },
          {
              "id": 220,
              "name": "Huyện Sơn Động",
              "province_id": 24,
              "created_at": "2024-01-28T17:20:45.232Z",
              "updated_at": "2024-10-29T10:15:07.753Z",
              "popular": false,
              "slug": "huyen-son-dong",
              "deleted": null
          },
          {
              "id": 221,
              "name": "Huyện Yên Dũng",
              "province_id": 24,
              "created_at": "2024-01-28T17:20:45.248Z",
              "updated_at": "2024-10-29T10:15:07.759Z",
              "popular": false,
              "slug": "huyen-yen-dung",
              "deleted": null
          },
          {
              "id": 222,
              "name": "Huyện Việt Yên",
              "province_id": 24,
              "created_at": "2024-01-28T17:20:45.259Z",
              "updated_at": "2024-10-29T10:15:07.764Z",
              "popular": false,
              "slug": "huyen-viet-yen",
              "deleted": null
          },
          {
              "id": 223,
              "name": "Huyện Hiệp Hòa",
              "province_id": 24,
              "created_at": "2024-01-28T17:20:45.272Z",
              "updated_at": "2024-10-29T10:15:07.770Z",
              "popular": false,
              "slug": "huyen-hiep-hoa",
              "deleted": null
          }
      ]
  },
  {
      "id": 25,
      "name": "Phú Thọ",
      "region": "north",
      "created_at": "2024-01-28T17:20:42.362Z",
      "updated_at": "2024-10-29T10:15:06.372Z",
      "lat": 21.319421,
      "lon": 105.228206,
      "deleted": null,
      "slug": "phu-tho",
      "district": [
          {
              "id": 227,
              "name": "Thành phố Việt Trì",
              "province_id": 25,
              "created_at": "2024-01-28T17:20:45.287Z",
              "updated_at": "2024-10-29T10:15:07.775Z",
              "popular": false,
              "slug": "thanh-pho-viet-tri",
              "deleted": null
          },
          {
              "id": 228,
              "name": "Thị xã Phú Thọ",
              "province_id": 25,
              "created_at": "2024-01-28T17:20:45.299Z",
              "updated_at": "2024-10-29T10:15:07.782Z",
              "popular": false,
              "slug": "thi-xa-phu-tho",
              "deleted": null
          },
          {
              "id": 230,
              "name": "Huyện Đoan Hùng",
              "province_id": 25,
              "created_at": "2024-01-28T17:20:45.314Z",
              "updated_at": "2024-10-29T10:15:07.807Z",
              "popular": false,
              "slug": "huyen-doan-hung",
              "deleted": null
          },
          {
              "id": 231,
              "name": "Huyện Hạ Hoà",
              "province_id": 25,
              "created_at": "2024-01-28T17:20:45.326Z",
              "updated_at": "2024-10-29T10:15:07.820Z",
              "popular": false,
              "slug": "huyen-ha-hoa",
              "deleted": null
          },
          {
              "id": 232,
              "name": "Huyện Thanh Ba",
              "province_id": 25,
              "created_at": "2024-01-28T17:20:45.338Z",
              "updated_at": "2024-10-29T10:15:07.832Z",
              "popular": false,
              "slug": "huyen-thanh-ba",
              "deleted": null
          },
          {
              "id": 233,
              "name": "Huyện Phù Ninh",
              "province_id": 25,
              "created_at": "2024-01-28T17:20:45.348Z",
              "updated_at": "2024-10-29T10:15:07.837Z",
              "popular": false,
              "slug": "huyen-phu-ninh",
              "deleted": null
          },
          {
              "id": 234,
              "name": "Huyện Yên Lập",
              "province_id": 25,
              "created_at": "2024-01-28T17:20:45.363Z",
              "updated_at": "2024-10-29T10:15:07.843Z",
              "popular": false,
              "slug": "huyen-yen-lap",
              "deleted": null
          },
          {
              "id": 235,
              "name": "Huyện Cẩm Khê",
              "province_id": 25,
              "created_at": "2024-01-28T17:20:45.374Z",
              "updated_at": "2024-10-29T10:15:07.849Z",
              "popular": false,
              "slug": "huyen-cam-khe",
              "deleted": null
          },
          {
              "id": 236,
              "name": "Huyện Tam Nông",
              "province_id": 25,
              "created_at": "2024-01-28T17:20:45.388Z",
              "updated_at": "2024-10-29T10:15:07.855Z",
              "popular": false,
              "slug": "huyen-tam-nong",
              "deleted": null
          },
          {
              "id": 237,
              "name": "Huyện Lâm Thao",
              "province_id": 25,
              "created_at": "2024-01-28T17:20:45.400Z",
              "updated_at": "2024-10-29T10:15:07.860Z",
              "popular": false,
              "slug": "huyen-lam-thao",
              "deleted": null
          },
          {
              "id": 238,
              "name": "Huyện Thanh Sơn",
              "province_id": 25,
              "created_at": "2024-01-28T17:20:45.414Z",
              "updated_at": "2024-10-29T10:15:07.866Z",
              "popular": false,
              "slug": "huyen-thanh-son",
              "deleted": null
          },
          {
              "id": 239,
              "name": "Huyện Thanh Thuỷ",
              "province_id": 25,
              "created_at": "2024-01-28T17:20:45.426Z",
              "updated_at": "2024-10-29T10:15:07.874Z",
              "popular": false,
              "slug": "huyen-thanh-thuy",
              "deleted": null
          },
          {
              "id": 240,
              "name": "Huyện Tân Sơn",
              "province_id": 25,
              "created_at": "2024-01-28T17:20:45.440Z",
              "updated_at": "2024-10-29T10:15:07.880Z",
              "popular": false,
              "slug": "huyen-tan-son",
              "deleted": null
          }
      ]
  },
  {
      "id": 26,
      "name": "Vĩnh Phúc",
      "region": "north",
      "created_at": "2024-01-28T17:20:42.376Z",
      "updated_at": "2024-10-29T10:15:06.428Z",
      "lat": 21.363121,
      "lon": 105.5821071,
      "deleted": null,
      "slug": "vinh-phuc",
      "district": [
          {
              "id": 243,
              "name": "Thành phố Vĩnh Yên",
              "province_id": 26,
              "created_at": "2024-01-28T17:20:45.454Z",
              "updated_at": "2024-10-29T10:15:07.885Z",
              "popular": false,
              "slug": "thanh-pho-vinh-yen",
              "deleted": null
          },
          {
              "id": 244,
              "name": "Thành phố Phúc Yên",
              "province_id": 26,
              "created_at": "2024-01-28T17:20:45.465Z",
              "updated_at": "2024-10-29T10:15:07.891Z",
              "popular": false,
              "slug": "thanh-pho-phuc-yen",
              "deleted": null
          },
          {
              "id": 246,
              "name": "Huyện Lập Thạch",
              "province_id": 26,
              "created_at": "2024-01-28T17:20:45.476Z",
              "updated_at": "2024-10-29T10:15:07.897Z",
              "popular": false,
              "slug": "huyen-lap-thach",
              "deleted": null
          },
          {
              "id": 247,
              "name": "Huyện Tam Dương",
              "province_id": 26,
              "created_at": "2024-01-28T17:20:45.488Z",
              "updated_at": "2024-10-29T10:15:07.912Z",
              "popular": false,
              "slug": "huyen-tam-duong",
              "deleted": null
          },
          {
              "id": 248,
              "name": "Huyện Tam Đảo",
              "province_id": 26,
              "created_at": "2024-01-28T17:20:45.502Z",
              "updated_at": "2024-10-29T10:15:07.918Z",
              "popular": false,
              "slug": "huyen-tam-dao",
              "deleted": null
          },
          {
              "id": 249,
              "name": "Huyện Bình Xuyên",
              "province_id": 26,
              "created_at": "2024-01-28T17:20:45.514Z",
              "updated_at": "2024-10-29T10:15:07.925Z",
              "popular": false,
              "slug": "huyen-binh-xuyen",
              "deleted": null
          },
          {
              "id": 251,
              "name": "Huyện Yên Lạc",
              "province_id": 26,
              "created_at": "2024-01-28T17:20:45.528Z",
              "updated_at": "2024-10-29T10:15:07.933Z",
              "popular": false,
              "slug": "huyen-yen-lac",
              "deleted": null
          },
          {
              "id": 252,
              "name": "Huyện Vĩnh Tường",
              "province_id": 26,
              "created_at": "2024-01-28T17:20:45.539Z",
              "updated_at": "2024-10-29T10:15:07.952Z",
              "popular": false,
              "slug": "huyen-vinh-tuong",
              "deleted": null
          },
          {
              "id": 253,
              "name": "Huyện Sông Lô",
              "province_id": 26,
              "created_at": "2024-01-28T17:20:45.552Z",
              "updated_at": "2024-10-29T10:15:07.962Z",
              "popular": false,
              "slug": "huyen-song-lo",
              "deleted": null
          }
      ]
  },
  {
      "id": 27,
      "name": "Bắc Ninh",
      "region": "north",
      "created_at": "2024-01-28T17:20:42.389Z",
      "updated_at": "2024-10-29T10:15:06.434Z",
      "lat": 21.1587204,
      "lon": 106.0615886,
      "deleted": null,
      "slug": "bac-ninh",
      "district": [
          {
              "id": 256,
              "name": "Thành phố Bắc Ninh",
              "province_id": 27,
              "created_at": "2024-01-28T17:20:45.565Z",
              "updated_at": "2024-10-29T10:15:07.969Z",
              "popular": false,
              "slug": "thanh-pho-bac-ninh",
              "deleted": null
          },
          {
              "id": 258,
              "name": "Huyện Yên Phong",
              "province_id": 27,
              "created_at": "2024-01-28T17:20:45.576Z",
              "updated_at": "2024-10-29T10:15:07.977Z",
              "popular": false,
              "slug": "huyen-yen-phong",
              "deleted": null
          },
          {
              "id": 259,
              "name": "Thị xã Quế Võ",
              "province_id": 27,
              "created_at": "2024-01-28T17:20:45.588Z",
              "updated_at": "2024-10-29T10:15:07.987Z",
              "popular": false,
              "slug": "thi-xa-que-vo",
              "deleted": null
          },
          {
              "id": 260,
              "name": "Huyện Tiên Du",
              "province_id": 27,
              "created_at": "2024-01-28T17:20:45.604Z",
              "updated_at": "2024-10-29T10:15:07.992Z",
              "popular": false,
              "slug": "huyen-tien-du",
              "deleted": null
          },
          {
              "id": 261,
              "name": "Thành phố Từ Sơn",
              "province_id": 27,
              "created_at": "2024-01-28T17:20:45.614Z",
              "updated_at": "2024-10-29T10:15:07.997Z",
              "popular": false,
              "slug": "thanh-pho-tu-son",
              "deleted": null
          },
          {
              "id": 262,
              "name": "Thị xã Thuận Thành",
              "province_id": 27,
              "created_at": "2024-01-28T17:20:45.628Z",
              "updated_at": "2024-10-29T10:15:08.003Z",
              "popular": false,
              "slug": "thi-xa-thuan-thanh",
              "deleted": null
          },
          {
              "id": 263,
              "name": "Huyện Gia Bình",
              "province_id": 27,
              "created_at": "2024-01-28T17:20:45.642Z",
              "updated_at": "2024-10-29T10:15:08.013Z",
              "popular": false,
              "slug": "huyen-gia-binh",
              "deleted": null
          },
          {
              "id": 264,
              "name": "Huyện Lương Tài",
              "province_id": 27,
              "created_at": "2024-01-28T17:20:45.653Z",
              "updated_at": "2024-10-29T10:15:08.020Z",
              "popular": false,
              "slug": "huyen-luong-tai",
              "deleted": null
          }
      ]
  },
  {
      "id": 30,
      "name": "Hải Dương",
      "region": "north",
      "created_at": "2024-01-28T17:20:42.402Z",
      "updated_at": "2024-10-29T10:15:06.440Z",
      "lat": 20.93904,
      "lon": 106.333179,
      "deleted": null,
      "slug": "hai-duong",
      "district": [
          {
              "id": 288,
              "name": "Thành phố Hải Dương",
              "province_id": 30,
              "created_at": "2024-01-28T17:20:45.672Z",
              "updated_at": "2024-10-29T10:15:08.027Z",
              "popular": false,
              "slug": "thanh-pho-hai-duong",
              "deleted": null
          },
          {
              "id": 290,
              "name": "Thành phố Chí Linh",
              "province_id": 30,
              "created_at": "2024-01-28T17:20:45.687Z",
              "updated_at": "2024-10-29T10:15:08.060Z",
              "popular": false,
              "slug": "thanh-pho-chi-linh",
              "deleted": null
          },
          {
              "id": 291,
              "name": "Huyện Nam Sách",
              "province_id": 30,
              "created_at": "2024-01-28T17:20:45.700Z",
              "updated_at": "2024-10-29T10:15:08.069Z",
              "popular": false,
              "slug": "huyen-nam-sach",
              "deleted": null
          },
          {
              "id": 292,
              "name": "Thị xã Kinh Môn",
              "province_id": 30,
              "created_at": "2024-01-28T17:20:45.716Z",
              "updated_at": "2024-10-29T10:15:08.075Z",
              "popular": false,
              "slug": "thi-xa-kinh-mon",
              "deleted": null
          },
          {
              "id": 293,
              "name": "Huyện Kim Thành",
              "province_id": 30,
              "created_at": "2024-01-28T17:20:45.730Z",
              "updated_at": "2024-10-29T10:15:08.081Z",
              "popular": false,
              "slug": "huyen-kim-thanh",
              "deleted": null
          },
          {
              "id": 294,
              "name": "Huyện Thanh Hà",
              "province_id": 30,
              "created_at": "2024-01-28T17:20:45.743Z",
              "updated_at": "2024-10-29T10:15:08.087Z",
              "popular": false,
              "slug": "huyen-thanh-ha",
              "deleted": null
          },
          {
              "id": 295,
              "name": "Huyện Cẩm Giàng",
              "province_id": 30,
              "created_at": "2024-01-28T17:20:45.756Z",
              "updated_at": "2024-10-29T10:15:08.092Z",
              "popular": false,
              "slug": "huyen-cam-giang",
              "deleted": null
          },
          {
              "id": 296,
              "name": "Huyện Bình Giang",
              "province_id": 30,
              "created_at": "2024-01-28T17:20:45.773Z",
              "updated_at": "2024-10-29T10:15:08.101Z",
              "popular": false,
              "slug": "huyen-binh-giang",
              "deleted": null
          },
          {
              "id": 297,
              "name": "Huyện Gia Lộc",
              "province_id": 30,
              "created_at": "2024-01-28T17:20:45.785Z",
              "updated_at": "2024-10-29T10:15:08.107Z",
              "popular": false,
              "slug": "huyen-gia-loc",
              "deleted": null
          },
          {
              "id": 298,
              "name": "Huyện Tứ Kỳ",
              "province_id": 30,
              "created_at": "2024-01-28T17:20:45.804Z",
              "updated_at": "2024-10-29T10:15:08.113Z",
              "popular": false,
              "slug": "huyen-tu-ky",
              "deleted": null
          },
          {
              "id": 299,
              "name": "Huyện Ninh Giang",
              "province_id": 30,
              "created_at": "2024-01-28T17:20:45.820Z",
              "updated_at": "2024-10-29T10:15:08.119Z",
              "popular": false,
              "slug": "huyen-ninh-giang",
              "deleted": null
          },
          {
              "id": 300,
              "name": "Huyện Thanh Miện",
              "province_id": 30,
              "created_at": "2024-01-28T17:20:45.833Z",
              "updated_at": "2024-10-29T10:15:08.126Z",
              "popular": false,
              "slug": "huyen-thanh-mien",
              "deleted": null
          }
      ]
  },
  {
      "id": 31,
      "name": "Hải Phòng",
      "region": "north",
      "created_at": "2024-01-28T17:20:42.414Z",
      "updated_at": "2024-10-29T10:15:06.446Z",
      "lat": 20.807195,
      "lon": 106.7395801,
      "deleted": null,
      "slug": "hai-phong",
      "district": [
          {
              "id": 303,
              "name": "Quận Hồng Bàng",
              "province_id": 31,
              "created_at": "2024-01-28T17:20:45.847Z",
              "updated_at": "2024-10-29T10:15:08.132Z",
              "popular": false,
              "slug": "quan-hong-bang",
              "deleted": null
          },
          {
              "id": 304,
              "name": "Quận Ngô Quyền",
              "province_id": 31,
              "created_at": "2024-01-28T17:20:45.860Z",
              "updated_at": "2024-10-29T10:15:08.137Z",
              "popular": false,
              "slug": "quan-ngo-quyen",
              "deleted": null
          },
          {
              "id": 305,
              "name": "Quận Lê Chân",
              "province_id": 31,
              "created_at": "2024-01-28T17:20:45.872Z",
              "updated_at": "2024-10-29T10:15:08.143Z",
              "popular": false,
              "slug": "quan-le-chan",
              "deleted": null
          },
          {
              "id": 306,
              "name": "Quận Hải An",
              "province_id": 31,
              "created_at": "2024-01-28T17:20:45.890Z",
              "updated_at": "2024-10-29T10:15:08.148Z",
              "popular": false,
              "slug": "quan-hai-an",
              "deleted": null
          },
          {
              "id": 307,
              "name": "Quận Kiến An",
              "province_id": 31,
              "created_at": "2024-01-28T17:20:45.904Z",
              "updated_at": "2024-10-29T10:15:08.154Z",
              "popular": false,
              "slug": "quan-kien-an",
              "deleted": null
          },
          {
              "id": 308,
              "name": "Quận Đồ Sơn",
              "province_id": 31,
              "created_at": "2024-01-28T17:20:45.920Z",
              "updated_at": "2024-10-29T10:15:08.160Z",
              "popular": false,
              "slug": "quan-do-son",
              "deleted": null
          },
          {
              "id": 309,
              "name": "Quận Dương Kinh",
              "province_id": 31,
              "created_at": "2024-01-28T17:20:45.935Z",
              "updated_at": "2024-10-29T10:15:08.165Z",
              "popular": false,
              "slug": "quan-duong-kinh",
              "deleted": null
          },
          {
              "id": 311,
              "name": "Huyện Thuỷ Nguyên",
              "province_id": 31,
              "created_at": "2024-01-28T17:20:45.951Z",
              "updated_at": "2024-10-29T10:15:08.171Z",
              "popular": false,
              "slug": "huyen-thuy-nguyen",
              "deleted": null
          },
          {
              "id": 312,
              "name": "Huyện An Dương",
              "province_id": 31,
              "created_at": "2024-01-28T17:20:45.965Z",
              "updated_at": "2024-10-29T10:15:08.176Z",
              "popular": false,
              "slug": "huyen-an-duong",
              "deleted": null
          },
          {
              "id": 313,
              "name": "Huyện An Lão",
              "province_id": 31,
              "created_at": "2024-01-28T17:20:45.985Z",
              "updated_at": "2024-10-29T10:15:08.181Z",
              "popular": false,
              "slug": "huyen-an-lao",
              "deleted": null
          },
          {
              "id": 314,
              "name": "Huyện Kiến Thuỵ",
              "province_id": 31,
              "created_at": "2024-01-28T17:20:46.002Z",
              "updated_at": "2024-10-29T10:15:08.186Z",
              "popular": false,
              "slug": "huyen-kien-thuy",
              "deleted": null
          },
          {
              "id": 315,
              "name": "Huyện Tiên Lãng",
              "province_id": 31,
              "created_at": "2024-01-28T17:20:46.014Z",
              "updated_at": "2024-10-29T10:15:08.191Z",
              "popular": false,
              "slug": "huyen-tien-lang",
              "deleted": null
          },
          {
              "id": 316,
              "name": "Huyện Vĩnh Bảo",
              "province_id": 31,
              "created_at": "2024-01-28T17:20:46.028Z",
              "updated_at": "2024-10-29T10:15:08.196Z",
              "popular": false,
              "slug": "huyen-vinh-bao",
              "deleted": null
          },
          {
              "id": 317,
              "name": "Huyện Cát Hải",
              "province_id": 31,
              "created_at": "2024-01-28T17:20:46.043Z",
              "updated_at": "2024-10-29T10:15:08.201Z",
              "popular": false,
              "slug": "huyen-cat-hai",
              "deleted": null
          },
          {
              "id": 318,
              "name": "Huyện Bạch Long Vĩ",
              "province_id": 31,
              "created_at": "2024-01-28T17:20:46.054Z",
              "updated_at": "2024-10-29T10:15:08.207Z",
              "popular": false,
              "slug": "huyen-bach-long-vi",
              "deleted": null
          }
      ]
  },
  {
      "id": 33,
      "name": "Hưng Yên",
      "region": "north",
      "created_at": "2024-01-28T17:20:42.429Z",
      "updated_at": "2024-10-29T10:15:06.452Z",
      "lat": 20.646537,
      "lon": 106.051134,
      "deleted": null,
      "slug": "hung-yen",
      "district": [
          {
              "id": 323,
              "name": "Thành phố Hưng Yên",
              "province_id": 33,
              "created_at": "2024-01-28T17:20:46.068Z",
              "updated_at": "2024-10-29T10:15:08.212Z",
              "popular": false,
              "slug": "thanh-pho-hung-yen",
              "deleted": null
          },
          {
              "id": 325,
              "name": "Huyện Văn Lâm",
              "province_id": 33,
              "created_at": "2024-01-28T17:20:46.083Z",
              "updated_at": "2024-10-29T10:15:08.217Z",
              "popular": false,
              "slug": "huyen-van-lam",
              "deleted": null
          },
          {
              "id": 326,
              "name": "Huyện Văn Giang",
              "province_id": 33,
              "created_at": "2024-01-28T17:20:46.094Z",
              "updated_at": "2024-10-29T10:15:08.222Z",
              "popular": false,
              "slug": "huyen-van-giang",
              "deleted": null
          },
          {
              "id": 327,
              "name": "Huyện Yên Mỹ",
              "province_id": 33,
              "created_at": "2024-01-28T17:20:46.108Z",
              "updated_at": "2024-10-29T10:15:08.228Z",
              "popular": false,
              "slug": "huyen-yen-my",
              "deleted": null
          },
          {
              "id": 328,
              "name": "Thị xã Mỹ Hào",
              "province_id": 33,
              "created_at": "2024-01-28T17:20:46.121Z",
              "updated_at": "2024-10-29T10:15:08.235Z",
              "popular": false,
              "slug": "thi-xa-my-hao",
              "deleted": null
          },
          {
              "id": 329,
              "name": "Huyện Ân Thi",
              "province_id": 33,
              "created_at": "2024-01-28T17:20:46.133Z",
              "updated_at": "2024-10-29T10:15:08.240Z",
              "popular": false,
              "slug": "huyen-an-thi",
              "deleted": null
          },
          {
              "id": 330,
              "name": "Huyện Khoái Châu",
              "province_id": 33,
              "created_at": "2024-01-28T17:20:46.144Z",
              "updated_at": "2024-10-29T10:15:08.246Z",
              "popular": false,
              "slug": "huyen-khoai-chau",
              "deleted": null
          },
          {
              "id": 331,
              "name": "Huyện Kim Động",
              "province_id": 33,
              "created_at": "2024-01-28T17:20:46.156Z",
              "updated_at": "2024-10-29T10:15:08.251Z",
              "popular": false,
              "slug": "huyen-kim-dong",
              "deleted": null
          },
          {
              "id": 332,
              "name": "Huyện Tiên Lữ",
              "province_id": 33,
              "created_at": "2024-01-28T17:20:46.171Z",
              "updated_at": "2024-10-29T10:15:08.257Z",
              "popular": false,
              "slug": "huyen-tien-lu",
              "deleted": null
          },
          {
              "id": 333,
              "name": "Huyện Phù Cừ",
              "province_id": 33,
              "created_at": "2024-01-28T17:20:46.184Z",
              "updated_at": "2024-10-29T10:15:08.272Z",
              "popular": false,
              "slug": "huyen-phu-cu",
              "deleted": null
          }
      ]
  },
  {
      "id": 34,
      "name": "Thái Bình",
      "region": "north",
      "created_at": "2024-01-28T17:20:42.444Z",
      "updated_at": "2024-10-29T10:15:06.458Z",
      "lat": 20.446002,
      "lon": 106.336582,
      "deleted": null,
      "slug": "thai-binh",
      "district": [
          {
              "id": 336,
              "name": "Thành phố Thái Bình",
              "province_id": 34,
              "created_at": "2024-01-28T17:20:46.199Z",
              "updated_at": "2024-10-29T10:15:08.279Z",
              "popular": false,
              "slug": "thanh-pho-thai-binh",
              "deleted": null
          },
          {
              "id": 338,
              "name": "Huyện Quỳnh Phụ",
              "province_id": 34,
              "created_at": "2024-01-28T17:20:46.212Z",
              "updated_at": "2024-10-29T10:15:08.284Z",
              "popular": false,
              "slug": "huyen-quynh-phu",
              "deleted": null
          },
          {
              "id": 339,
              "name": "Huyện Hưng Hà",
              "province_id": 34,
              "created_at": "2024-01-28T17:20:46.228Z",
              "updated_at": "2024-10-29T10:15:08.290Z",
              "popular": false,
              "slug": "huyen-hung-ha",
              "deleted": null
          },
          {
              "id": 340,
              "name": "Huyện Đông Hưng",
              "province_id": 34,
              "created_at": "2024-01-28T17:20:46.244Z",
              "updated_at": "2024-10-29T10:15:08.296Z",
              "popular": false,
              "slug": "huyen-dong-hung",
              "deleted": null
          },
          {
              "id": 341,
              "name": "Huyện Thái Thụy",
              "province_id": 34,
              "created_at": "2024-01-28T17:20:46.260Z",
              "updated_at": "2024-10-29T10:15:08.302Z",
              "popular": false,
              "slug": "huyen-thai-thuy",
              "deleted": null
          },
          {
              "id": 342,
              "name": "Huyện Tiền Hải",
              "province_id": 34,
              "created_at": "2024-01-28T17:20:46.272Z",
              "updated_at": "2024-10-29T10:15:08.307Z",
              "popular": false,
              "slug": "huyen-tien-hai",
              "deleted": null
          },
          {
              "id": 343,
              "name": "Huyện Kiến Xương",
              "province_id": 34,
              "created_at": "2024-01-28T17:20:46.284Z",
              "updated_at": "2024-10-29T10:15:08.312Z",
              "popular": false,
              "slug": "huyen-kien-xuong",
              "deleted": null
          },
          {
              "id": 344,
              "name": "Huyện Vũ Thư",
              "province_id": 34,
              "created_at": "2024-01-28T17:20:46.301Z",
              "updated_at": "2024-10-29T10:15:08.317Z",
              "popular": false,
              "slug": "huyen-vu-thu",
              "deleted": null
          }
      ]
  },
  {
      "id": 35,
      "name": "Hà Nam",
      "region": "north",
      "created_at": "2024-01-28T17:20:42.456Z",
      "updated_at": "2024-10-29T10:15:06.464Z",
      "lat": 20.583519,
      "lon": 105.92299,
      "deleted": null,
      "slug": "ha-nam",
      "district": [
          {
              "id": 347,
              "name": "Thành phố Phủ Lý",
              "province_id": 35,
              "created_at": "2024-01-28T17:20:46.313Z",
              "updated_at": "2024-10-29T10:15:08.328Z",
              "popular": false,
              "slug": "thanh-pho-phu-ly",
              "deleted": null
          },
          {
              "id": 349,
              "name": "Thị xã Duy Tiên",
              "province_id": 35,
              "created_at": "2024-01-28T17:20:46.328Z",
              "updated_at": "2024-10-29T10:15:08.333Z",
              "popular": false,
              "slug": "thi-xa-duy-tien",
              "deleted": null
          },
          {
              "id": 350,
              "name": "Huyện Kim Bảng",
              "province_id": 35,
              "created_at": "2024-01-28T17:20:46.343Z",
              "updated_at": "2024-10-29T10:15:08.339Z",
              "popular": false,
              "slug": "huyen-kim-bang",
              "deleted": null
          },
          {
              "id": 351,
              "name": "Huyện Thanh Liêm",
              "province_id": 35,
              "created_at": "2024-01-28T17:20:46.355Z",
              "updated_at": "2024-10-29T10:15:08.344Z",
              "popular": false,
              "slug": "huyen-thanh-liem",
              "deleted": null
          },
          {
              "id": 352,
              "name": "Huyện Bình Lục",
              "province_id": 35,
              "created_at": "2024-01-28T17:20:46.368Z",
              "updated_at": "2024-10-29T10:15:08.350Z",
              "popular": false,
              "slug": "huyen-binh-luc",
              "deleted": null
          },
          {
              "id": 353,
              "name": "Huyện Lý Nhân",
              "province_id": 35,
              "created_at": "2024-01-28T17:20:46.384Z",
              "updated_at": "2024-10-29T10:15:08.355Z",
              "popular": false,
              "slug": "huyen-ly-nhan",
              "deleted": null
          }
      ]
  },
  {
      "id": 36,
      "name": "Nam Định",
      "region": "north",
      "created_at": "2024-01-28T17:20:42.468Z",
      "updated_at": "2024-10-29T10:15:06.471Z",
      "lat": 20.43882,
      "lon": 106.162106,
      "deleted": null,
      "slug": "nam-dinh",
      "district": [
          {
              "id": 356,
              "name": "Thành phố Nam Định",
              "province_id": 36,
              "created_at": "2024-01-28T17:20:46.395Z",
              "updated_at": "2024-10-29T10:15:08.361Z",
              "popular": false,
              "slug": "thanh-pho-nam-dinh",
              "deleted": null
          },
          {
              "id": 358,
              "name": "Huyện Mỹ Lộc",
              "province_id": 36,
              "created_at": "2024-01-28T17:20:46.408Z",
              "updated_at": "2024-10-29T10:15:08.366Z",
              "popular": false,
              "slug": "huyen-my-loc",
              "deleted": null
          },
          {
              "id": 359,
              "name": "Huyện Vụ Bản",
              "province_id": 36,
              "created_at": "2024-01-28T17:20:46.423Z",
              "updated_at": "2024-10-29T10:15:08.373Z",
              "popular": false,
              "slug": "huyen-vu-ban",
              "deleted": null
          },
          {
              "id": 360,
              "name": "Huyện Ý Yên",
              "province_id": 36,
              "created_at": "2024-01-28T17:20:46.435Z",
              "updated_at": "2024-10-29T10:15:08.378Z",
              "popular": false,
              "slug": "huyen-y-yen",
              "deleted": null
          },
          {
              "id": 361,
              "name": "Huyện Nghĩa Hưng",
              "province_id": 36,
              "created_at": "2024-01-28T17:20:46.448Z",
              "updated_at": "2024-10-29T10:15:08.385Z",
              "popular": false,
              "slug": "huyen-nghia-hung",
              "deleted": null
          },
          {
              "id": 362,
              "name": "Huyện Nam Trực",
              "province_id": 36,
              "created_at": "2024-01-28T17:20:46.463Z",
              "updated_at": "2024-10-29T10:15:08.390Z",
              "popular": false,
              "slug": "huyen-nam-truc",
              "deleted": null
          },
          {
              "id": 363,
              "name": "Huyện Trực Ninh",
              "province_id": 36,
              "created_at": "2024-01-28T17:20:46.475Z",
              "updated_at": "2024-10-29T10:15:08.396Z",
              "popular": false,
              "slug": "huyen-truc-ninh",
              "deleted": null
          },
          {
              "id": 364,
              "name": "Huyện Xuân Trường",
              "province_id": 36,
              "created_at": "2024-01-28T17:20:46.488Z",
              "updated_at": "2024-10-29T10:15:08.402Z",
              "popular": false,
              "slug": "huyen-xuan-truong",
              "deleted": null
          },
          {
              "id": 365,
              "name": "Huyện Giao Thủy",
              "province_id": 36,
              "created_at": "2024-01-28T17:20:46.505Z",
              "updated_at": "2024-10-29T10:15:08.409Z",
              "popular": false,
              "slug": "huyen-giao-thuy",
              "deleted": null
          },
          {
              "id": 366,
              "name": "Huyện Hải Hậu",
              "province_id": 36,
              "created_at": "2024-01-28T17:20:46.517Z",
              "updated_at": "2024-10-29T10:15:08.415Z",
              "popular": false,
              "slug": "huyen-hai-hau",
              "deleted": null
          }
      ]
  },
  {
      "id": 37,
      "name": "Ninh Bình",
      "region": "north",
      "created_at": "2024-01-28T17:20:42.484Z",
      "updated_at": "2024-10-29T10:15:06.476Z",
      "lat": 20.250614,
      "lon": 105.974454,
      "deleted": null,
      "slug": "ninh-binh",
      "district": [
          {
              "id": 369,
              "name": "Thành phố Ninh Bình",
              "province_id": 37,
              "created_at": "2024-01-28T17:20:46.531Z",
              "updated_at": "2024-10-29T10:15:08.420Z",
              "popular": false,
              "slug": "thanh-pho-ninh-binh",
              "deleted": null
          },
          {
              "id": 370,
              "name": "Thành phố Tam Điệp",
              "province_id": 37,
              "created_at": "2024-01-28T17:20:46.544Z",
              "updated_at": "2024-10-29T10:15:08.425Z",
              "popular": false,
              "slug": "thanh-pho-tam-diep",
              "deleted": null
          },
          {
              "id": 372,
              "name": "Huyện Nho Quan",
              "province_id": 37,
              "created_at": "2024-01-28T17:20:46.559Z",
              "updated_at": "2024-10-29T10:15:08.431Z",
              "popular": false,
              "slug": "huyen-nho-quan",
              "deleted": null
          },
          {
              "id": 373,
              "name": "Huyện Gia Viễn",
              "province_id": 37,
              "created_at": "2024-01-28T17:20:46.572Z",
              "updated_at": "2024-10-29T10:15:08.435Z",
              "popular": false,
              "slug": "huyen-gia-vien",
              "deleted": null
          },
          {
              "id": 374,
              "name": "Huyện Hoa Lư",
              "province_id": 37,
              "created_at": "2024-01-28T17:20:46.586Z",
              "updated_at": "2024-10-29T10:15:08.441Z",
              "popular": false,
              "slug": "huyen-hoa-lu",
              "deleted": null
          },
          {
              "id": 375,
              "name": "Huyện Yên Khánh",
              "province_id": 37,
              "created_at": "2024-01-28T17:20:46.599Z",
              "updated_at": "2024-10-29T10:15:08.446Z",
              "popular": false,
              "slug": "huyen-yen-khanh",
              "deleted": null
          },
          {
              "id": 376,
              "name": "Huyện Kim Sơn",
              "province_id": 37,
              "created_at": "2024-01-28T17:20:46.612Z",
              "updated_at": "2024-10-29T10:15:08.452Z",
              "popular": false,
              "slug": "huyen-kim-son",
              "deleted": null
          },
          {
              "id": 377,
              "name": "Huyện Yên Mô",
              "province_id": 37,
              "created_at": "2024-01-28T17:20:46.625Z",
              "updated_at": "2024-10-29T10:15:08.458Z",
              "popular": false,
              "slug": "huyen-yen-mo",
              "deleted": null
          }
      ]
  },
  {
      "id": 38,
      "name": "Thanh Hóa",
      "region": "middle",
      "created_at": "2024-01-28T17:20:42.498Z",
      "updated_at": "2024-10-29T10:15:06.483Z",
      "lat": 19.810843,
      "lon": 105.7798792,
      "deleted": null,
      "slug": "thanh-hoa",
      "district": [
          {
              "id": 380,
              "name": "Thành phố Thanh Hóa",
              "province_id": 38,
              "created_at": "2024-01-28T17:20:46.638Z",
              "updated_at": "2024-10-29T10:15:08.463Z",
              "popular": false,
              "slug": "thanh-pho-thanh-hoa",
              "deleted": null
          },
          {
              "id": 381,
              "name": "Thị xã Bỉm Sơn",
              "province_id": 38,
              "created_at": "2024-01-28T17:20:46.651Z",
              "updated_at": "2024-10-29T10:15:08.468Z",
              "popular": false,
              "slug": "thi-xa-bim-son",
              "deleted": null
          },
          {
              "id": 382,
              "name": "Thành phố Sầm Sơn",
              "province_id": 38,
              "created_at": "2024-01-28T17:20:46.669Z",
              "updated_at": "2024-10-29T10:15:08.474Z",
              "popular": false,
              "slug": "thanh-pho-sam-son",
              "deleted": null
          },
          {
              "id": 384,
              "name": "Huyện Mường Lát",
              "province_id": 38,
              "created_at": "2024-01-28T17:20:46.683Z",
              "updated_at": "2024-10-29T10:15:08.478Z",
              "popular": false,
              "slug": "huyen-muong-lat",
              "deleted": null
          },
          {
              "id": 385,
              "name": "Huyện Quan Hóa",
              "province_id": 38,
              "created_at": "2024-01-28T17:20:46.695Z",
              "updated_at": "2024-10-29T10:15:08.485Z",
              "popular": false,
              "slug": "huyen-quan-hoa",
              "deleted": null
          },
          {
              "id": 386,
              "name": "Huyện Bá Thước",
              "province_id": 38,
              "created_at": "2024-01-28T17:20:46.708Z",
              "updated_at": "2024-10-29T10:15:08.491Z",
              "popular": false,
              "slug": "huyen-ba-thuoc",
              "deleted": null
          },
          {
              "id": 387,
              "name": "Huyện Quan Sơn",
              "province_id": 38,
              "created_at": "2024-01-28T17:20:46.722Z",
              "updated_at": "2024-10-29T10:15:08.499Z",
              "popular": false,
              "slug": "huyen-quan-son",
              "deleted": null
          },
          {
              "id": 388,
              "name": "Huyện Lang Chánh",
              "province_id": 38,
              "created_at": "2024-01-28T17:20:46.734Z",
              "updated_at": "2024-10-29T10:15:08.505Z",
              "popular": false,
              "slug": "huyen-lang-chanh",
              "deleted": null
          },
          {
              "id": 389,
              "name": "Huyện Ngọc Lặc",
              "province_id": 38,
              "created_at": "2024-01-28T17:20:46.747Z",
              "updated_at": "2024-10-29T10:15:08.512Z",
              "popular": false,
              "slug": "huyen-ngoc-lac",
              "deleted": null
          },
          {
              "id": 390,
              "name": "Huyện Cẩm Thủy",
              "province_id": 38,
              "created_at": "2024-01-28T17:20:46.760Z",
              "updated_at": "2024-10-29T10:15:08.518Z",
              "popular": false,
              "slug": "huyen-cam-thuy",
              "deleted": null
          },
          {
              "id": 391,
              "name": "Huyện Thạch Thành",
              "province_id": 38,
              "created_at": "2024-01-28T17:20:46.776Z",
              "updated_at": "2024-10-29T10:15:08.525Z",
              "popular": false,
              "slug": "huyen-thach-thanh",
              "deleted": null
          },
          {
              "id": 392,
              "name": "Huyện Hà Trung",
              "province_id": 38,
              "created_at": "2024-01-28T17:20:46.789Z",
              "updated_at": "2024-10-29T10:15:08.531Z",
              "popular": false,
              "slug": "huyen-ha-trung",
              "deleted": null
          },
          {
              "id": 393,
              "name": "Huyện Vĩnh Lộc",
              "province_id": 38,
              "created_at": "2024-01-28T17:20:46.803Z",
              "updated_at": "2024-10-29T10:15:08.543Z",
              "popular": false,
              "slug": "huyen-vinh-loc",
              "deleted": null
          },
          {
              "id": 394,
              "name": "Huyện Yên Định",
              "province_id": 38,
              "created_at": "2024-01-28T17:20:46.814Z",
              "updated_at": "2024-10-29T10:15:08.550Z",
              "popular": false,
              "slug": "huyen-yen-dinh",
              "deleted": null
          },
          {
              "id": 395,
              "name": "Huyện Thọ Xuân",
              "province_id": 38,
              "created_at": "2024-01-28T17:20:46.827Z",
              "updated_at": "2024-10-29T10:15:08.558Z",
              "popular": false,
              "slug": "huyen-tho-xuan",
              "deleted": null
          },
          {
              "id": 396,
              "name": "Huyện Thường Xuân",
              "province_id": 38,
              "created_at": "2024-01-28T17:20:46.840Z",
              "updated_at": "2024-10-29T10:15:08.567Z",
              "popular": false,
              "slug": "huyen-thuong-xuan",
              "deleted": null
          },
          {
              "id": 397,
              "name": "Huyện Triệu Sơn",
              "province_id": 38,
              "created_at": "2024-01-28T17:20:46.852Z",
              "updated_at": "2024-10-29T10:15:08.575Z",
              "popular": false,
              "slug": "huyen-trieu-son",
              "deleted": null
          },
          {
              "id": 398,
              "name": "Huyện Thiệu Hóa",
              "province_id": 38,
              "created_at": "2024-01-28T17:20:46.867Z",
              "updated_at": "2024-10-29T10:15:08.583Z",
              "popular": false,
              "slug": "huyen-thieu-hoa",
              "deleted": null
          },
          {
              "id": 399,
              "name": "Huyện Hoằng Hóa",
              "province_id": 38,
              "created_at": "2024-01-28T17:20:46.879Z",
              "updated_at": "2024-10-29T10:15:08.589Z",
              "popular": false,
              "slug": "huyen-hoang-hoa",
              "deleted": null
          },
          {
              "id": 400,
              "name": "Huyện Hậu Lộc",
              "province_id": 38,
              "created_at": "2024-01-28T17:20:46.892Z",
              "updated_at": "2024-10-29T10:15:08.596Z",
              "popular": false,
              "slug": "huyen-hau-loc",
              "deleted": null
          },
          {
              "id": 401,
              "name": "Huyện Nga Sơn",
              "province_id": 38,
              "created_at": "2024-01-28T17:20:46.907Z",
              "updated_at": "2024-10-29T10:15:08.602Z",
              "popular": false,
              "slug": "huyen-nga-son",
              "deleted": null
          },
          {
              "id": 402,
              "name": "Huyện Như Xuân",
              "province_id": 38,
              "created_at": "2024-01-28T17:20:46.919Z",
              "updated_at": "2024-10-29T10:15:08.608Z",
              "popular": false,
              "slug": "huyen-nhu-xuan",
              "deleted": null
          },
          {
              "id": 403,
              "name": "Huyện Như Thanh",
              "province_id": 38,
              "created_at": "2024-01-28T17:20:46.935Z",
              "updated_at": "2024-10-29T10:15:08.614Z",
              "popular": false,
              "slug": "huyen-nhu-thanh",
              "deleted": null
          },
          {
              "id": 404,
              "name": "Huyện Nông Cống",
              "province_id": 38,
              "created_at": "2024-01-28T17:20:46.947Z",
              "updated_at": "2024-10-29T10:15:08.621Z",
              "popular": false,
              "slug": "huyen-nong-cong",
              "deleted": null
          },
          {
              "id": 405,
              "name": "Huyện Đông Sơn",
              "province_id": 38,
              "created_at": "2024-01-28T17:20:46.960Z",
              "updated_at": "2024-10-29T10:15:08.627Z",
              "popular": false,
              "slug": "huyen-dong-son",
              "deleted": null
          },
          {
              "id": 406,
              "name": "Huyện Quảng Xương",
              "province_id": 38,
              "created_at": "2024-01-28T17:20:46.977Z",
              "updated_at": "2024-10-29T10:15:08.632Z",
              "popular": false,
              "slug": "huyen-quang-xuong",
              "deleted": null
          },
          {
              "id": 407,
              "name": "Thị xã Nghi Sơn",
              "province_id": 38,
              "created_at": "2024-01-28T17:20:46.989Z",
              "updated_at": "2024-10-29T10:15:08.640Z",
              "popular": false,
              "slug": "thi-xa-nghi-son",
              "deleted": null
          }
      ]
  },
  {
      "id": 40,
      "name": "Nghệ An",
      "region": "middle",
      "created_at": "2024-01-28T17:20:42.512Z",
      "updated_at": "2024-10-29T10:15:06.488Z",
      "lat": 19.233704,
      "lon": 105.0890141,
      "deleted": null,
      "slug": "nghe-an",
      "district": [
          {
              "id": 412,
              "name": "Thành phố Vinh",
              "province_id": 40,
              "created_at": "2024-01-28T17:20:47.008Z",
              "updated_at": "2024-10-29T10:15:08.645Z",
              "popular": false,
              "slug": "thanh-pho-vinh",
              "deleted": null
          },
          {
              "id": 413,
              "name": "Thị xã Cửa Lò",
              "province_id": 40,
              "created_at": "2024-01-28T17:20:47.027Z",
              "updated_at": "2024-10-29T10:15:08.655Z",
              "popular": false,
              "slug": "thi-xa-cua-lo",
              "deleted": null
          },
          {
              "id": 414,
              "name": "Thị xã Thái Hoà",
              "province_id": 40,
              "created_at": "2024-01-28T17:20:47.048Z",
              "updated_at": "2024-10-29T10:15:08.663Z",
              "popular": false,
              "slug": "thi-xa-thai-hoa",
              "deleted": null
          },
          {
              "id": 415,
              "name": "Huyện Quế Phong",
              "province_id": 40,
              "created_at": "2024-01-28T17:20:47.066Z",
              "updated_at": "2024-10-29T10:15:08.672Z",
              "popular": false,
              "slug": "huyen-que-phong",
              "deleted": null
          },
          {
              "id": 416,
              "name": "Huyện Quỳ Châu",
              "province_id": 40,
              "created_at": "2024-01-28T17:20:47.084Z",
              "updated_at": "2024-10-29T10:15:08.681Z",
              "popular": false,
              "slug": "huyen-quy-chau",
              "deleted": null
          },
          {
              "id": 417,
              "name": "Huyện Kỳ Sơn",
              "province_id": 40,
              "created_at": "2024-01-28T17:20:47.100Z",
              "updated_at": "2024-10-29T10:15:08.688Z",
              "popular": false,
              "slug": "huyen-ky-son",
              "deleted": null
          },
          {
              "id": 418,
              "name": "Huyện Tương Dương",
              "province_id": 40,
              "created_at": "2024-01-28T17:20:47.117Z",
              "updated_at": "2024-10-29T10:15:08.693Z",
              "popular": false,
              "slug": "huyen-tuong-duong",
              "deleted": null
          },
          {
              "id": 419,
              "name": "Huyện Nghĩa Đàn",
              "province_id": 40,
              "created_at": "2024-01-28T17:20:47.129Z",
              "updated_at": "2024-10-29T10:15:08.705Z",
              "popular": false,
              "slug": "huyen-nghia-dan",
              "deleted": null
          },
          {
              "id": 420,
              "name": "Huyện Quỳ Hợp",
              "province_id": 40,
              "created_at": "2024-01-28T17:20:47.143Z",
              "updated_at": "2024-10-29T10:15:08.711Z",
              "popular": false,
              "slug": "huyen-quy-hop",
              "deleted": null
          },
          {
              "id": 421,
              "name": "Huyện Quỳnh Lưu",
              "province_id": 40,
              "created_at": "2024-01-28T17:20:47.156Z",
              "updated_at": "2024-10-29T10:15:08.717Z",
              "popular": false,
              "slug": "huyen-quynh-luu",
              "deleted": null
          },
          {
              "id": 422,
              "name": "Huyện Con Cuông",
              "province_id": 40,
              "created_at": "2024-01-28T17:20:47.171Z",
              "updated_at": "2024-10-29T10:15:08.727Z",
              "popular": false,
              "slug": "huyen-con-cuong",
              "deleted": null
          },
          {
              "id": 423,
              "name": "Huyện Tân Kỳ",
              "province_id": 40,
              "created_at": "2024-01-28T17:20:47.184Z",
              "updated_at": "2024-10-29T10:15:08.735Z",
              "popular": false,
              "slug": "huyen-tan-ky",
              "deleted": null
          },
          {
              "id": 424,
              "name": "Huyện Anh Sơn",
              "province_id": 40,
              "created_at": "2024-01-28T17:20:47.199Z",
              "updated_at": "2024-10-29T10:15:08.744Z",
              "popular": false,
              "slug": "huyen-anh-son",
              "deleted": null
          },
          {
              "id": 425,
              "name": "Huyện Diễn Châu",
              "province_id": 40,
              "created_at": "2024-01-28T17:20:47.211Z",
              "updated_at": "2024-10-29T10:15:08.751Z",
              "popular": false,
              "slug": "huyen-dien-chau",
              "deleted": null
          },
          {
              "id": 426,
              "name": "Huyện Yên Thành",
              "province_id": 40,
              "created_at": "2024-01-28T17:20:47.224Z",
              "updated_at": "2024-10-29T10:15:08.758Z",
              "popular": false,
              "slug": "huyen-yen-thanh",
              "deleted": null
          },
          {
              "id": 427,
              "name": "Huyện Đô Lương",
              "province_id": 40,
              "created_at": "2024-01-28T17:20:47.237Z",
              "updated_at": "2024-10-29T10:15:08.765Z",
              "popular": false,
              "slug": "huyen-do-luong",
              "deleted": null
          },
          {
              "id": 428,
              "name": "Huyện Thanh Chương",
              "province_id": 40,
              "created_at": "2024-01-28T17:20:47.252Z",
              "updated_at": "2024-10-29T10:15:08.771Z",
              "popular": false,
              "slug": "huyen-thanh-chuong",
              "deleted": null
          },
          {
              "id": 429,
              "name": "Huyện Nghi Lộc",
              "province_id": 40,
              "created_at": "2024-01-28T17:20:47.267Z",
              "updated_at": "2024-10-29T10:15:08.776Z",
              "popular": false,
              "slug": "huyen-nghi-loc",
              "deleted": null
          },
          {
              "id": 430,
              "name": "Huyện Nam Đàn",
              "province_id": 40,
              "created_at": "2024-01-28T17:20:47.279Z",
              "updated_at": "2024-10-29T10:15:08.781Z",
              "popular": false,
              "slug": "huyen-nam-dan",
              "deleted": null
          },
          {
              "id": 431,
              "name": "Huyện Hưng Nguyên",
              "province_id": 40,
              "created_at": "2024-01-28T17:20:47.292Z",
              "updated_at": "2024-10-29T10:15:08.787Z",
              "popular": false,
              "slug": "huyen-hung-nguyen",
              "deleted": null
          },
          {
              "id": 432,
              "name": "Thị xã Hoàng Mai",
              "province_id": 40,
              "created_at": "2024-01-28T17:20:47.306Z",
              "updated_at": "2024-10-29T10:15:08.793Z",
              "popular": false,
              "slug": "thi-xa-hoang-mai",
              "deleted": null
          }
      ]
  },
  {
      "id": 42,
      "name": "Hà Tĩnh",
      "region": "middle",
      "created_at": "2024-01-28T17:20:42.527Z",
      "updated_at": "2024-10-29T10:15:06.495Z",
      "lat": 18.355382,
      "lon": 105.900938,
      "deleted": null,
      "slug": "ha-tinh",
      "district": [
          {
              "id": 436,
              "name": "Thành phố Hà Tĩnh",
              "province_id": 42,
              "created_at": "2024-01-28T17:20:47.318Z",
              "updated_at": "2024-10-29T10:15:08.801Z",
              "popular": false,
              "slug": "thanh-pho-ha-tinh",
              "deleted": null
          },
          {
              "id": 437,
              "name": "Thị xã Hồng Lĩnh",
              "province_id": 42,
              "created_at": "2024-01-28T17:20:47.331Z",
              "updated_at": "2024-10-29T10:15:08.807Z",
              "popular": false,
              "slug": "thi-xa-hong-linh",
              "deleted": null
          },
          {
              "id": 439,
              "name": "Huyện Hương Sơn",
              "province_id": 42,
              "created_at": "2024-01-28T17:20:47.342Z",
              "updated_at": "2024-10-29T10:15:08.824Z",
              "popular": false,
              "slug": "huyen-huong-son",
              "deleted": null
          },
          {
              "id": 440,
              "name": "Huyện Đức Thọ",
              "province_id": 42,
              "created_at": "2024-01-28T17:20:47.355Z",
              "updated_at": "2024-10-29T10:15:08.830Z",
              "popular": false,
              "slug": "huyen-duc-tho",
              "deleted": null
          },
          {
              "id": 441,
              "name": "Huyện Vũ Quang",
              "province_id": 42,
              "created_at": "2024-01-28T17:20:47.367Z",
              "updated_at": "2024-10-29T10:15:08.837Z",
              "popular": false,
              "slug": "huyen-vu-quang",
              "deleted": null
          },
          {
              "id": 442,
              "name": "Huyện Nghi Xuân",
              "province_id": 42,
              "created_at": "2024-01-28T17:20:47.379Z",
              "updated_at": "2024-10-29T10:15:08.844Z",
              "popular": false,
              "slug": "huyen-nghi-xuan",
              "deleted": null
          },
          {
              "id": 443,
              "name": "Huyện Can Lộc",
              "province_id": 42,
              "created_at": "2024-01-28T17:20:47.392Z",
              "updated_at": "2024-10-29T10:15:08.851Z",
              "popular": false,
              "slug": "huyen-can-loc",
              "deleted": null
          },
          {
              "id": 444,
              "name": "Huyện Hương Khê",
              "province_id": 42,
              "created_at": "2024-01-28T17:20:47.408Z",
              "updated_at": "2024-10-29T10:15:08.857Z",
              "popular": false,
              "slug": "huyen-huong-khe",
              "deleted": null
          },
          {
              "id": 445,
              "name": "Huyện Thạch Hà",
              "province_id": 42,
              "created_at": "2024-01-28T17:20:47.420Z",
              "updated_at": "2024-10-29T10:15:08.866Z",
              "popular": false,
              "slug": "huyen-thach-ha",
              "deleted": null
          },
          {
              "id": 446,
              "name": "Huyện Cẩm Xuyên",
              "province_id": 42,
              "created_at": "2024-01-28T17:20:47.452Z",
              "updated_at": "2024-10-29T10:15:08.873Z",
              "popular": false,
              "slug": "huyen-cam-xuyen",
              "deleted": null
          },
          {
              "id": 447,
              "name": "Huyện Kỳ Anh",
              "province_id": 42,
              "created_at": "2024-01-28T17:20:47.468Z",
              "updated_at": "2024-10-29T10:15:08.880Z",
              "popular": false,
              "slug": "huyen-ky-anh",
              "deleted": null
          },
          {
              "id": 448,
              "name": "Huyện Lộc Hà",
              "province_id": 42,
              "created_at": "2024-01-28T17:20:47.480Z",
              "updated_at": "2024-10-29T10:15:08.886Z",
              "popular": false,
              "slug": "huyen-loc-ha",
              "deleted": null
          },
          {
              "id": 449,
              "name": "Thị xã Kỳ Anh",
              "province_id": 42,
              "created_at": "2024-01-28T17:20:47.492Z",
              "updated_at": "2024-10-29T10:15:08.892Z",
              "popular": false,
              "slug": "thi-xa-ky-anh",
              "deleted": null
          }
      ]
  },
  {
      "id": 44,
      "name": "Quảng Bình",
      "region": "middle",
      "created_at": "2024-01-28T17:20:42.538Z",
      "updated_at": "2024-10-29T10:15:06.500Z",
      "lat": 17.470835,
      "lon": 106.622125,
      "deleted": null,
      "slug": "quang-binh",
      "district": [
          {
              "id": 450,
              "name": "Thành Phố Đồng Hới",
              "province_id": 44,
              "created_at": "2024-01-28T17:20:47.508Z",
              "updated_at": "2024-10-29T10:15:08.898Z",
              "popular": false,
              "slug": "thanh-pho-dong-hoi",
              "deleted": null
          },
          {
              "id": 452,
              "name": "Huyện Minh Hóa",
              "province_id": 44,
              "created_at": "2024-01-28T17:20:47.520Z",
              "updated_at": "2024-10-29T10:15:08.903Z",
              "popular": false,
              "slug": "huyen-minh-hoa",
              "deleted": null
          },
          {
              "id": 453,
              "name": "Huyện Tuyên Hóa",
              "province_id": 44,
              "created_at": "2024-01-28T17:20:47.535Z",
              "updated_at": "2024-10-29T10:15:08.908Z",
              "popular": false,
              "slug": "huyen-tuyen-hoa",
              "deleted": null
          },
          {
              "id": 454,
              "name": "Huyện Quảng Trạch",
              "province_id": 44,
              "created_at": "2024-01-28T17:20:47.547Z",
              "updated_at": "2024-10-29T10:15:08.914Z",
              "popular": false,
              "slug": "huyen-quang-trach",
              "deleted": null
          },
          {
              "id": 455,
              "name": "Huyện Bố Trạch",
              "province_id": 44,
              "created_at": "2024-01-28T17:20:47.560Z",
              "updated_at": "2024-10-29T10:15:08.919Z",
              "popular": false,
              "slug": "huyen-bo-trach",
              "deleted": null
          },
          {
              "id": 456,
              "name": "Huyện Quảng Ninh",
              "province_id": 44,
              "created_at": "2024-01-28T17:20:47.576Z",
              "updated_at": "2024-10-29T10:15:08.924Z",
              "popular": false,
              "slug": "huyen-quang-ninh",
              "deleted": null
          },
          {
              "id": 457,
              "name": "Huyện Lệ Thủy",
              "province_id": 44,
              "created_at": "2024-01-28T17:20:47.591Z",
              "updated_at": "2024-10-29T10:15:08.929Z",
              "popular": false,
              "slug": "huyen-le-thuy",
              "deleted": null
          },
          {
              "id": 458,
              "name": "Thị xã Ba Đồn",
              "province_id": 44,
              "created_at": "2024-01-28T17:20:47.608Z",
              "updated_at": "2024-10-29T10:15:08.936Z",
              "popular": false,
              "slug": "thi-xa-ba-don",
              "deleted": null
          }
      ]
  },
  {
      "id": 45,
      "name": "Quảng Trị",
      "region": "middle",
      "created_at": "2024-01-28T17:20:42.552Z",
      "updated_at": "2024-10-29T10:15:06.506Z",
      "lat": 16.787238,
      "lon": 106.901729,
      "deleted": null,
      "slug": "quang-tri",
      "district": [
          {
              "id": 461,
              "name": "Thành phố Đông Hà",
              "province_id": 45,
              "created_at": "2024-01-28T17:20:47.622Z",
              "updated_at": "2024-10-29T10:15:08.942Z",
              "popular": false,
              "slug": "thanh-pho-dong-ha",
              "deleted": null
          },
          {
              "id": 462,
              "name": "Thị xã Quảng Trị",
              "province_id": 45,
              "created_at": "2024-01-28T17:20:47.634Z",
              "updated_at": "2024-10-29T10:15:08.951Z",
              "popular": false,
              "slug": "thi-xa-quang-tri",
              "deleted": null
          },
          {
              "id": 464,
              "name": "Huyện Vĩnh Linh",
              "province_id": 45,
              "created_at": "2024-01-28T17:20:47.649Z",
              "updated_at": "2024-10-29T10:15:08.958Z",
              "popular": false,
              "slug": "huyen-vinh-linh",
              "deleted": null
          },
          {
              "id": 465,
              "name": "Huyện Hướng Hóa",
              "province_id": 45,
              "created_at": "2024-01-28T17:20:47.665Z",
              "updated_at": "2024-10-29T10:15:08.965Z",
              "popular": false,
              "slug": "huyen-huong-hoa",
              "deleted": null
          },
          {
              "id": 466,
              "name": "Huyện Gio Linh",
              "province_id": 45,
              "created_at": "2024-01-28T17:20:47.680Z",
              "updated_at": "2024-10-29T10:15:08.972Z",
              "popular": false,
              "slug": "huyen-gio-linh",
              "deleted": null
          },
          {
              "id": 467,
              "name": "Huyện Đa Krông",
              "province_id": 45,
              "created_at": "2024-01-28T17:20:47.692Z",
              "updated_at": "2024-10-29T10:15:08.981Z",
              "popular": false,
              "slug": "huyen-da-krong",
              "deleted": null
          },
          {
              "id": 468,
              "name": "Huyện Cam Lộ",
              "province_id": 45,
              "created_at": "2024-01-28T17:20:47.704Z",
              "updated_at": "2024-10-29T10:15:08.987Z",
              "popular": false,
              "slug": "huyen-cam-lo",
              "deleted": null
          },
          {
              "id": 469,
              "name": "Huyện Triệu Phong",
              "province_id": 45,
              "created_at": "2024-01-28T17:20:47.721Z",
              "updated_at": "2024-10-29T10:15:08.994Z",
              "popular": false,
              "slug": "huyen-trieu-phong",
              "deleted": null
          },
          {
              "id": 470,
              "name": "Huyện Hải Lăng",
              "province_id": 45,
              "created_at": "2024-01-28T17:20:47.734Z",
              "updated_at": "2024-10-29T10:15:09.002Z",
              "popular": false,
              "slug": "huyen-hai-lang",
              "deleted": null
          },
          {
              "id": 471,
              "name": "Huyện Cồn Cỏ",
              "province_id": 45,
              "created_at": "2024-01-28T17:20:47.746Z",
              "updated_at": "2024-10-29T10:15:09.011Z",
              "popular": false,
              "slug": "huyen-con-co",
              "deleted": null
          }
      ]
  },
  {
      "id": 46,
      "name": "Thừa Thiên Huế",
      "region": "middle",
      "created_at": "2024-01-28T17:20:42.565Z",
      "updated_at": "2024-10-29T10:15:06.513Z",
      "lat": 16.3150931,
      "lon": 107.5376741,
      "deleted": null,
      "slug": "thua-thien-hue",
      "district": [
          {
              "id": 474,
              "name": "Thành phố Huế",
              "province_id": 46,
              "created_at": "2024-01-28T17:20:47.760Z",
              "updated_at": "2024-10-29T10:15:09.018Z",
              "popular": false,
              "slug": "thanh-pho-hue",
              "deleted": null
          },
          {
              "id": 476,
              "name": "Huyện Phong Điền",
              "province_id": 46,
              "created_at": "2024-01-28T17:20:47.778Z",
              "updated_at": "2024-10-29T10:15:09.025Z",
              "popular": false,
              "slug": "huyen-phong-dien",
              "deleted": null
          },
          {
              "id": 477,
              "name": "Huyện Quảng Điền",
              "province_id": 46,
              "created_at": "2024-01-28T17:20:47.791Z",
              "updated_at": "2024-10-29T10:15:09.034Z",
              "popular": false,
              "slug": "huyen-quang-dien",
              "deleted": null
          },
          {
              "id": 478,
              "name": "Huyện Phú Vang",
              "province_id": 46,
              "created_at": "2024-01-28T17:20:47.804Z",
              "updated_at": "2024-10-29T10:15:09.041Z",
              "popular": false,
              "slug": "huyen-phu-vang",
              "deleted": null
          },
          {
              "id": 479,
              "name": "Thị xã Hương Thủy",
              "province_id": 46,
              "created_at": "2024-01-28T17:20:47.819Z",
              "updated_at": "2024-10-29T10:15:09.048Z",
              "popular": false,
              "slug": "thi-xa-huong-thuy",
              "deleted": null
          },
          {
              "id": 480,
              "name": "Thị xã Hương Trà",
              "province_id": 46,
              "created_at": "2024-01-28T17:20:47.831Z",
              "updated_at": "2024-10-29T10:15:09.056Z",
              "popular": false,
              "slug": "thi-xa-huong-tra",
              "deleted": null
          },
          {
              "id": 481,
              "name": "Huyện A Lưới",
              "province_id": 46,
              "created_at": "2024-01-28T17:20:47.844Z",
              "updated_at": "2024-10-29T10:15:09.070Z",
              "popular": false,
              "slug": "huyen-a-luoi",
              "deleted": null
          },
          {
              "id": 482,
              "name": "Huyện Phú Lộc",
              "province_id": 46,
              "created_at": "2024-01-28T17:20:47.855Z",
              "updated_at": "2024-10-29T10:15:09.079Z",
              "popular": false,
              "slug": "huyen-phu-loc",
              "deleted": null
          },
          {
              "id": 483,
              "name": "Huyện Nam Đông",
              "province_id": 46,
              "created_at": "2024-01-28T17:20:47.868Z",
              "updated_at": "2024-10-29T10:15:09.086Z",
              "popular": false,
              "slug": "huyen-nam-dong",
              "deleted": null
          }
      ]
  },
  {
      "id": 48,
      "name": "Đà Nẵng",
      "region": "middle",
      "created_at": "2024-01-28T17:20:42.577Z",
      "updated_at": "2024-10-29T10:15:06.521Z",
      "lat": 16.0726671,
      "lon": 108.0925761,
      "deleted": null,
      "slug": "da-nang",
      "district": [
          {
              "id": 490,
              "name": "Liên Chiểu",
              "province_id": 48,
              "created_at": "2024-01-28T17:20:47.882Z",
              "updated_at": "2024-10-29T10:15:09.093Z",
              "popular": true,
              "slug": "lien-chieu",
              "deleted": null
          },
          {
              "id": 491,
              "name": "Thanh Khê",
              "province_id": 48,
              "created_at": "2024-01-28T17:20:47.894Z",
              "updated_at": "2024-10-29T10:15:09.098Z",
              "popular": true,
              "slug": "thanh-khe",
              "deleted": null
          },
          {
              "id": 492,
              "name": "Hải Châu",
              "province_id": 48,
              "created_at": "2024-01-28T17:20:47.907Z",
              "updated_at": "2024-10-29T10:15:09.104Z",
              "popular": true,
              "slug": "hai-chau",
              "deleted": null
          },
          {
              "id": 493,
              "name": "Sơn Trà",
              "province_id": 48,
              "created_at": "2024-01-28T17:20:47.923Z",
              "updated_at": "2024-10-29T10:15:09.109Z",
              "popular": true,
              "slug": "son-tra",
              "deleted": null
          },
          {
              "id": 494,
              "name": "Ngũ Hành Sơn",
              "province_id": 48,
              "created_at": "2024-01-28T17:20:47.935Z",
              "updated_at": "2024-10-29T10:15:09.115Z",
              "popular": true,
              "slug": "ngu-hanh-son",
              "deleted": null
          },
          {
              "id": 495,
              "name": "Cẩm Lệ",
              "province_id": 48,
              "created_at": "2024-01-28T17:20:47.948Z",
              "updated_at": "2024-10-29T10:15:09.119Z",
              "popular": true,
              "slug": "cam-le",
              "deleted": null
          },
          {
              "id": 497,
              "name": "Hòa Vang",
              "province_id": 48,
              "created_at": "2024-01-28T17:20:47.964Z",
              "updated_at": "2024-10-29T10:15:09.125Z",
              "popular": true,
              "slug": "hoa-vang",
              "deleted": null
          },
          {
              "id": 498,
              "name": "Hoàng Sa",
              "province_id": 48,
              "created_at": "2024-01-28T17:20:47.977Z",
              "updated_at": "2024-10-29T10:15:09.132Z",
              "popular": true,
              "slug": "hoang-sa",
              "deleted": null
          }
      ]
  },
  {
      "id": 49,
      "name": "Quảng Nam",
      "region": "middle",
      "created_at": "2024-01-28T17:20:42.588Z",
      "updated_at": "2024-10-29T10:15:06.525Z",
      "lat": 15.573857,
      "lon": 108.474112,
      "deleted": null,
      "slug": "quang-nam",
      "district": [
          {
              "id": 502,
              "name": "Thành phố Tam Kỳ",
              "province_id": 49,
              "created_at": "2024-01-28T17:20:47.990Z",
              "updated_at": "2024-10-29T10:15:09.138Z",
              "popular": false,
              "slug": "thanh-pho-tam-ky",
              "deleted": null
          },
          {
              "id": 503,
              "name": "Thành phố Hội An",
              "province_id": 49,
              "created_at": "2024-01-28T17:20:48.002Z",
              "updated_at": "2024-10-29T10:15:09.145Z",
              "popular": false,
              "slug": "thanh-pho-hoi-an",
              "deleted": null
          },
          {
              "id": 504,
              "name": "Huyện Tây Giang",
              "province_id": 49,
              "created_at": "2024-01-28T17:20:48.020Z",
              "updated_at": "2024-10-29T10:15:09.151Z",
              "popular": false,
              "slug": "huyen-tay-giang",
              "deleted": null
          },
          {
              "id": 505,
              "name": "Huyện Đông Giang",
              "province_id": 49,
              "created_at": "2024-01-28T17:20:48.032Z",
              "updated_at": "2024-10-29T10:15:09.156Z",
              "popular": false,
              "slug": "huyen-dong-giang",
              "deleted": null
          },
          {
              "id": 506,
              "name": "Huyện Đại Lộc",
              "province_id": 49,
              "created_at": "2024-01-28T17:20:48.044Z",
              "updated_at": "2024-10-29T10:15:09.161Z",
              "popular": false,
              "slug": "huyen-dai-loc",
              "deleted": null
          },
          {
              "id": 507,
              "name": "Thị xã Điện Bàn",
              "province_id": 49,
              "created_at": "2024-01-28T17:20:48.058Z",
              "updated_at": "2024-10-29T10:15:09.168Z",
              "popular": false,
              "slug": "thi-xa-dien-ban",
              "deleted": null
          },
          {
              "id": 508,
              "name": "Huyện Duy Xuyên",
              "province_id": 49,
              "created_at": "2024-01-28T17:20:48.070Z",
              "updated_at": "2024-10-29T10:15:09.173Z",
              "popular": false,
              "slug": "huyen-duy-xuyen",
              "deleted": null
          },
          {
              "id": 509,
              "name": "Huyện Quế Sơn",
              "province_id": 49,
              "created_at": "2024-01-28T17:20:48.081Z",
              "updated_at": "2024-10-29T10:15:09.177Z",
              "popular": false,
              "slug": "huyen-que-son",
              "deleted": null
          },
          {
              "id": 510,
              "name": "Huyện Nam Giang",
              "province_id": 49,
              "created_at": "2024-01-28T17:20:48.096Z",
              "updated_at": "2024-10-29T10:15:09.183Z",
              "popular": false,
              "slug": "huyen-nam-giang",
              "deleted": null
          },
          {
              "id": 511,
              "name": "Huyện Phước Sơn",
              "province_id": 49,
              "created_at": "2024-01-28T17:20:48.118Z",
              "updated_at": "2024-10-29T10:15:09.187Z",
              "popular": false,
              "slug": "huyen-phuoc-son",
              "deleted": null
          },
          {
              "id": 512,
              "name": "Huyện Hiệp Đức",
              "province_id": 49,
              "created_at": "2024-01-28T17:20:48.132Z",
              "updated_at": "2024-10-29T10:15:09.191Z",
              "popular": false,
              "slug": "huyen-hiep-duc",
              "deleted": null
          },
          {
              "id": 513,
              "name": "Huyện Thăng Bình",
              "province_id": 49,
              "created_at": "2024-01-28T17:20:48.148Z",
              "updated_at": "2024-10-29T10:15:09.196Z",
              "popular": false,
              "slug": "huyen-thang-binh",
              "deleted": null
          },
          {
              "id": 514,
              "name": "Huyện Tiên Phước",
              "province_id": 49,
              "created_at": "2024-01-28T17:20:48.165Z",
              "updated_at": "2024-10-29T10:15:09.202Z",
              "popular": false,
              "slug": "huyen-tien-phuoc",
              "deleted": null
          },
          {
              "id": 515,
              "name": "Huyện Bắc Trà My",
              "province_id": 49,
              "created_at": "2024-01-28T17:20:48.178Z",
              "updated_at": "2024-10-29T10:15:09.207Z",
              "popular": false,
              "slug": "huyen-bac-tra-my",
              "deleted": null
          },
          {
              "id": 516,
              "name": "Huyện Nam Trà My",
              "province_id": 49,
              "created_at": "2024-01-28T17:20:48.191Z",
              "updated_at": "2024-10-29T10:15:09.212Z",
              "popular": false,
              "slug": "huyen-nam-tra-my",
              "deleted": null
          },
          {
              "id": 517,
              "name": "Huyện Núi Thành",
              "province_id": 49,
              "created_at": "2024-01-28T17:20:48.203Z",
              "updated_at": "2024-10-29T10:15:09.217Z",
              "popular": false,
              "slug": "huyen-nui-thanh",
              "deleted": null
          },
          {
              "id": 518,
              "name": "Huyện Phú Ninh",
              "province_id": 49,
              "created_at": "2024-01-28T17:20:48.217Z",
              "updated_at": "2024-10-29T10:15:09.222Z",
              "popular": false,
              "slug": "huyen-phu-ninh",
              "deleted": null
          },
          {
              "id": 519,
              "name": "Huyện Nông Sơn",
              "province_id": 49,
              "created_at": "2024-01-28T17:20:48.233Z",
              "updated_at": "2024-10-29T10:15:09.227Z",
              "popular": false,
              "slug": "huyen-nong-son",
              "deleted": null
          }
      ]
  },
  {
      "id": 51,
      "name": "Quảng Ngãi",
      "region": "middle",
      "created_at": "2024-01-28T17:20:42.605Z",
      "updated_at": "2024-10-29T10:15:06.531Z",
      "lat": 15.121388,
      "lon": 108.804633,
      "deleted": null,
      "slug": "quang-ngai",
      "district": [
          {
              "id": 522,
              "name": "Thành phố Quảng Ngãi",
              "province_id": 51,
              "created_at": "2024-01-28T17:20:48.248Z",
              "updated_at": "2024-10-29T10:15:09.234Z",
              "popular": false,
              "slug": "thanh-pho-quang-ngai",
              "deleted": null
          },
          {
              "id": 524,
              "name": "Huyện Bình Sơn",
              "province_id": 51,
              "created_at": "2024-01-28T17:20:48.260Z",
              "updated_at": "2024-10-29T10:15:09.240Z",
              "popular": false,
              "slug": "huyen-binh-son",
              "deleted": null
          },
          {
              "id": 525,
              "name": "Huyện Trà Bồng",
              "province_id": 51,
              "created_at": "2024-01-28T17:20:48.273Z",
              "updated_at": "2024-10-29T10:15:09.248Z",
              "popular": false,
              "slug": "huyen-tra-bong",
              "deleted": null
          },
          {
              "id": 527,
              "name": "Huyện Sơn Tịnh",
              "province_id": 51,
              "created_at": "2024-01-28T17:20:48.288Z",
              "updated_at": "2024-10-29T10:15:09.254Z",
              "popular": false,
              "slug": "huyen-son-tinh",
              "deleted": null
          },
          {
              "id": 528,
              "name": "Huyện Tư Nghĩa",
              "province_id": 51,
              "created_at": "2024-01-28T17:20:48.304Z",
              "updated_at": "2024-10-29T10:15:09.260Z",
              "popular": false,
              "slug": "huyen-tu-nghia",
              "deleted": null
          },
          {
              "id": 529,
              "name": "Huyện Sơn Hà",
              "province_id": 51,
              "created_at": "2024-01-28T17:20:48.320Z",
              "updated_at": "2024-10-29T10:15:09.267Z",
              "popular": false,
              "slug": "huyen-son-ha",
              "deleted": null
          },
          {
              "id": 530,
              "name": "Huyện Sơn Tây",
              "province_id": 51,
              "created_at": "2024-01-28T17:20:48.337Z",
              "updated_at": "2024-10-29T10:15:09.274Z",
              "popular": false,
              "slug": "huyen-son-tay",
              "deleted": null
          },
          {
              "id": 531,
              "name": "Huyện Minh Long",
              "province_id": 51,
              "created_at": "2024-01-28T17:20:48.352Z",
              "updated_at": "2024-10-29T10:15:09.279Z",
              "popular": false,
              "slug": "huyen-minh-long",
              "deleted": null
          },
          {
              "id": 532,
              "name": "Huyện Nghĩa Hành",
              "province_id": 51,
              "created_at": "2024-01-28T17:20:48.371Z",
              "updated_at": "2024-10-29T10:15:09.285Z",
              "popular": false,
              "slug": "huyen-nghia-hanh",
              "deleted": null
          },
          {
              "id": 533,
              "name": "Huyện Mộ Đức",
              "province_id": 51,
              "created_at": "2024-01-28T17:20:48.384Z",
              "updated_at": "2024-10-29T10:15:09.292Z",
              "popular": false,
              "slug": "huyen-mo-duc",
              "deleted": null
          },
          {
              "id": 534,
              "name": "Thị xã Đức Phổ",
              "province_id": 51,
              "created_at": "2024-01-28T17:20:48.400Z",
              "updated_at": "2024-10-29T10:15:09.299Z",
              "popular": false,
              "slug": "thi-xa-duc-pho",
              "deleted": null
          },
          {
              "id": 535,
              "name": "Huyện Ba Tơ",
              "province_id": 51,
              "created_at": "2024-01-28T17:20:48.414Z",
              "updated_at": "2024-10-29T10:15:09.304Z",
              "popular": false,
              "slug": "huyen-ba-to",
              "deleted": null
          },
          {
              "id": 536,
              "name": "Huyện Lý Sơn",
              "province_id": 51,
              "created_at": "2024-01-28T17:20:48.426Z",
              "updated_at": "2024-10-29T10:15:09.310Z",
              "popular": false,
              "slug": "huyen-ly-son",
              "deleted": null
          }
      ]
  },
  {
      "id": 52,
      "name": "Bình Định",
      "region": "middle",
      "created_at": "2024-01-28T17:20:42.617Z",
      "updated_at": "2024-10-29T10:15:06.537Z",
      "lat": 13.782026,
      "lon": 109.21946,
      "deleted": null,
      "slug": "binh-dinh",
      "district": [
          {
              "id": 540,
              "name": "Thành phố Quy Nhơn",
              "province_id": 52,
              "created_at": "2024-01-28T17:20:48.438Z",
              "updated_at": "2024-10-29T10:15:09.316Z",
              "popular": false,
              "slug": "thanh-pho-quy-nhon",
              "deleted": null
          },
          {
              "id": 542,
              "name": "Huyện An Lão",
              "province_id": 52,
              "created_at": "2024-01-28T17:20:48.449Z",
              "updated_at": "2024-10-29T10:15:09.322Z",
              "popular": false,
              "slug": "huyen-an-lao",
              "deleted": null
          },
          {
              "id": 543,
              "name": "Thị xã Hoài Nhơn",
              "province_id": 52,
              "created_at": "2024-01-28T17:20:48.461Z",
              "updated_at": "2024-10-29T10:15:09.328Z",
              "popular": false,
              "slug": "thi-xa-hoai-nhon",
              "deleted": null
          },
          {
              "id": 544,
              "name": "Huyện Hoài Ân",
              "province_id": 52,
              "created_at": "2024-01-28T17:20:48.474Z",
              "updated_at": "2024-10-29T10:15:09.338Z",
              "popular": false,
              "slug": "huyen-hoai-an",
              "deleted": null
          },
          {
              "id": 545,
              "name": "Huyện Phù Mỹ",
              "province_id": 52,
              "created_at": "2024-01-28T17:20:48.485Z",
              "updated_at": "2024-10-29T10:15:09.345Z",
              "popular": false,
              "slug": "huyen-phu-my",
              "deleted": null
          },
          {
              "id": 546,
              "name": "Huyện Vĩnh Thạnh",
              "province_id": 52,
              "created_at": "2024-01-28T17:20:48.498Z",
              "updated_at": "2024-10-29T10:15:09.352Z",
              "popular": false,
              "slug": "huyen-vinh-thanh",
              "deleted": null
          },
          {
              "id": 547,
              "name": "Huyện Tây Sơn",
              "province_id": 52,
              "created_at": "2024-01-28T17:20:48.509Z",
              "updated_at": "2024-10-29T10:15:09.358Z",
              "popular": false,
              "slug": "huyen-tay-son",
              "deleted": null
          },
          {
              "id": 548,
              "name": "Huyện Phù Cát",
              "province_id": 52,
              "created_at": "2024-01-28T17:20:48.524Z",
              "updated_at": "2024-10-29T10:15:09.364Z",
              "popular": false,
              "slug": "huyen-phu-cat",
              "deleted": null
          },
          {
              "id": 549,
              "name": "Thị xã An Nhơn",
              "province_id": 52,
              "created_at": "2024-01-28T17:20:48.536Z",
              "updated_at": "2024-10-29T10:15:09.370Z",
              "popular": false,
              "slug": "thi-xa-an-nhon",
              "deleted": null
          },
          {
              "id": 550,
              "name": "Huyện Tuy Phước",
              "province_id": 52,
              "created_at": "2024-01-28T17:20:48.548Z",
              "updated_at": "2024-10-29T10:15:09.375Z",
              "popular": false,
              "slug": "huyen-tuy-phuoc",
              "deleted": null
          },
          {
              "id": 551,
              "name": "Huyện Vân Canh",
              "province_id": 52,
              "created_at": "2024-01-28T17:20:48.567Z",
              "updated_at": "2024-10-29T10:15:09.380Z",
              "popular": false,
              "slug": "huyen-van-canh",
              "deleted": null
          }
      ]
  },
  {
      "id": 54,
      "name": "Phú Yên",
      "region": "middle",
      "created_at": "2024-01-28T17:20:42.630Z",
      "updated_at": "2024-10-29T10:15:06.543Z",
      "lat": 13.088186,
      "lon": 109.092876,
      "deleted": null,
      "slug": "phu-yen",
      "district": [
          {
              "id": 555,
              "name": "Thành phố Tuy Hoà",
              "province_id": 54,
              "created_at": "2024-01-28T17:20:48.580Z",
              "updated_at": "2024-10-29T10:15:09.384Z",
              "popular": false,
              "slug": "thanh-pho-tuy-hoa",
              "deleted": null
          },
          {
              "id": 557,
              "name": "Thị xã Sông Cầu",
              "province_id": 54,
              "created_at": "2024-01-28T17:20:48.592Z",
              "updated_at": "2024-10-29T10:15:09.389Z",
              "popular": false,
              "slug": "thi-xa-song-cau",
              "deleted": null
          },
          {
              "id": 558,
              "name": "Huyện Đồng Xuân",
              "province_id": 54,
              "created_at": "2024-01-28T17:20:48.604Z",
              "updated_at": "2024-10-29T10:15:09.393Z",
              "popular": false,
              "slug": "huyen-dong-xuan",
              "deleted": null
          },
          {
              "id": 559,
              "name": "Huyện Tuy An",
              "province_id": 54,
              "created_at": "2024-01-28T17:20:48.625Z",
              "updated_at": "2024-10-29T10:15:09.398Z",
              "popular": false,
              "slug": "huyen-tuy-an",
              "deleted": null
          },
          {
              "id": 560,
              "name": "Huyện Sơn Hòa",
              "province_id": 54,
              "created_at": "2024-01-28T17:20:48.638Z",
              "updated_at": "2024-10-29T10:15:09.403Z",
              "popular": false,
              "slug": "huyen-son-hoa",
              "deleted": null
          },
          {
              "id": 561,
              "name": "Huyện Sông Hinh",
              "province_id": 54,
              "created_at": "2024-01-28T17:20:48.652Z",
              "updated_at": "2024-10-29T10:15:09.408Z",
              "popular": false,
              "slug": "huyen-song-hinh",
              "deleted": null
          },
          {
              "id": 562,
              "name": "Huyện Tây Hoà",
              "province_id": 54,
              "created_at": "2024-01-28T17:20:48.667Z",
              "updated_at": "2024-10-29T10:15:09.412Z",
              "popular": false,
              "slug": "huyen-tay-hoa",
              "deleted": null
          },
          {
              "id": 563,
              "name": "Huyện Phú Hoà",
              "province_id": 54,
              "created_at": "2024-01-28T17:20:48.678Z",
              "updated_at": "2024-10-29T10:15:09.417Z",
              "popular": false,
              "slug": "huyen-phu-hoa",
              "deleted": null
          },
          {
              "id": 564,
              "name": "Thị xã Đông Hòa",
              "province_id": 54,
              "created_at": "2024-01-28T17:20:48.692Z",
              "updated_at": "2024-10-29T10:15:09.421Z",
              "popular": false,
              "slug": "thi-xa-dong-hoa",
              "deleted": null
          }
      ]
  },
  {
      "id": 56,
      "name": "Khánh Hòa",
      "region": "middle",
      "created_at": "2024-01-28T17:20:42.641Z",
      "updated_at": "2024-10-29T10:15:06.549Z",
      "lat": 12.258514,
      "lon": 109.045158,
      "deleted": null,
      "slug": "khanh-hoa",
      "district": [
          {
              "id": 568,
              "name": "Thành phố Nha Trang",
              "province_id": 56,
              "created_at": "2024-01-28T17:20:48.706Z",
              "updated_at": "2024-10-29T10:15:09.427Z",
              "popular": false,
              "slug": "thanh-pho-nha-trang",
              "deleted": null
          },
          {
              "id": 569,
              "name": "Thành phố Cam Ranh",
              "province_id": 56,
              "created_at": "2024-01-28T17:20:48.717Z",
              "updated_at": "2024-10-29T10:15:09.433Z",
              "popular": false,
              "slug": "thanh-pho-cam-ranh",
              "deleted": null
          },
          {
              "id": 570,
              "name": "Huyện Cam Lâm",
              "province_id": 56,
              "created_at": "2024-01-28T17:20:48.731Z",
              "updated_at": "2024-10-29T10:15:09.440Z",
              "popular": false,
              "slug": "huyen-cam-lam",
              "deleted": null
          },
          {
              "id": 571,
              "name": "Huyện Vạn Ninh",
              "province_id": 56,
              "created_at": "2024-01-28T17:20:48.742Z",
              "updated_at": "2024-10-29T10:15:09.448Z",
              "popular": false,
              "slug": "huyen-van-ninh",
              "deleted": null
          },
          {
              "id": 572,
              "name": "Thị xã Ninh Hòa",
              "province_id": 56,
              "created_at": "2024-01-28T17:20:48.756Z",
              "updated_at": "2024-10-29T10:15:09.456Z",
              "popular": false,
              "slug": "thi-xa-ninh-hoa",
              "deleted": null
          },
          {
              "id": 573,
              "name": "Huyện Khánh Vĩnh",
              "province_id": 56,
              "created_at": "2024-01-28T17:20:48.766Z",
              "updated_at": "2024-10-29T10:15:09.463Z",
              "popular": false,
              "slug": "huyen-khanh-vinh",
              "deleted": null
          },
          {
              "id": 574,
              "name": "Huyện Diên Khánh",
              "province_id": 56,
              "created_at": "2024-01-28T17:20:48.780Z",
              "updated_at": "2024-10-29T10:15:09.469Z",
              "popular": false,
              "slug": "huyen-dien-khanh",
              "deleted": null
          },
          {
              "id": 575,
              "name": "Huyện Khánh Sơn",
              "province_id": 56,
              "created_at": "2024-01-28T17:20:48.792Z",
              "updated_at": "2024-10-29T10:15:09.476Z",
              "popular": false,
              "slug": "huyen-khanh-son",
              "deleted": null
          },
          {
              "id": 576,
              "name": "Huyện Trường Sa",
              "province_id": 56,
              "created_at": "2024-01-28T17:20:48.802Z",
              "updated_at": "2024-10-29T10:15:09.483Z",
              "popular": false,
              "slug": "huyen-truong-sa",
              "deleted": null
          }
      ]
  },
  {
      "id": 58,
      "name": "Ninh Thuận",
      "region": "middle",
      "created_at": "2024-01-28T17:20:42.652Z",
      "updated_at": "2024-10-29T10:15:06.554Z",
      "lat": 11.567636,
      "lon": 108.990609,
      "deleted": null,
      "slug": "ninh-thuan",
      "district": [
          {
              "id": 582,
              "name": "Thành phố Phan Rang-Tháp Chàm",
              "province_id": 58,
              "created_at": "2024-01-28T17:20:48.816Z",
              "updated_at": "2024-10-29T10:15:09.488Z",
              "popular": false,
              "slug": "thanh-pho-phan-rang-thap-cham",
              "deleted": null
          },
          {
              "id": 584,
              "name": "Huyện Bác Ái",
              "province_id": 58,
              "created_at": "2024-01-28T17:20:48.826Z",
              "updated_at": "2024-10-29T10:15:09.493Z",
              "popular": false,
              "slug": "huyen-bac-ai",
              "deleted": null
          },
          {
              "id": 585,
              "name": "Huyện Ninh Sơn",
              "province_id": 58,
              "created_at": "2024-01-28T17:20:48.840Z",
              "updated_at": "2024-10-29T10:15:09.498Z",
              "popular": false,
              "slug": "huyen-ninh-son",
              "deleted": null
          },
          {
              "id": 586,
              "name": "Huyện Ninh Hải",
              "province_id": 58,
              "created_at": "2024-01-28T17:20:48.850Z",
              "updated_at": "2024-10-29T10:15:09.504Z",
              "popular": false,
              "slug": "huyen-ninh-hai",
              "deleted": null
          },
          {
              "id": 587,
              "name": "Huyện Ninh Phước",
              "province_id": 58,
              "created_at": "2024-01-28T17:20:48.862Z",
              "updated_at": "2024-10-29T10:15:09.510Z",
              "popular": false,
              "slug": "huyen-ninh-phuoc",
              "deleted": null
          },
          {
              "id": 588,
              "name": "Huyện Thuận Bắc",
              "province_id": 58,
              "created_at": "2024-01-28T17:20:48.874Z",
              "updated_at": "2024-10-29T10:15:09.516Z",
              "popular": false,
              "slug": "huyen-thuan-bac",
              "deleted": null
          },
          {
              "id": 589,
              "name": "Huyện Thuận Nam",
              "province_id": 58,
              "created_at": "2024-01-28T17:20:48.888Z",
              "updated_at": "2024-10-29T10:15:09.523Z",
              "popular": false,
              "slug": "huyen-thuan-nam",
              "deleted": null
          }
      ]
  },
  {
      "id": 60,
      "name": "Bình Thuận",
      "region": "middle",
      "created_at": "2024-01-28T17:20:42.665Z",
      "updated_at": "2024-10-29T10:15:06.559Z",
      "lat": 11.090088,
      "lon": 108.072079,
      "deleted": null,
      "slug": "binh-thuan",
      "district": [
          {
              "id": 593,
              "name": "Thành phố Phan Thiết",
              "province_id": 60,
              "created_at": "2024-01-28T17:20:48.898Z",
              "updated_at": "2024-10-29T10:15:09.533Z",
              "popular": false,
              "slug": "thanh-pho-phan-thiet",
              "deleted": null
          },
          {
              "id": 594,
              "name": "Thị xã La Gi",
              "province_id": 60,
              "created_at": "2024-01-28T17:20:48.912Z",
              "updated_at": "2024-10-29T10:15:09.540Z",
              "popular": false,
              "slug": "thi-xa-la-gi",
              "deleted": null
          },
          {
              "id": 595,
              "name": "Huyện Tuy Phong",
              "province_id": 60,
              "created_at": "2024-01-28T17:20:48.922Z",
              "updated_at": "2024-10-29T10:15:09.551Z",
              "popular": false,
              "slug": "huyen-tuy-phong",
              "deleted": null
          },
          {
              "id": 596,
              "name": "Huyện Bắc Bình",
              "province_id": 60,
              "created_at": "2024-01-28T17:20:48.936Z",
              "updated_at": "2024-10-29T10:15:09.558Z",
              "popular": false,
              "slug": "huyen-bac-binh",
              "deleted": null
          },
          {
              "id": 597,
              "name": "Huyện Hàm Thuận Bắc",
              "province_id": 60,
              "created_at": "2024-01-28T17:20:48.947Z",
              "updated_at": "2024-10-29T10:15:09.564Z",
              "popular": false,
              "slug": "huyen-ham-thuan-bac",
              "deleted": null
          },
          {
              "id": 598,
              "name": "Huyện Hàm Thuận Nam",
              "province_id": 60,
              "created_at": "2024-01-28T17:20:48.960Z",
              "updated_at": "2024-10-29T10:15:09.570Z",
              "popular": false,
              "slug": "huyen-ham-thuan-nam",
              "deleted": null
          },
          {
              "id": 599,
              "name": "Huyện Tánh Linh",
              "province_id": 60,
              "created_at": "2024-01-28T17:20:48.973Z",
              "updated_at": "2024-10-29T10:15:09.575Z",
              "popular": false,
              "slug": "huyen-tanh-linh",
              "deleted": null
          },
          {
              "id": 600,
              "name": "Huyện Đức Linh",
              "province_id": 60,
              "created_at": "2024-01-28T17:20:48.983Z",
              "updated_at": "2024-10-29T10:15:09.580Z",
              "popular": false,
              "slug": "huyen-duc-linh",
              "deleted": null
          },
          {
              "id": 601,
              "name": "Huyện Hàm Tân",
              "province_id": 60,
              "created_at": "2024-01-28T17:20:48.996Z",
              "updated_at": "2024-10-29T10:15:09.585Z",
              "popular": false,
              "slug": "huyen-ham-tan",
              "deleted": null
          },
          {
              "id": 602,
              "name": "Huyện Phú Quí",
              "province_id": 60,
              "created_at": "2024-01-28T17:20:49.009Z",
              "updated_at": "2024-10-29T10:15:09.588Z",
              "popular": false,
              "slug": "huyen-phu-qui",
              "deleted": null
          }
      ]
  },
  {
      "id": 62,
      "name": "Kon Tum",
      "region": "middle",
      "created_at": "2024-01-28T17:20:42.677Z",
      "updated_at": "2024-10-29T10:15:06.566Z",
      "lat": 14.35042,
      "lon": 107.982337,
      "deleted": null,
      "slug": "kon-tum",
      "district": [
          {
              "id": 608,
              "name": "Thành phố Kon Tum",
              "province_id": 62,
              "created_at": "2024-01-28T17:20:49.024Z",
              "updated_at": "2024-10-29T10:15:09.593Z",
              "popular": false,
              "slug": "thanh-pho-kon-tum",
              "deleted": null
          },
          {
              "id": 610,
              "name": "Huyện Đắk Glei",
              "province_id": 62,
              "created_at": "2024-01-28T17:20:49.040Z",
              "updated_at": "2024-10-29T10:15:09.597Z",
              "popular": false,
              "slug": "huyen-dak-glei",
              "deleted": null
          },
          {
              "id": 611,
              "name": "Huyện Ngọc Hồi",
              "province_id": 62,
              "created_at": "2024-01-28T17:20:49.054Z",
              "updated_at": "2024-10-29T10:15:09.601Z",
              "popular": false,
              "slug": "huyen-ngoc-hoi",
              "deleted": null
          },
          {
              "id": 612,
              "name": "Huyện Đắk Tô",
              "province_id": 62,
              "created_at": "2024-01-28T17:20:49.068Z",
              "updated_at": "2024-10-29T10:15:09.605Z",
              "popular": false,
              "slug": "huyen-dak-to",
              "deleted": null
          },
          {
              "id": 613,
              "name": "Huyện Kon Plông",
              "province_id": 62,
              "created_at": "2024-01-28T17:20:49.083Z",
              "updated_at": "2024-10-29T10:15:09.609Z",
              "popular": false,
              "slug": "huyen-kon-plong",
              "deleted": null
          },
          {
              "id": 614,
              "name": "Huyện Kon Rẫy",
              "province_id": 62,
              "created_at": "2024-01-28T17:20:49.095Z",
              "updated_at": "2024-10-29T10:15:09.613Z",
              "popular": false,
              "slug": "huyen-kon-ray",
              "deleted": null
          },
          {
              "id": 615,
              "name": "Huyện Đắk Hà",
              "province_id": 62,
              "created_at": "2024-01-28T17:20:49.108Z",
              "updated_at": "2024-10-29T10:15:09.617Z",
              "popular": false,
              "slug": "huyen-dak-ha",
              "deleted": null
          },
          {
              "id": 616,
              "name": "Huyện Sa Thầy",
              "province_id": 62,
              "created_at": "2024-01-28T17:20:49.123Z",
              "updated_at": "2024-10-29T10:15:09.622Z",
              "popular": false,
              "slug": "huyen-sa-thay",
              "deleted": null
          },
          {
              "id": 617,
              "name": "Huyện Tu Mơ Rông",
              "province_id": 62,
              "created_at": "2024-01-28T17:20:49.135Z",
              "updated_at": "2024-10-29T10:15:09.627Z",
              "popular": false,
              "slug": "huyen-tu-mo-rong",
              "deleted": null
          },
          {
              "id": 618,
              "name": "Huyện Ia H' Drai",
              "province_id": 62,
              "created_at": "2024-01-28T17:20:49.148Z",
              "updated_at": "2024-10-29T10:15:09.633Z",
              "popular": false,
              "slug": "huyen-ia-h-drai",
              "deleted": null
          }
      ]
  },
  {
      "id": 64,
      "name": "Gia Lai",
      "region": "middle",
      "created_at": "2024-01-28T17:20:42.688Z",
      "updated_at": "2024-10-29T10:15:06.572Z",
      "lat": 13.971199,
      "lon": 108.014623,
      "deleted": null,
      "slug": "gia-lai",
      "district": [
          {
              "id": 622,
              "name": "Thành phố Pleiku",
              "province_id": 64,
              "created_at": "2024-01-28T17:20:49.165Z",
              "updated_at": "2024-10-29T10:15:09.639Z",
              "popular": false,
              "slug": "thanh-pho-pleiku",
              "deleted": null
          },
          {
              "id": 623,
              "name": "Thị xã An Khê",
              "province_id": 64,
              "created_at": "2024-01-28T17:20:49.183Z",
              "updated_at": "2024-10-29T10:15:09.646Z",
              "popular": false,
              "slug": "thi-xa-an-khe",
              "deleted": null
          },
          {
              "id": 624,
              "name": "Thị xã Ayun Pa",
              "province_id": 64,
              "created_at": "2024-01-28T17:20:49.196Z",
              "updated_at": "2024-10-29T10:15:09.653Z",
              "popular": false,
              "slug": "thi-xa-ayun-pa",
              "deleted": null
          },
          {
              "id": 625,
              "name": "Huyện KBang",
              "province_id": 64,
              "created_at": "2024-01-28T17:20:49.216Z",
              "updated_at": "2024-10-29T10:15:09.659Z",
              "popular": false,
              "slug": "huyen-kbang",
              "deleted": null
          },
          {
              "id": 626,
              "name": "Huyện Đăk Đoa",
              "province_id": 64,
              "created_at": "2024-01-28T17:20:49.229Z",
              "updated_at": "2024-10-29T10:15:09.667Z",
              "popular": false,
              "slug": "huyen-dak-doa",
              "deleted": null
          },
          {
              "id": 627,
              "name": "Huyện Chư Păh",
              "province_id": 64,
              "created_at": "2024-01-28T17:20:49.243Z",
              "updated_at": "2024-10-29T10:15:09.672Z",
              "popular": false,
              "slug": "huyen-chu-pah",
              "deleted": null
          },
          {
              "id": 628,
              "name": "Huyện Ia Grai",
              "province_id": 64,
              "created_at": "2024-01-28T17:20:49.256Z",
              "updated_at": "2024-10-29T10:15:09.677Z",
              "popular": false,
              "slug": "huyen-ia-grai",
              "deleted": null
          },
          {
              "id": 629,
              "name": "Huyện Mang Yang",
              "province_id": 64,
              "created_at": "2024-01-28T17:20:49.270Z",
              "updated_at": "2024-10-29T10:15:09.682Z",
              "popular": false,
              "slug": "huyen-mang-yang",
              "deleted": null
          },
          {
              "id": 630,
              "name": "Huyện Kông Chro",
              "province_id": 64,
              "created_at": "2024-01-28T17:20:49.282Z",
              "updated_at": "2024-10-29T10:15:09.686Z",
              "popular": false,
              "slug": "huyen-kong-chro",
              "deleted": null
          },
          {
              "id": 631,
              "name": "Huyện Đức Cơ",
              "province_id": 64,
              "created_at": "2024-01-28T17:20:49.296Z",
              "updated_at": "2024-10-29T10:15:09.691Z",
              "popular": false,
              "slug": "huyen-duc-co",
              "deleted": null
          },
          {
              "id": 632,
              "name": "Huyện Chư Prông",
              "province_id": 64,
              "created_at": "2024-01-28T17:20:49.312Z",
              "updated_at": "2024-10-29T10:15:09.696Z",
              "popular": false,
              "slug": "huyen-chu-prong",
              "deleted": null
          },
          {
              "id": 633,
              "name": "Huyện Chư Sê",
              "province_id": 64,
              "created_at": "2024-01-28T17:20:49.327Z",
              "updated_at": "2024-10-29T10:15:09.701Z",
              "popular": false,
              "slug": "huyen-chu-se",
              "deleted": null
          },
          {
              "id": 634,
              "name": "Huyện Đăk Pơ",
              "province_id": 64,
              "created_at": "2024-01-28T17:20:49.340Z",
              "updated_at": "2024-10-29T10:15:09.707Z",
              "popular": false,
              "slug": "huyen-dak-po",
              "deleted": null
          },
          {
              "id": 635,
              "name": "Huyện Ia Pa",
              "province_id": 64,
              "created_at": "2024-01-28T17:20:49.352Z",
              "updated_at": "2024-10-29T10:15:09.712Z",
              "popular": false,
              "slug": "huyen-ia-pa",
              "deleted": null
          },
          {
              "id": 637,
              "name": "Huyện Krông Pa",
              "province_id": 64,
              "created_at": "2024-01-28T17:20:49.366Z",
              "updated_at": "2024-10-29T10:15:09.717Z",
              "popular": false,
              "slug": "huyen-krong-pa",
              "deleted": null
          },
          {
              "id": 638,
              "name": "Huyện Phú Thiện",
              "province_id": 64,
              "created_at": "2024-01-28T17:20:49.380Z",
              "updated_at": "2024-10-29T10:15:09.722Z",
              "popular": false,
              "slug": "huyen-phu-thien",
              "deleted": null
          },
          {
              "id": 639,
              "name": "Huyện Chư Pưh",
              "province_id": 64,
              "created_at": "2024-01-28T17:20:49.396Z",
              "updated_at": "2024-10-29T10:15:09.728Z",
              "popular": false,
              "slug": "huyen-chu-puh",
              "deleted": null
          }
      ]
  },
  {
      "id": 66,
      "name": "Đắk Lắk",
      "region": "middle",
      "created_at": "2024-01-28T17:20:42.702Z",
      "updated_at": "2024-10-29T10:15:06.578Z",
      "lat": 12.666684,
      "lon": 108.037951,
      "deleted": null,
      "slug": "dak-lak",
      "district": [
          {
              "id": 643,
              "name": "Thành phố Buôn Ma Thuột",
              "province_id": 66,
              "created_at": "2024-01-28T17:20:49.408Z",
              "updated_at": "2024-10-29T10:15:09.734Z",
              "popular": false,
              "slug": "thanh-pho-buon-ma-thuot",
              "deleted": null
          },
          {
              "id": 644,
              "name": "Thị xã Buôn Hồ",
              "province_id": 66,
              "created_at": "2024-01-28T17:20:49.424Z",
              "updated_at": "2024-10-29T10:15:09.740Z",
              "popular": false,
              "slug": "thi-xa-buon-ho",
              "deleted": null
          },
          {
              "id": 645,
              "name": "Huyện Ea H'leo",
              "province_id": 66,
              "created_at": "2024-01-28T17:20:49.437Z",
              "updated_at": "2024-10-29T10:15:09.746Z",
              "popular": false,
              "slug": "huyen-ea-hleo",
              "deleted": null
          },
          {
              "id": 646,
              "name": "Huyện Ea Súp",
              "province_id": 66,
              "created_at": "2024-01-28T17:20:49.451Z",
              "updated_at": "2024-10-29T10:15:09.757Z",
              "popular": false,
              "slug": "huyen-ea-sup",
              "deleted": null
          },
          {
              "id": 647,
              "name": "Huyện Buôn Đôn",
              "province_id": 66,
              "created_at": "2024-01-28T17:20:49.462Z",
              "updated_at": "2024-10-29T10:15:09.764Z",
              "popular": false,
              "slug": "huyen-buon-don",
              "deleted": null
          },
          {
              "id": 648,
              "name": "Huyện Cư M'gar",
              "province_id": 66,
              "created_at": "2024-01-28T17:20:49.476Z",
              "updated_at": "2024-10-29T10:15:09.769Z",
              "popular": false,
              "slug": "huyen-cu-mgar",
              "deleted": null
          },
          {
              "id": 649,
              "name": "Huyện Krông Búk",
              "province_id": 66,
              "created_at": "2024-01-28T17:20:49.493Z",
              "updated_at": "2024-10-29T10:15:09.774Z",
              "popular": false,
              "slug": "huyen-krong-buk",
              "deleted": null
          },
          {
              "id": 650,
              "name": "Huyện Krông Năng",
              "province_id": 66,
              "created_at": "2024-01-28T17:20:49.505Z",
              "updated_at": "2024-10-29T10:15:09.780Z",
              "popular": false,
              "slug": "huyen-krong-nang",
              "deleted": null
          },
          {
              "id": 651,
              "name": "Huyện Ea Kar",
              "province_id": 66,
              "created_at": "2024-01-28T17:20:49.519Z",
              "updated_at": "2024-10-29T10:15:09.785Z",
              "popular": false,
              "slug": "huyen-ea-kar",
              "deleted": null
          },
          {
              "id": 652,
              "name": "Huyện M'Đrắk",
              "province_id": 66,
              "created_at": "2024-01-28T17:20:49.531Z",
              "updated_at": "2024-10-29T10:15:09.790Z",
              "popular": false,
              "slug": "huyen-mdrak",
              "deleted": null
          },
          {
              "id": 653,
              "name": "Huyện Krông Bông",
              "province_id": 66,
              "created_at": "2024-01-28T17:20:49.544Z",
              "updated_at": "2024-10-29T10:15:09.795Z",
              "popular": false,
              "slug": "huyen-krong-bong",
              "deleted": null
          },
          {
              "id": 654,
              "name": "Huyện Krông Pắc",
              "province_id": 66,
              "created_at": "2024-01-28T17:20:49.557Z",
              "updated_at": "2024-10-29T10:15:09.799Z",
              "popular": false,
              "slug": "huyen-krong-pac",
              "deleted": null
          },
          {
              "id": 655,
              "name": "Huyện Krông A Na",
              "province_id": 66,
              "created_at": "2024-01-28T17:20:49.571Z",
              "updated_at": "2024-10-29T10:15:09.804Z",
              "popular": false,
              "slug": "huyen-krong-a-na",
              "deleted": null
          },
          {
              "id": 656,
              "name": "Huyện Lắk",
              "province_id": 66,
              "created_at": "2024-01-28T17:20:49.582Z",
              "updated_at": "2024-10-29T10:15:09.810Z",
              "popular": false,
              "slug": "huyen-lak",
              "deleted": null
          },
          {
              "id": 657,
              "name": "Huyện Cư Kuin",
              "province_id": 66,
              "created_at": "2024-01-28T17:20:49.600Z",
              "updated_at": "2024-10-29T10:15:09.815Z",
              "popular": false,
              "slug": "huyen-cu-kuin",
              "deleted": null
          }
      ]
  },
  {
      "id": 67,
      "name": "Đắk Nông",
      "region": "middle",
      "created_at": "2024-01-28T17:20:42.713Z",
      "updated_at": "2024-10-29T10:15:06.583Z",
      "lat": 12.112607,
      "lon": 107.711234,
      "deleted": null,
      "slug": "dak-nong",
      "district": [
          {
              "id": 660,
              "name": "Thành phố Gia Nghĩa",
              "province_id": 67,
              "created_at": "2024-01-28T17:20:49.616Z",
              "updated_at": "2024-10-29T10:15:09.820Z",
              "popular": false,
              "slug": "thanh-pho-gia-nghia",
              "deleted": null
          },
          {
              "id": 661,
              "name": "Huyện Đăk Glong",
              "province_id": 67,
              "created_at": "2024-01-28T17:20:49.629Z",
              "updated_at": "2024-10-29T10:15:09.826Z",
              "popular": false,
              "slug": "huyen-dak-glong",
              "deleted": null
          },
          {
              "id": 662,
              "name": "Huyện Cư Jút",
              "province_id": 67,
              "created_at": "2024-01-28T17:20:49.644Z",
              "updated_at": "2024-10-29T10:15:09.830Z",
              "popular": false,
              "slug": "huyen-cu-jut",
              "deleted": null
          },
          {
              "id": 663,
              "name": "Huyện Đắk Mil",
              "province_id": 67,
              "created_at": "2024-01-28T17:20:49.657Z",
              "updated_at": "2024-10-29T10:15:09.835Z",
              "popular": false,
              "slug": "huyen-dak-mil",
              "deleted": null
          },
          {
              "id": 664,
              "name": "Huyện Krông Nô",
              "province_id": 67,
              "created_at": "2024-01-28T17:20:49.669Z",
              "updated_at": "2024-10-29T10:15:09.840Z",
              "popular": false,
              "slug": "huyen-krong-no",
              "deleted": null
          },
          {
              "id": 665,
              "name": "Huyện Đắk Song",
              "province_id": 67,
              "created_at": "2024-01-28T17:20:49.690Z",
              "updated_at": "2024-10-29T10:15:09.844Z",
              "popular": false,
              "slug": "huyen-dak-song",
              "deleted": null
          },
          {
              "id": 666,
              "name": "Huyện Đắk R'Lấp",
              "province_id": 67,
              "created_at": "2024-01-28T17:20:49.732Z",
              "updated_at": "2024-10-29T10:15:09.850Z",
              "popular": false,
              "slug": "huyen-dak-rlap",
              "deleted": null
          },
          {
              "id": 667,
              "name": "Huyện Tuy Đức",
              "province_id": 67,
              "created_at": "2024-01-28T17:20:49.791Z",
              "updated_at": "2024-10-29T10:15:09.856Z",
              "popular": false,
              "slug": "huyen-tuy-duc",
              "deleted": null
          }
      ]
  },
  {
      "id": 68,
      "name": "Lâm Đồng",
      "region": "middle",
      "created_at": "2024-01-28T17:20:42.727Z",
      "updated_at": "2024-10-29T10:15:06.589Z",
      "lat": 11.575279,
      "lon": 107.837982,
      "deleted": null,
      "slug": "lam-dong",
      "district": [
          {
              "id": 672,
              "name": "Thành phố Đà Lạt",
              "province_id": 68,
              "created_at": "2024-01-28T17:20:49.834Z",
              "updated_at": "2024-10-29T10:15:09.863Z",
              "popular": false,
              "slug": "thanh-pho-da-lat",
              "deleted": null
          },
          {
              "id": 673,
              "name": "Thành phố Bảo Lộc",
              "province_id": 68,
              "created_at": "2024-01-28T17:20:49.911Z",
              "updated_at": "2024-10-29T10:15:09.868Z",
              "popular": false,
              "slug": "thanh-pho-bao-loc",
              "deleted": null
          },
          {
              "id": 674,
              "name": "Huyện Đam Rông",
              "province_id": 68,
              "created_at": "2024-01-28T17:20:49.964Z",
              "updated_at": "2024-10-29T10:15:09.873Z",
              "popular": false,
              "slug": "huyen-dam-rong",
              "deleted": null
          },
          {
              "id": 675,
              "name": "Huyện Lạc Dương",
              "province_id": 68,
              "created_at": "2024-01-28T17:20:49.996Z",
              "updated_at": "2024-10-29T10:15:09.878Z",
              "popular": false,
              "slug": "huyen-lac-duong",
              "deleted": null
          },
          {
              "id": 676,
              "name": "Huyện Lâm Hà",
              "province_id": 68,
              "created_at": "2024-01-28T17:20:50.008Z",
              "updated_at": "2024-10-29T10:15:09.883Z",
              "popular": false,
              "slug": "huyen-lam-ha",
              "deleted": null
          },
          {
              "id": 677,
              "name": "Huyện Đơn Dương",
              "province_id": 68,
              "created_at": "2024-01-28T17:20:50.019Z",
              "updated_at": "2024-10-29T10:15:09.890Z",
              "popular": false,
              "slug": "huyen-don-duong",
              "deleted": null
          },
          {
              "id": 678,
              "name": "Huyện Đức Trọng",
              "province_id": 68,
              "created_at": "2024-01-28T17:20:50.031Z",
              "updated_at": "2024-10-29T10:15:09.895Z",
              "popular": false,
              "slug": "huyen-duc-trong",
              "deleted": null
          },
          {
              "id": 679,
              "name": "Huyện Di Linh",
              "province_id": 68,
              "created_at": "2024-01-28T17:20:50.045Z",
              "updated_at": "2024-10-29T10:15:09.899Z",
              "popular": false,
              "slug": "huyen-di-linh",
              "deleted": null
          },
          {
              "id": 680,
              "name": "Huyện Bảo Lâm",
              "province_id": 68,
              "created_at": "2024-01-28T17:20:50.060Z",
              "updated_at": "2024-10-29T10:15:09.904Z",
              "popular": false,
              "slug": "huyen-bao-lam",
              "deleted": null
          },
          {
              "id": 681,
              "name": "Huyện Đạ Huoai",
              "province_id": 68,
              "created_at": "2024-01-28T17:20:50.073Z",
              "updated_at": "2024-10-29T10:15:09.908Z",
              "popular": false,
              "slug": "huyen-da-huoai",
              "deleted": null
          },
          {
              "id": 682,
              "name": "Huyện Đạ Tẻh",
              "province_id": 68,
              "created_at": "2024-01-28T17:20:50.086Z",
              "updated_at": "2024-10-29T10:15:09.912Z",
              "popular": false,
              "slug": "huyen-da-teh",
              "deleted": null
          },
          {
              "id": 683,
              "name": "Huyện Cát Tiên",
              "province_id": 68,
              "created_at": "2024-01-28T17:20:50.098Z",
              "updated_at": "2024-10-29T10:15:09.917Z",
              "popular": false,
              "slug": "huyen-cat-tien",
              "deleted": null
          }
      ]
  },
  {
      "id": 70,
      "name": "Bình Phước",
      "region": "south",
      "created_at": "2024-01-28T17:20:42.739Z",
      "updated_at": "2024-10-29T10:15:06.596Z",
      "lat": 11.721173,
      "lon": 106.929374,
      "deleted": null,
      "slug": "binh-phuoc",
      "district": [
          {
              "id": 688,
              "name": "Thị xã Phước Long",
              "province_id": 70,
              "created_at": "2024-01-28T17:20:50.109Z",
              "updated_at": "2024-10-29T10:15:09.922Z",
              "popular": false,
              "slug": "thi-xa-phuoc-long",
              "deleted": null
          },
          {
              "id": 689,
              "name": "Thành phố Đồng Xoài",
              "province_id": 70,
              "created_at": "2024-01-28T17:20:50.134Z",
              "updated_at": "2024-10-29T10:15:09.927Z",
              "popular": false,
              "slug": "thanh-pho-dong-xoai",
              "deleted": null
          },
          {
              "id": 690,
              "name": "Thị xã Bình Long",
              "province_id": 70,
              "created_at": "2024-01-28T17:20:50.148Z",
              "updated_at": "2024-10-29T10:15:09.933Z",
              "popular": false,
              "slug": "thi-xa-binh-long",
              "deleted": null
          },
          {
              "id": 691,
              "name": "Huyện Bù Gia Mập",
              "province_id": 70,
              "created_at": "2024-01-28T17:20:50.162Z",
              "updated_at": "2024-10-29T10:15:09.940Z",
              "popular": false,
              "slug": "huyen-bu-gia-map",
              "deleted": null
          },
          {
              "id": 692,
              "name": "Huyện Lộc Ninh",
              "province_id": 70,
              "created_at": "2024-01-28T17:20:50.175Z",
              "updated_at": "2024-10-29T10:15:09.947Z",
              "popular": false,
              "slug": "huyen-loc-ninh",
              "deleted": null
          },
          {
              "id": 693,
              "name": "Huyện Bù Đốp",
              "province_id": 70,
              "created_at": "2024-01-28T17:20:50.191Z",
              "updated_at": "2024-10-29T10:15:09.953Z",
              "popular": false,
              "slug": "huyen-bu-dop",
              "deleted": null
          },
          {
              "id": 694,
              "name": "Huyện Hớn Quản",
              "province_id": 70,
              "created_at": "2024-01-28T17:20:50.205Z",
              "updated_at": "2024-10-29T10:15:09.959Z",
              "popular": false,
              "slug": "huyen-hon-quan",
              "deleted": null
          },
          {
              "id": 695,
              "name": "Huyện Đồng Phú",
              "province_id": 70,
              "created_at": "2024-01-28T17:20:50.218Z",
              "updated_at": "2024-10-29T10:15:09.965Z",
              "popular": false,
              "slug": "huyen-dong-phu",
              "deleted": null
          },
          {
              "id": 696,
              "name": "Huyện Bù Đăng",
              "province_id": 70,
              "created_at": "2024-01-28T17:20:50.233Z",
              "updated_at": "2024-10-29T10:15:09.971Z",
              "popular": false,
              "slug": "huyen-bu-dang",
              "deleted": null
          },
          {
              "id": 697,
              "name": "Thị xã Chơn Thành",
              "province_id": 70,
              "created_at": "2024-01-28T17:20:50.250Z",
              "updated_at": "2024-10-29T10:15:09.976Z",
              "popular": false,
              "slug": "thi-xa-chon-thanh",
              "deleted": null
          },
          {
              "id": 698,
              "name": "Huyện Phú Riềng",
              "province_id": 70,
              "created_at": "2024-01-28T17:20:50.271Z",
              "updated_at": "2024-10-29T10:15:09.981Z",
              "popular": false,
              "slug": "huyen-phu-rieng",
              "deleted": null
          }
      ]
  },
  {
      "id": 72,
      "name": "Tây Ninh",
      "region": "south",
      "created_at": "2024-01-28T17:20:42.755Z",
      "updated_at": "2024-10-29T10:15:06.600Z",
      "lat": 11.359056,
      "lon": 106.125722,
      "deleted": null,
      "slug": "tay-ninh",
      "district": [
          {
              "id": 703,
              "name": "Thành phố Tây Ninh",
              "province_id": 72,
              "created_at": "2024-01-28T17:20:50.287Z",
              "updated_at": "2024-10-29T10:15:09.985Z",
              "popular": false,
              "slug": "thanh-pho-tay-ninh",
              "deleted": null
          },
          {
              "id": 705,
              "name": "Huyện Tân Biên",
              "province_id": 72,
              "created_at": "2024-01-28T17:20:50.308Z",
              "updated_at": "2024-10-29T10:15:09.990Z",
              "popular": false,
              "slug": "huyen-tan-bien",
              "deleted": null
          },
          {
              "id": 706,
              "name": "Huyện Tân Châu",
              "province_id": 72,
              "created_at": "2024-01-28T17:20:50.323Z",
              "updated_at": "2024-10-29T10:15:09.995Z",
              "popular": false,
              "slug": "huyen-tan-chau",
              "deleted": null
          },
          {
              "id": 707,
              "name": "Huyện Dương Minh Châu",
              "province_id": 72,
              "created_at": "2024-01-28T17:20:50.336Z",
              "updated_at": "2024-10-29T10:15:10.000Z",
              "popular": false,
              "slug": "huyen-duong-minh-chau",
              "deleted": null
          },
          {
              "id": 708,
              "name": "Huyện Châu Thành",
              "province_id": 72,
              "created_at": "2024-01-28T17:20:50.350Z",
              "updated_at": "2024-10-29T10:15:10.007Z",
              "popular": false,
              "slug": "huyen-chau-thanh",
              "deleted": null
          },
          {
              "id": 709,
              "name": "Thị xã Hòa Thành",
              "province_id": 72,
              "created_at": "2024-01-28T17:20:50.362Z",
              "updated_at": "2024-10-29T10:15:10.012Z",
              "popular": false,
              "slug": "thi-xa-hoa-thanh",
              "deleted": null
          },
          {
              "id": 710,
              "name": "Huyện Gò Dầu",
              "province_id": 72,
              "created_at": "2024-01-28T17:20:50.376Z",
              "updated_at": "2024-10-29T10:15:10.017Z",
              "popular": false,
              "slug": "huyen-go-dau",
              "deleted": null
          },
          {
              "id": 711,
              "name": "Huyện Bến Cầu",
              "province_id": 72,
              "created_at": "2024-01-28T17:20:50.389Z",
              "updated_at": "2024-10-29T10:15:10.022Z",
              "popular": false,
              "slug": "huyen-ben-cau",
              "deleted": null
          },
          {
              "id": 712,
              "name": "Thị xã Trảng Bàng",
              "province_id": 72,
              "created_at": "2024-01-28T17:20:50.402Z",
              "updated_at": "2024-10-29T10:15:10.027Z",
              "popular": false,
              "slug": "thi-xa-trang-bang",
              "deleted": null
          }
      ]
  },
  {
      "id": 74,
      "name": "Bình Dương",
      "region": "south",
      "created_at": "2024-01-28T17:20:42.770Z",
      "updated_at": "2024-10-29T10:15:06.606Z",
      "lat": 11.1707765,
      "lon": 106.6698995,
      "deleted": null,
      "slug": "binh-duong",
      "district": [
          {
              "id": 718,
              "name": "Thành phố Thủ Dầu Một",
              "province_id": 74,
              "created_at": "2024-01-28T17:20:50.414Z",
              "updated_at": "2024-10-29T10:15:10.036Z",
              "popular": false,
              "slug": "thanh-pho-thu-dau-mot",
              "deleted": null
          },
          {
              "id": 719,
              "name": "Huyện Bàu Bàng",
              "province_id": 74,
              "created_at": "2024-01-28T17:20:50.428Z",
              "updated_at": "2024-10-29T10:15:10.044Z",
              "popular": false,
              "slug": "huyen-bau-bang",
              "deleted": null
          },
          {
              "id": 720,
              "name": "Huyện Dầu Tiếng",
              "province_id": 74,
              "created_at": "2024-01-28T17:20:50.443Z",
              "updated_at": "2024-10-29T10:15:10.050Z",
              "popular": false,
              "slug": "huyen-dau-tieng",
              "deleted": null
          },
          {
              "id": 721,
              "name": "Thị xã Bến Cát",
              "province_id": 74,
              "created_at": "2024-01-28T17:20:50.454Z",
              "updated_at": "2024-10-29T10:15:10.065Z",
              "popular": false,
              "slug": "thi-xa-ben-cat",
              "deleted": null
          },
          {
              "id": 722,
              "name": "Huyện Phú Giáo",
              "province_id": 74,
              "created_at": "2024-01-28T17:20:50.467Z",
              "updated_at": "2024-10-29T10:15:10.092Z",
              "popular": false,
              "slug": "huyen-phu-giao",
              "deleted": null
          },
          {
              "id": 723,
              "name": "Thành phố Tân Uyên",
              "province_id": 74,
              "created_at": "2024-01-28T17:20:50.479Z",
              "updated_at": "2024-10-29T10:15:10.098Z",
              "popular": false,
              "slug": "thanh-pho-tan-uyen",
              "deleted": null
          },
          {
              "id": 724,
              "name": "Thành phố Dĩ An",
              "province_id": 74,
              "created_at": "2024-01-28T17:20:50.492Z",
              "updated_at": "2024-10-29T10:15:10.105Z",
              "popular": false,
              "slug": "thanh-pho-di-an",
              "deleted": null
          },
          {
              "id": 725,
              "name": "Thành phố Thuận An",
              "province_id": 74,
              "created_at": "2024-01-28T17:20:50.504Z",
              "updated_at": "2024-10-29T10:15:10.112Z",
              "popular": false,
              "slug": "thanh-pho-thuan-an",
              "deleted": null
          },
          {
              "id": 726,
              "name": "Huyện Bắc Tân Uyên",
              "province_id": 74,
              "created_at": "2024-01-28T17:20:50.520Z",
              "updated_at": "2024-10-29T10:15:10.119Z",
              "popular": false,
              "slug": "huyen-bac-tan-uyen",
              "deleted": null
          }
      ]
  },
  {
      "id": 75,
      "name": "Đồng Nai",
      "region": "south",
      "created_at": "2024-01-28T17:20:42.785Z",
      "updated_at": "2024-10-29T10:15:06.612Z",
      "lat": 10.9547167,
      "lon": 107.2734235,
      "deleted": null,
      "slug": "dong-nai",
      "district": [
          {
              "id": 731,
              "name": "Thành phố Biên Hòa",
              "province_id": 75,
              "created_at": "2024-01-28T17:20:50.535Z",
              "updated_at": "2024-10-29T10:15:10.126Z",
              "popular": false,
              "slug": "thanh-pho-bien-hoa",
              "deleted": null
          },
          {
              "id": 732,
              "name": "Thành phố Long Khánh",
              "province_id": 75,
              "created_at": "2024-01-28T17:20:50.548Z",
              "updated_at": "2024-10-29T10:15:10.136Z",
              "popular": false,
              "slug": "thanh-pho-long-khanh",
              "deleted": null
          },
          {
              "id": 734,
              "name": "Huyện Tân Phú",
              "province_id": 75,
              "created_at": "2024-01-28T17:20:50.562Z",
              "updated_at": "2024-10-29T10:15:10.149Z",
              "popular": false,
              "slug": "huyen-tan-phu",
              "deleted": null
          },
          {
              "id": 735,
              "name": "Huyện Vĩnh Cửu",
              "province_id": 75,
              "created_at": "2024-01-28T17:20:50.574Z",
              "updated_at": "2024-10-29T10:15:10.169Z",
              "popular": false,
              "slug": "huyen-vinh-cuu",
              "deleted": null
          },
          {
              "id": 736,
              "name": "Huyện Định Quán",
              "province_id": 75,
              "created_at": "2024-01-28T17:20:50.586Z",
              "updated_at": "2024-10-29T10:15:10.182Z",
              "popular": false,
              "slug": "huyen-dinh-quan",
              "deleted": null
          },
          {
              "id": 737,
              "name": "Huyện Trảng Bom",
              "province_id": 75,
              "created_at": "2024-01-28T17:20:50.599Z",
              "updated_at": "2024-10-29T10:15:10.193Z",
              "popular": false,
              "slug": "huyen-trang-bom",
              "deleted": null
          },
          {
              "id": 738,
              "name": "Huyện Thống Nhất",
              "province_id": 75,
              "created_at": "2024-01-28T17:20:50.611Z",
              "updated_at": "2024-10-29T10:15:10.203Z",
              "popular": false,
              "slug": "huyen-thong-nhat",
              "deleted": null
          },
          {
              "id": 739,
              "name": "Huyện Cẩm Mỹ",
              "province_id": 75,
              "created_at": "2024-01-28T17:20:50.623Z",
              "updated_at": "2024-10-29T10:15:10.209Z",
              "popular": false,
              "slug": "huyen-cam-my",
              "deleted": null
          },
          {
              "id": 740,
              "name": "Huyện Long Thành",
              "province_id": 75,
              "created_at": "2024-01-28T17:20:50.636Z",
              "updated_at": "2024-10-29T10:15:10.216Z",
              "popular": false,
              "slug": "huyen-long-thanh",
              "deleted": null
          },
          {
              "id": 741,
              "name": "Huyện Xuân Lộc",
              "province_id": 75,
              "created_at": "2024-01-28T17:20:50.651Z",
              "updated_at": "2024-10-29T10:15:10.221Z",
              "popular": false,
              "slug": "huyen-xuan-loc",
              "deleted": null
          },
          {
              "id": 742,
              "name": "Huyện Nhơn Trạch",
              "province_id": 75,
              "created_at": "2024-01-28T17:20:50.663Z",
              "updated_at": "2024-10-29T10:15:10.227Z",
              "popular": false,
              "slug": "huyen-nhon-trach",
              "deleted": null
          }
      ]
  },
  {
      "id": 77,
      "name": "Bà Rịa - Vũng Tàu",
      "region": "south",
      "created_at": "2024-01-28T17:20:42.797Z",
      "updated_at": "2024-10-29T10:15:06.617Z",
      "lat": 10.541739,
      "lon": 107.242997,
      "deleted": null,
      "slug": "ba-ria-vung-tau",
      "district": [
          {
              "id": 747,
              "name": "Thành phố Vũng Tàu",
              "province_id": 77,
              "created_at": "2024-01-28T17:20:50.676Z",
              "updated_at": "2024-10-29T10:15:10.238Z",
              "popular": false,
              "slug": "thanh-pho-vung-tau",
              "deleted": null
          },
          {
              "id": 748,
              "name": "Thành phố Bà Rịa",
              "province_id": 77,
              "created_at": "2024-01-28T17:20:50.694Z",
              "updated_at": "2024-10-29T10:15:10.247Z",
              "popular": false,
              "slug": "thanh-pho-ba-ria",
              "deleted": null
          },
          {
              "id": 750,
              "name": "Huyện Châu Đức",
              "province_id": 77,
              "created_at": "2024-01-28T17:20:50.708Z",
              "updated_at": "2024-10-29T10:15:10.255Z",
              "popular": false,
              "slug": "huyen-chau-duc",
              "deleted": null
          },
          {
              "id": 751,
              "name": "Huyện Xuyên Mộc",
              "province_id": 77,
              "created_at": "2024-01-28T17:20:50.719Z",
              "updated_at": "2024-10-29T10:15:10.260Z",
              "popular": false,
              "slug": "huyen-xuyen-moc",
              "deleted": null
          },
          {
              "id": 752,
              "name": "Huyện Long Điền",
              "province_id": 77,
              "created_at": "2024-01-28T17:20:50.732Z",
              "updated_at": "2024-10-29T10:15:10.267Z",
              "popular": false,
              "slug": "huyen-long-dien",
              "deleted": null
          },
          {
              "id": 753,
              "name": "Huyện Đất Đỏ",
              "province_id": 77,
              "created_at": "2024-01-28T17:20:50.760Z",
              "updated_at": "2024-10-29T10:15:10.272Z",
              "popular": false,
              "slug": "huyen-dat-do",
              "deleted": null
          },
          {
              "id": 754,
              "name": "Thị xã Phú Mỹ",
              "province_id": 77,
              "created_at": "2024-01-28T17:20:50.775Z",
              "updated_at": "2024-10-29T10:15:10.278Z",
              "popular": false,
              "slug": "thi-xa-phu-my",
              "deleted": null
          },
          {
              "id": 755,
              "name": "Huyện Côn Đảo",
              "province_id": 77,
              "created_at": "2024-01-28T17:20:50.787Z",
              "updated_at": "2024-10-29T10:15:10.284Z",
              "popular": false,
              "slug": "huyen-con-dao",
              "deleted": null
          }
      ]
  },
  {
      "id": 79,
      "name": "Hồ Chí Minh",
      "region": "south",
      "created_at": "2024-01-28T17:20:42.808Z",
      "updated_at": "2024-10-29T10:15:06.700Z",
      "lat": 10.762622,
      "lon": 106.660172,
      "deleted": null,
      "slug": "ho-chi-minh",
      "district": [
          {
              "id": 760,
              "name": "Quận 1",
              "province_id": 79,
              "created_at": "2024-01-28T17:20:50.798Z",
              "updated_at": "2024-10-29T10:15:10.290Z",
              "popular": true,
              "slug": "quan-1",
              "deleted": null
          },
          {
              "id": 761,
              "name": "Quận 12",
              "province_id": 79,
              "created_at": "2024-01-28T17:20:50.811Z",
              "updated_at": "2024-10-29T10:15:10.296Z",
              "popular": true,
              "slug": "quan-12",
              "deleted": null
          },
          {
              "id": 764,
              "name": "Gò Vấp",
              "province_id": 79,
              "created_at": "2024-01-28T17:20:50.822Z",
              "updated_at": "2024-10-29T10:15:10.302Z",
              "popular": true,
              "slug": "go-vap",
              "deleted": null
          },
          {
              "id": 765,
              "name": "Bình Thạnh",
              "province_id": 79,
              "created_at": "2024-01-28T17:20:50.838Z",
              "updated_at": "2024-10-29T10:15:10.307Z",
              "popular": true,
              "slug": "binh-thanh",
              "deleted": null
          },
          {
              "id": 766,
              "name": "Tân Bình",
              "province_id": 79,
              "created_at": "2024-01-28T17:20:50.850Z",
              "updated_at": "2024-10-29T10:15:10.312Z",
              "popular": true,
              "slug": "tan-binh",
              "deleted": null
          },
          {
              "id": 767,
              "name": "Tân Phú",
              "province_id": 79,
              "created_at": "2024-01-28T17:20:50.864Z",
              "updated_at": "2024-10-29T10:15:10.318Z",
              "popular": true,
              "slug": "tan-phu",
              "deleted": null
          },
          {
              "id": 768,
              "name": "Phú Nhuận",
              "province_id": 79,
              "created_at": "2024-01-28T17:20:50.878Z",
              "updated_at": "2024-10-29T10:15:10.324Z",
              "popular": true,
              "slug": "phu-nhuan",
              "deleted": null
          },
          {
              "id": 769,
              "name": "Thủ Đức",
              "province_id": 79,
              "created_at": "2024-01-28T17:20:50.892Z",
              "updated_at": "2024-10-29T10:15:10.331Z",
              "popular": true,
              "slug": "thu-duc",
              "deleted": null
          },
          {
              "id": 770,
              "name": "Quận 3",
              "province_id": 79,
              "created_at": "2024-01-28T17:20:50.905Z",
              "updated_at": "2024-10-29T10:15:10.338Z",
              "popular": true,
              "slug": "quan-3",
              "deleted": null
          },
          {
              "id": 771,
              "name": "Quận 10",
              "province_id": 79,
              "created_at": "2024-01-28T17:20:50.920Z",
              "updated_at": "2024-10-29T10:15:10.343Z",
              "popular": true,
              "slug": "quan-10",
              "deleted": null
          },
          {
              "id": 772,
              "name": "Quận 11",
              "province_id": 79,
              "created_at": "2024-01-28T17:20:50.932Z",
              "updated_at": "2024-10-29T10:15:10.350Z",
              "popular": true,
              "slug": "quan-11",
              "deleted": null
          },
          {
              "id": 773,
              "name": "Quận 4",
              "province_id": 79,
              "created_at": "2024-01-28T17:20:50.946Z",
              "updated_at": "2024-10-29T10:15:10.356Z",
              "popular": true,
              "slug": "quan-4",
              "deleted": null
          },
          {
              "id": 774,
              "name": "Quận 5",
              "province_id": 79,
              "created_at": "2024-01-28T17:20:50.958Z",
              "updated_at": "2024-10-29T10:15:10.361Z",
              "popular": true,
              "slug": "quan-5",
              "deleted": null
          },
          {
              "id": 775,
              "name": "Quận 6",
              "province_id": 79,
              "created_at": "2024-01-28T17:20:50.972Z",
              "updated_at": "2024-10-29T10:15:10.368Z",
              "popular": true,
              "slug": "quan-6",
              "deleted": null
          },
          {
              "id": 776,
              "name": "Quận 8",
              "province_id": 79,
              "created_at": "2024-01-28T17:20:50.988Z",
              "updated_at": "2024-10-29T10:15:10.372Z",
              "popular": true,
              "slug": "quan-8",
              "deleted": null
          },
          {
              "id": 777,
              "name": "Bình Tân",
              "province_id": 79,
              "created_at": "2024-01-28T17:20:51.002Z",
              "updated_at": "2024-10-29T10:15:10.377Z",
              "popular": true,
              "slug": "binh-tan",
              "deleted": null
          },
          {
              "id": 778,
              "name": "Quận 7",
              "province_id": 79,
              "created_at": "2024-01-28T17:20:51.019Z",
              "updated_at": "2024-10-29T10:15:10.382Z",
              "popular": true,
              "slug": "quan-7",
              "deleted": null
          },
          {
              "id": 783,
              "name": "Củ Chi",
              "province_id": 79,
              "created_at": "2024-01-28T17:20:51.032Z",
              "updated_at": "2024-10-29T10:15:10.388Z",
              "popular": false,
              "slug": "cu-chi",
              "deleted": null
          },
          {
              "id": 784,
              "name": "Hóc Môn",
              "province_id": 79,
              "created_at": "2024-01-28T17:20:51.044Z",
              "updated_at": "2024-10-29T10:15:10.393Z",
              "popular": false,
              "slug": "hoc-mon",
              "deleted": null
          },
          {
              "id": 785,
              "name": "Bình Chánh",
              "province_id": 79,
              "created_at": "2024-01-28T17:20:51.062Z",
              "updated_at": "2024-10-29T10:15:10.399Z",
              "popular": false,
              "slug": "binh-chanh",
              "deleted": null
          },
          {
              "id": 786,
              "name": "Nhà Bè",
              "province_id": 79,
              "created_at": "2024-01-28T17:20:51.074Z",
              "updated_at": "2024-10-29T10:15:10.404Z",
              "popular": false,
              "slug": "nha-be",
              "deleted": null
          },
          {
              "id": 787,
              "name": "Cần Giờ",
              "province_id": 79,
              "created_at": "2024-01-28T17:20:51.088Z",
              "updated_at": "2024-10-29T10:15:10.410Z",
              "popular": false,
              "slug": "can-gio",
              "deleted": null
          }
      ]
  },
  {
      "id": 80,
      "name": "Long An",
      "region": "south",
      "created_at": "2024-01-28T17:20:42.822Z",
      "updated_at": "2024-10-29T10:15:06.624Z",
      "lat": 10.535247,
      "lon": 106.404732,
      "deleted": null,
      "slug": "long-an",
      "district": [
          {
              "id": 794,
              "name": "Thành phố Tân An",
              "province_id": 80,
              "created_at": "2024-01-28T17:20:51.103Z",
              "updated_at": "2024-10-29T10:15:10.415Z",
              "popular": false,
              "slug": "thanh-pho-tan-an",
              "deleted": null
          },
          {
              "id": 795,
              "name": "Thị xã Kiến Tường",
              "province_id": 80,
              "created_at": "2024-01-28T17:20:51.116Z",
              "updated_at": "2024-10-29T10:15:10.420Z",
              "popular": false,
              "slug": "thi-xa-kien-tuong",
              "deleted": null
          },
          {
              "id": 796,
              "name": "Huyện Tân Hưng",
              "province_id": 80,
              "created_at": "2024-01-28T17:20:51.132Z",
              "updated_at": "2024-10-29T10:15:10.426Z",
              "popular": false,
              "slug": "huyen-tan-hung",
              "deleted": null
          },
          {
              "id": 797,
              "name": "Huyện Vĩnh Hưng",
              "province_id": 80,
              "created_at": "2024-01-28T17:20:51.148Z",
              "updated_at": "2024-10-29T10:15:10.431Z",
              "popular": false,
              "slug": "huyen-vinh-hung",
              "deleted": null
          },
          {
              "id": 798,
              "name": "Huyện Mộc Hóa",
              "province_id": 80,
              "created_at": "2024-01-28T17:20:51.161Z",
              "updated_at": "2024-10-29T10:15:10.440Z",
              "popular": false,
              "slug": "huyen-moc-hoa",
              "deleted": null
          },
          {
              "id": 799,
              "name": "Huyện Tân Thạnh",
              "province_id": 80,
              "created_at": "2024-01-28T17:20:51.175Z",
              "updated_at": "2024-10-29T10:15:10.445Z",
              "popular": false,
              "slug": "huyen-tan-thanh",
              "deleted": null
          },
          {
              "id": 800,
              "name": "Huyện Thạnh Hóa",
              "province_id": 80,
              "created_at": "2024-01-28T17:20:51.187Z",
              "updated_at": "2024-10-29T10:15:10.452Z",
              "popular": false,
              "slug": "huyen-thanh-hoa",
              "deleted": null
          },
          {
              "id": 801,
              "name": "Huyện Đức Huệ",
              "province_id": 80,
              "created_at": "2024-01-28T17:20:51.200Z",
              "updated_at": "2024-10-29T10:15:10.457Z",
              "popular": false,
              "slug": "huyen-duc-hue",
              "deleted": null
          },
          {
              "id": 802,
              "name": "Huyện Đức Hòa",
              "province_id": 80,
              "created_at": "2024-01-28T17:20:51.212Z",
              "updated_at": "2024-10-29T10:15:10.461Z",
              "popular": false,
              "slug": "huyen-duc-hoa",
              "deleted": null
          },
          {
              "id": 803,
              "name": "Huyện Bến Lức",
              "province_id": 80,
              "created_at": "2024-01-28T17:20:51.226Z",
              "updated_at": "2024-10-29T10:15:10.466Z",
              "popular": false,
              "slug": "huyen-ben-luc",
              "deleted": null
          },
          {
              "id": 804,
              "name": "Huyện Thủ Thừa",
              "province_id": 80,
              "created_at": "2024-01-28T17:20:51.239Z",
              "updated_at": "2024-10-29T10:15:10.471Z",
              "popular": false,
              "slug": "huyen-thu-thua",
              "deleted": null
          },
          {
              "id": 805,
              "name": "Huyện Tân Trụ",
              "province_id": 80,
              "created_at": "2024-01-28T17:20:51.252Z",
              "updated_at": "2024-10-29T10:15:10.476Z",
              "popular": false,
              "slug": "huyen-tan-tru",
              "deleted": null
          },
          {
              "id": 806,
              "name": "Huyện Cần Đước",
              "province_id": 80,
              "created_at": "2024-01-28T17:20:51.267Z",
              "updated_at": "2024-10-29T10:15:10.481Z",
              "popular": false,
              "slug": "huyen-can-duoc",
              "deleted": null
          },
          {
              "id": 807,
              "name": "Huyện Cần Giuộc",
              "province_id": 80,
              "created_at": "2024-01-28T17:20:51.279Z",
              "updated_at": "2024-10-29T10:15:10.486Z",
              "popular": false,
              "slug": "huyen-can-giuoc",
              "deleted": null
          },
          {
              "id": 808,
              "name": "Huyện Châu Thành",
              "province_id": 80,
              "created_at": "2024-01-28T17:20:51.295Z",
              "updated_at": "2024-10-29T10:15:10.491Z",
              "popular": false,
              "slug": "huyen-chau-thanh",
              "deleted": null
          }
      ]
  },
  {
      "id": 82,
      "name": "Tiền Giang",
      "region": "south",
      "created_at": "2024-01-28T17:20:42.833Z",
      "updated_at": "2024-10-29T10:15:06.629Z",
      "lat": 10.358791,
      "lon": 106.36528,
      "deleted": null,
      "slug": "tien-giang",
      "district": [
          {
              "id": 815,
              "name": "Thành phố Mỹ Tho",
              "province_id": 82,
              "created_at": "2024-01-28T17:20:51.309Z",
              "updated_at": "2024-10-29T10:15:10.496Z",
              "popular": false,
              "slug": "thanh-pho-my-tho",
              "deleted": null
          },
          {
              "id": 816,
              "name": "Thị xã Gò Công",
              "province_id": 82,
              "created_at": "2024-01-28T17:20:51.328Z",
              "updated_at": "2024-10-29T10:15:10.501Z",
              "popular": false,
              "slug": "thi-xa-go-cong",
              "deleted": null
          },
          {
              "id": 817,
              "name": "Thị xã Cai Lậy",
              "province_id": 82,
              "created_at": "2024-01-28T17:20:51.348Z",
              "updated_at": "2024-10-29T10:15:10.507Z",
              "popular": false,
              "slug": "thi-xa-cai-lay",
              "deleted": null
          },
          {
              "id": 818,
              "name": "Huyện Tân Phước",
              "province_id": 82,
              "created_at": "2024-01-28T17:20:51.363Z",
              "updated_at": "2024-10-29T10:15:10.514Z",
              "popular": false,
              "slug": "huyen-tan-phuoc",
              "deleted": null
          },
          {
              "id": 819,
              "name": "Huyện Cái Bè",
              "province_id": 82,
              "created_at": "2024-01-28T17:20:51.378Z",
              "updated_at": "2024-10-29T10:15:10.520Z",
              "popular": false,
              "slug": "huyen-cai-be",
              "deleted": null
          },
          {
              "id": 820,
              "name": "Huyện Cai Lậy",
              "province_id": 82,
              "created_at": "2024-01-28T17:20:51.392Z",
              "updated_at": "2024-10-29T10:15:10.526Z",
              "popular": false,
              "slug": "huyen-cai-lay",
              "deleted": null
          },
          {
              "id": 821,
              "name": "Huyện Châu Thành",
              "province_id": 82,
              "created_at": "2024-01-28T17:20:51.409Z",
              "updated_at": "2024-10-29T10:15:10.531Z",
              "popular": false,
              "slug": "huyen-chau-thanh",
              "deleted": null
          },
          {
              "id": 822,
              "name": "Huyện Chợ Gạo",
              "province_id": 82,
              "created_at": "2024-01-28T17:20:51.422Z",
              "updated_at": "2024-10-29T10:15:10.536Z",
              "popular": false,
              "slug": "huyen-cho-gao",
              "deleted": null
          },
          {
              "id": 823,
              "name": "Huyện Gò Công Tây",
              "province_id": 82,
              "created_at": "2024-01-28T17:20:51.435Z",
              "updated_at": "2024-10-29T10:15:10.541Z",
              "popular": false,
              "slug": "huyen-go-cong-tay",
              "deleted": null
          },
          {
              "id": 824,
              "name": "Huyện Gò Công Đông",
              "province_id": 82,
              "created_at": "2024-01-28T17:20:51.447Z",
              "updated_at": "2024-10-29T10:15:10.546Z",
              "popular": false,
              "slug": "huyen-go-cong-dong",
              "deleted": null
          },
          {
              "id": 825,
              "name": "Huyện Tân Phú Đông",
              "province_id": 82,
              "created_at": "2024-01-28T17:20:51.460Z",
              "updated_at": "2024-10-29T10:15:10.552Z",
              "popular": false,
              "slug": "huyen-tan-phu-dong",
              "deleted": null
          }
      ]
  },
  {
      "id": 83,
      "name": "Bến Tre",
      "region": "south",
      "created_at": "2024-01-28T17:20:42.844Z",
      "updated_at": "2024-10-29T10:15:06.636Z",
      "lat": 10.241979,
      "lon": 106.375054,
      "deleted": null,
      "slug": "ben-tre",
      "district": [
          {
              "id": 829,
              "name": "Thành phố Bến Tre",
              "province_id": 83,
              "created_at": "2024-01-28T17:20:51.473Z",
              "updated_at": "2024-10-29T10:15:10.560Z",
              "popular": false,
              "slug": "thanh-pho-ben-tre",
              "deleted": null
          },
          {
              "id": 831,
              "name": "Huyện Châu Thành",
              "province_id": 83,
              "created_at": "2024-01-28T17:20:51.488Z",
              "updated_at": "2024-10-29T10:15:10.568Z",
              "popular": false,
              "slug": "huyen-chau-thanh",
              "deleted": null
          },
          {
              "id": 832,
              "name": "Huyện Chợ Lách",
              "province_id": 83,
              "created_at": "2024-01-28T17:20:51.501Z",
              "updated_at": "2024-10-29T10:15:10.573Z",
              "popular": false,
              "slug": "huyen-cho-lach",
              "deleted": null
          },
          {
              "id": 833,
              "name": "Huyện Mỏ Cày Nam",
              "province_id": 83,
              "created_at": "2024-01-28T17:20:51.515Z",
              "updated_at": "2024-10-29T10:15:10.586Z",
              "popular": false,
              "slug": "huyen-mo-cay-nam",
              "deleted": null
          },
          {
              "id": 834,
              "name": "Huyện Giồng Trôm",
              "province_id": 83,
              "created_at": "2024-01-28T17:20:51.527Z",
              "updated_at": "2024-10-29T10:15:10.594Z",
              "popular": false,
              "slug": "huyen-giong-trom",
              "deleted": null
          },
          {
              "id": 835,
              "name": "Huyện Bình Đại",
              "province_id": 83,
              "created_at": "2024-01-28T17:20:51.541Z",
              "updated_at": "2024-10-29T10:15:10.599Z",
              "popular": false,
              "slug": "huyen-binh-dai",
              "deleted": null
          },
          {
              "id": 836,
              "name": "Huyện Ba Tri",
              "province_id": 83,
              "created_at": "2024-01-28T17:20:51.555Z",
              "updated_at": "2024-10-29T10:15:10.604Z",
              "popular": false,
              "slug": "huyen-ba-tri",
              "deleted": null
          },
          {
              "id": 837,
              "name": "Huyện Thạnh Phú",
              "province_id": 83,
              "created_at": "2024-01-28T17:20:51.566Z",
              "updated_at": "2024-10-29T10:15:10.627Z",
              "popular": false,
              "slug": "huyen-thanh-phu",
              "deleted": null
          },
          {
              "id": 838,
              "name": "Huyện Mỏ Cày Bắc",
              "province_id": 83,
              "created_at": "2024-01-28T17:20:51.579Z",
              "updated_at": "2024-10-29T10:15:10.634Z",
              "popular": false,
              "slug": "huyen-mo-cay-bac",
              "deleted": null
          }
      ]
  },
  {
      "id": 84,
      "name": "Trà Vinh",
      "region": "south",
      "created_at": "2024-01-28T17:20:42.857Z",
      "updated_at": "2024-10-29T10:15:06.641Z",
      "lat": 9.935182,
      "lon": 106.34139,
      "deleted": null,
      "slug": "tra-vinh",
      "district": [
          {
              "id": 842,
              "name": "Thành phố Trà Vinh",
              "province_id": 84,
              "created_at": "2024-01-28T17:20:51.592Z",
              "updated_at": "2024-10-29T10:15:10.640Z",
              "popular": false,
              "slug": "thanh-pho-tra-vinh",
              "deleted": null
          },
          {
              "id": 844,
              "name": "Huyện Càng Long",
              "province_id": 84,
              "created_at": "2024-01-28T17:20:51.607Z",
              "updated_at": "2024-10-29T10:15:10.657Z",
              "popular": false,
              "slug": "huyen-cang-long",
              "deleted": null
          },
          {
              "id": 845,
              "name": "Huyện Cầu Kè",
              "province_id": 84,
              "created_at": "2024-01-28T17:20:51.619Z",
              "updated_at": "2024-10-29T10:15:10.662Z",
              "popular": false,
              "slug": "huyen-cau-ke",
              "deleted": null
          },
          {
              "id": 846,
              "name": "Huyện Tiểu Cần",
              "province_id": 84,
              "created_at": "2024-01-28T17:20:51.632Z",
              "updated_at": "2024-10-29T10:15:10.668Z",
              "popular": false,
              "slug": "huyen-tieu-can",
              "deleted": null
          },
          {
              "id": 847,
              "name": "Huyện Châu Thành",
              "province_id": 84,
              "created_at": "2024-01-28T17:20:51.644Z",
              "updated_at": "2024-10-29T10:15:10.682Z",
              "popular": false,
              "slug": "huyen-chau-thanh",
              "deleted": null
          },
          {
              "id": 848,
              "name": "Huyện Cầu Ngang",
              "province_id": 84,
              "created_at": "2024-01-28T17:20:51.657Z",
              "updated_at": "2024-10-29T10:15:10.688Z",
              "popular": false,
              "slug": "huyen-cau-ngang",
              "deleted": null
          },
          {
              "id": 849,
              "name": "Huyện Trà Cú",
              "province_id": 84,
              "created_at": "2024-01-28T17:20:51.669Z",
              "updated_at": "2024-10-29T10:15:10.693Z",
              "popular": false,
              "slug": "huyen-tra-cu",
              "deleted": null
          },
          {
              "id": 850,
              "name": "Huyện Duyên Hải",
              "province_id": 84,
              "created_at": "2024-01-28T17:20:51.680Z",
              "updated_at": "2024-10-29T10:15:10.698Z",
              "popular": false,
              "slug": "huyen-duyen-hai",
              "deleted": null
          },
          {
              "id": 851,
              "name": "Thị xã Duyên Hải",
              "province_id": 84,
              "created_at": "2024-01-28T17:20:51.692Z",
              "updated_at": "2024-10-29T10:15:10.704Z",
              "popular": false,
              "slug": "thi-xa-duyen-hai",
              "deleted": null
          }
      ]
  },
  {
      "id": 86,
      "name": "Vĩnh Long",
      "region": "south",
      "created_at": "2024-01-28T17:20:42.867Z",
      "updated_at": "2024-10-29T10:15:06.648Z",
      "lat": 10.255093,
      "lon": 105.9754,
      "deleted": null,
      "slug": "vinh-long",
      "district": [
          {
              "id": 855,
              "name": "Thành phố Vĩnh Long",
              "province_id": 86,
              "created_at": "2024-01-28T17:20:51.706Z",
              "updated_at": "2024-10-29T10:15:10.709Z",
              "popular": false,
              "slug": "thanh-pho-vinh-long",
              "deleted": null
          },
          {
              "id": 857,
              "name": "Huyện Long Hồ",
              "province_id": 86,
              "created_at": "2024-01-28T17:20:51.717Z",
              "updated_at": "2024-10-29T10:15:10.714Z",
              "popular": false,
              "slug": "huyen-long-ho",
              "deleted": null
          },
          {
              "id": 858,
              "name": "Huyện Mang Thít",
              "province_id": 86,
              "created_at": "2024-01-28T17:20:51.730Z",
              "updated_at": "2024-10-29T10:15:10.718Z",
              "popular": false,
              "slug": "huyen-mang-thit",
              "deleted": null
          },
          {
              "id": 859,
              "name": "Huyện Vũng Liêm",
              "province_id": 86,
              "created_at": "2024-01-28T17:20:51.742Z",
              "updated_at": "2024-10-29T10:15:10.724Z",
              "popular": false,
              "slug": "huyen-vung-liem",
              "deleted": null
          },
          {
              "id": 860,
              "name": "Huyện Tam Bình",
              "province_id": 86,
              "created_at": "2024-01-28T17:20:51.754Z",
              "updated_at": "2024-10-29T10:15:10.729Z",
              "popular": false,
              "slug": "huyen-tam-binh",
              "deleted": null
          },
          {
              "id": 861,
              "name": "Thị xã Bình Minh",
              "province_id": 86,
              "created_at": "2024-01-28T17:20:51.768Z",
              "updated_at": "2024-10-29T10:15:10.735Z",
              "popular": false,
              "slug": "thi-xa-binh-minh",
              "deleted": null
          },
          {
              "id": 862,
              "name": "Huyện Trà Ôn",
              "province_id": 86,
              "created_at": "2024-01-28T17:20:51.783Z",
              "updated_at": "2024-10-29T10:15:10.740Z",
              "popular": false,
              "slug": "huyen-tra-on",
              "deleted": null
          },
          {
              "id": 863,
              "name": "Huyện Bình Tân",
              "province_id": 86,
              "created_at": "2024-01-28T17:20:51.795Z",
              "updated_at": "2024-10-29T10:15:10.746Z",
              "popular": false,
              "slug": "huyen-binh-tan",
              "deleted": null
          }
      ]
  },
  {
      "id": 87,
      "name": "Đồng Tháp",
      "region": "south",
      "created_at": "2024-01-28T17:20:42.880Z",
      "updated_at": "2024-10-29T10:15:06.654Z",
      "lat": 10.60017,
      "lon": 105.760067,
      "deleted": null,
      "slug": "dong-thap",
      "district": [
          {
              "id": 866,
              "name": "Thành phố Cao Lãnh",
              "province_id": 87,
              "created_at": "2024-01-28T17:20:51.808Z",
              "updated_at": "2024-10-29T10:15:10.752Z",
              "popular": false,
              "slug": "thanh-pho-cao-lanh",
              "deleted": null
          },
          {
              "id": 867,
              "name": "Thành phố Sa Đéc",
              "province_id": 87,
              "created_at": "2024-01-28T17:20:51.822Z",
              "updated_at": "2024-10-29T10:15:10.759Z",
              "popular": false,
              "slug": "thanh-pho-sa-dec",
              "deleted": null
          },
          {
              "id": 868,
              "name": "Thành phố Hồng Ngự",
              "province_id": 87,
              "created_at": "2024-01-28T17:20:51.833Z",
              "updated_at": "2024-10-29T10:15:10.764Z",
              "popular": false,
              "slug": "thanh-pho-hong-ngu",
              "deleted": null
          },
          {
              "id": 869,
              "name": "Huyện Tân Hồng",
              "province_id": 87,
              "created_at": "2024-01-28T17:20:51.846Z",
              "updated_at": "2024-10-29T10:15:10.769Z",
              "popular": false,
              "slug": "huyen-tan-hong",
              "deleted": null
          },
          {
              "id": 870,
              "name": "Huyện Hồng Ngự",
              "province_id": 87,
              "created_at": "2024-01-28T17:20:51.857Z",
              "updated_at": "2024-10-29T10:15:10.775Z",
              "popular": false,
              "slug": "huyen-hong-ngu",
              "deleted": null
          },
          {
              "id": 871,
              "name": "Huyện Tam Nông",
              "province_id": 87,
              "created_at": "2024-01-28T17:20:51.870Z",
              "updated_at": "2024-10-29T10:15:10.780Z",
              "popular": false,
              "slug": "huyen-tam-nong",
              "deleted": null
          },
          {
              "id": 872,
              "name": "Huyện Tháp Mười",
              "province_id": 87,
              "created_at": "2024-01-28T17:20:51.881Z",
              "updated_at": "2024-10-29T10:15:10.787Z",
              "popular": false,
              "slug": "huyen-thap-muoi",
              "deleted": null
          },
          {
              "id": 873,
              "name": "Huyện Cao Lãnh",
              "province_id": 87,
              "created_at": "2024-01-28T17:20:51.892Z",
              "updated_at": "2024-10-29T10:15:10.792Z",
              "popular": false,
              "slug": "huyen-cao-lanh",
              "deleted": null
          },
          {
              "id": 874,
              "name": "Huyện Thanh Bình",
              "province_id": 87,
              "created_at": "2024-01-28T17:20:51.904Z",
              "updated_at": "2024-10-29T10:15:10.799Z",
              "popular": false,
              "slug": "huyen-thanh-binh",
              "deleted": null
          },
          {
              "id": 875,
              "name": "Huyện Lấp Vò",
              "province_id": 87,
              "created_at": "2024-01-28T17:20:51.920Z",
              "updated_at": "2024-10-29T10:15:10.809Z",
              "popular": false,
              "slug": "huyen-lap-vo",
              "deleted": null
          },
          {
              "id": 876,
              "name": "Huyện Lai Vung",
              "province_id": 87,
              "created_at": "2024-01-28T17:20:51.931Z",
              "updated_at": "2024-10-29T10:15:10.814Z",
              "popular": false,
              "slug": "huyen-lai-vung",
              "deleted": null
          },
          {
              "id": 877,
              "name": "Huyện Châu Thành",
              "province_id": 87,
              "created_at": "2024-01-28T17:20:51.943Z",
              "updated_at": "2024-10-29T10:15:10.825Z",
              "popular": false,
              "slug": "huyen-chau-thanh",
              "deleted": null
          }
      ]
  },
  {
      "id": 89,
      "name": "An Giang",
      "region": "south",
      "created_at": "2024-01-28T17:20:42.890Z",
      "updated_at": "2024-10-29T10:15:06.660Z",
      "lat": 10.521583,
      "lon": 105.125896,
      "deleted": null,
      "slug": "an-giang",
      "district": [
          {
              "id": 883,
              "name": "Thành phố Long Xuyên",
              "province_id": 89,
              "created_at": "2024-01-28T17:20:51.953Z",
              "updated_at": "2024-10-29T10:15:10.831Z",
              "popular": false,
              "slug": "thanh-pho-long-xuyen",
              "deleted": null
          },
          {
              "id": 884,
              "name": "Thành phố Châu Đốc",
              "province_id": 89,
              "created_at": "2024-01-28T17:20:51.963Z",
              "updated_at": "2024-10-29T10:15:10.837Z",
              "popular": false,
              "slug": "thanh-pho-chau-doc",
              "deleted": null
          },
          {
              "id": 886,
              "name": "Huyện An Phú",
              "province_id": 89,
              "created_at": "2024-01-28T17:20:51.976Z",
              "updated_at": "2024-10-29T10:15:10.859Z",
              "popular": false,
              "slug": "huyen-an-phu",
              "deleted": null
          },
          {
              "id": 887,
              "name": "Thị xã Tân Châu",
              "province_id": 89,
              "created_at": "2024-01-28T17:20:51.990Z",
              "updated_at": "2024-10-29T10:15:10.865Z",
              "popular": false,
              "slug": "thi-xa-tan-chau",
              "deleted": null
          },
          {
              "id": 888,
              "name": "Huyện Phú Tân",
              "province_id": 89,
              "created_at": "2024-01-28T17:20:52.002Z",
              "updated_at": "2024-10-29T10:15:10.870Z",
              "popular": false,
              "slug": "huyen-phu-tan",
              "deleted": null
          },
          {
              "id": 889,
              "name": "Huyện Châu Phú",
              "province_id": 89,
              "created_at": "2024-01-28T17:20:52.016Z",
              "updated_at": "2024-10-29T10:15:10.876Z",
              "popular": false,
              "slug": "huyen-chau-phu",
              "deleted": null
          },
          {
              "id": 890,
              "name": "Thị xã Tịnh Biên",
              "province_id": 89,
              "created_at": "2024-01-28T17:20:52.029Z",
              "updated_at": "2024-10-29T10:15:10.881Z",
              "popular": false,
              "slug": "thi-xa-tinh-bien",
              "deleted": null
          },
          {
              "id": 891,
              "name": "Huyện Tri Tôn",
              "province_id": 89,
              "created_at": "2024-01-28T17:20:52.044Z",
              "updated_at": "2024-10-29T10:15:10.886Z",
              "popular": false,
              "slug": "huyen-tri-ton",
              "deleted": null
          },
          {
              "id": 892,
              "name": "Huyện Châu Thành",
              "province_id": 89,
              "created_at": "2024-01-28T17:20:52.058Z",
              "updated_at": "2024-10-29T10:15:10.891Z",
              "popular": false,
              "slug": "huyen-chau-thanh",
              "deleted": null
          },
          {
              "id": 893,
              "name": "Huyện Chợ Mới",
              "province_id": 89,
              "created_at": "2024-01-28T17:20:52.070Z",
              "updated_at": "2024-10-29T10:15:10.897Z",
              "popular": false,
              "slug": "huyen-cho-moi",
              "deleted": null
          },
          {
              "id": 894,
              "name": "Huyện Thoại Sơn",
              "province_id": 89,
              "created_at": "2024-01-28T17:20:52.084Z",
              "updated_at": "2024-10-29T10:15:10.902Z",
              "popular": false,
              "slug": "huyen-thoai-son",
              "deleted": null
          }
      ]
  },
  {
      "id": 91,
      "name": "Kiên Giang",
      "region": "south",
      "created_at": "2024-01-28T17:20:42.904Z",
      "updated_at": "2024-10-29T10:15:06.665Z",
      "lat": 10.015089,
      "lon": 105.078899,
      "deleted": null,
      "slug": "kien-giang",
      "district": [
          {
              "id": 899,
              "name": "Thành phố Rạch Giá",
              "province_id": 91,
              "created_at": "2024-01-28T17:20:52.100Z",
              "updated_at": "2024-10-29T10:15:10.908Z",
              "popular": false,
              "slug": "thanh-pho-rach-gia",
              "deleted": null
          },
          {
              "id": 900,
              "name": "Thành phố Hà Tiên",
              "province_id": 91,
              "created_at": "2024-01-28T17:20:52.112Z",
              "updated_at": "2024-10-29T10:15:10.915Z",
              "popular": false,
              "slug": "thanh-pho-ha-tien",
              "deleted": null
          },
          {
              "id": 902,
              "name": "Huyện Kiên Lương",
              "province_id": 91,
              "created_at": "2024-01-28T17:20:52.136Z",
              "updated_at": "2024-10-29T10:15:10.920Z",
              "popular": false,
              "slug": "huyen-kien-luong",
              "deleted": null
          },
          {
              "id": 903,
              "name": "Huyện Hòn Đất",
              "province_id": 91,
              "created_at": "2024-01-28T17:20:52.168Z",
              "updated_at": "2024-10-29T10:15:10.927Z",
              "popular": false,
              "slug": "huyen-hon-dat",
              "deleted": null
          },
          {
              "id": 904,
              "name": "Huyện Tân Hiệp",
              "province_id": 91,
              "created_at": "2024-01-28T17:20:52.187Z",
              "updated_at": "2024-10-29T10:15:10.933Z",
              "popular": false,
              "slug": "huyen-tan-hiep",
              "deleted": null
          },
          {
              "id": 905,
              "name": "Huyện Châu Thành",
              "province_id": 91,
              "created_at": "2024-01-28T17:20:52.204Z",
              "updated_at": "2024-10-29T10:15:10.939Z",
              "popular": false,
              "slug": "huyen-chau-thanh",
              "deleted": null
          },
          {
              "id": 906,
              "name": "Huyện Giồng Riềng",
              "province_id": 91,
              "created_at": "2024-01-28T17:20:52.216Z",
              "updated_at": "2024-10-29T10:15:10.945Z",
              "popular": false,
              "slug": "huyen-giong-rieng",
              "deleted": null
          },
          {
              "id": 907,
              "name": "Huyện Gò Quao",
              "province_id": 91,
              "created_at": "2024-01-28T17:20:52.232Z",
              "updated_at": "2024-10-29T10:15:10.962Z",
              "popular": false,
              "slug": "huyen-go-quao",
              "deleted": null
          },
          {
              "id": 908,
              "name": "Huyện An Biên",
              "province_id": 91,
              "created_at": "2024-01-28T17:20:52.246Z",
              "updated_at": "2024-10-29T10:15:10.970Z",
              "popular": false,
              "slug": "huyen-an-bien",
              "deleted": null
          },
          {
              "id": 909,
              "name": "Huyện An Minh",
              "province_id": 91,
              "created_at": "2024-01-28T17:20:52.260Z",
              "updated_at": "2024-10-29T10:15:10.980Z",
              "popular": false,
              "slug": "huyen-an-minh",
              "deleted": null
          },
          {
              "id": 910,
              "name": "Huyện Vĩnh Thuận",
              "province_id": 91,
              "created_at": "2024-01-28T17:20:52.278Z",
              "updated_at": "2024-10-29T10:15:10.986Z",
              "popular": false,
              "slug": "huyen-vinh-thuan",
              "deleted": null
          },
          {
              "id": 911,
              "name": "Thành phố Phú Quốc",
              "province_id": 91,
              "created_at": "2024-01-28T17:20:52.292Z",
              "updated_at": "2024-10-29T10:15:10.995Z",
              "popular": false,
              "slug": "thanh-pho-phu-quoc",
              "deleted": null
          },
          {
              "id": 912,
              "name": "Huyện Kiên Hải",
              "province_id": 91,
              "created_at": "2024-01-28T17:20:52.307Z",
              "updated_at": "2024-10-29T10:15:11.001Z",
              "popular": false,
              "slug": "huyen-kien-hai",
              "deleted": null
          },
          {
              "id": 913,
              "name": "Huyện U Minh Thượng",
              "province_id": 91,
              "created_at": "2024-01-28T17:20:52.320Z",
              "updated_at": "2024-10-29T10:15:11.021Z",
              "popular": false,
              "slug": "huyen-u-minh-thuong",
              "deleted": null
          },
          {
              "id": 914,
              "name": "Huyện Giang Thành",
              "province_id": 91,
              "created_at": "2024-01-28T17:20:52.332Z",
              "updated_at": "2024-10-29T10:15:11.035Z",
              "popular": false,
              "slug": "huyen-giang-thanh",
              "deleted": null
          }
      ]
  },
  {
      "id": 92,
      "name": "Cần Thơ",
      "region": "south",
      "created_at": "2024-01-28T17:20:42.915Z",
      "updated_at": "2024-10-29T10:15:06.672Z",
      "lat": 10.0451628,
      "lon": 105.7468535,
      "deleted": null,
      "slug": "can-tho",
      "district": [
          {
              "id": 916,
              "name": "Quận Ninh Kiều",
              "province_id": 92,
              "created_at": "2024-01-28T17:20:52.349Z",
              "updated_at": "2024-10-29T10:15:11.041Z",
              "popular": false,
              "slug": "quan-ninh-kieu",
              "deleted": null
          },
          {
              "id": 917,
              "name": "Quận Ô Môn",
              "province_id": 92,
              "created_at": "2024-01-28T17:20:52.362Z",
              "updated_at": "2024-10-29T10:15:11.046Z",
              "popular": false,
              "slug": "quan-o-mon",
              "deleted": null
          },
          {
              "id": 918,
              "name": "Quận Bình Thuỷ",
              "province_id": 92,
              "created_at": "2024-01-28T17:20:52.379Z",
              "updated_at": "2024-10-29T10:15:11.051Z",
              "popular": false,
              "slug": "quan-binh-thuy",
              "deleted": null
          },
          {
              "id": 919,
              "name": "Quận Cái Răng",
              "province_id": 92,
              "created_at": "2024-01-28T17:20:52.395Z",
              "updated_at": "2024-10-29T10:15:11.056Z",
              "popular": false,
              "slug": "quan-cai-rang",
              "deleted": null
          },
          {
              "id": 923,
              "name": "Quận Thốt Nốt",
              "province_id": 92,
              "created_at": "2024-01-28T17:20:52.412Z",
              "updated_at": "2024-10-29T10:15:11.074Z",
              "popular": false,
              "slug": "quan-thot-not",
              "deleted": null
          },
          {
              "id": 924,
              "name": "Huyện Vĩnh Thạnh",
              "province_id": 92,
              "created_at": "2024-01-28T17:20:52.430Z",
              "updated_at": "2024-10-29T10:15:11.080Z",
              "popular": false,
              "slug": "huyen-vinh-thanh",
              "deleted": null
          },
          {
              "id": 925,
              "name": "Huyện Cờ Đỏ",
              "province_id": 92,
              "created_at": "2024-01-28T17:20:52.443Z",
              "updated_at": "2024-10-29T10:15:11.086Z",
              "popular": false,
              "slug": "huyen-co-do",
              "deleted": null
          },
          {
              "id": 926,
              "name": "Huyện Phong Điền",
              "province_id": 92,
              "created_at": "2024-01-28T17:20:52.456Z",
              "updated_at": "2024-10-29T10:15:11.091Z",
              "popular": false,
              "slug": "huyen-phong-dien",
              "deleted": null
          },
          {
              "id": 927,
              "name": "Huyện Thới Lai",
              "province_id": 92,
              "created_at": "2024-01-28T17:20:52.469Z",
              "updated_at": "2024-10-29T10:15:11.096Z",
              "popular": false,
              "slug": "huyen-thoi-lai",
              "deleted": null
          }
      ]
  },
  {
      "id": 93,
      "name": "Hậu Giang",
      "region": "south",
      "created_at": "2024-01-28T17:20:42.927Z",
      "updated_at": "2024-10-29T10:15:06.678Z",
      "lat": 9.784634,
      "lon": 105.470319,
      "deleted": null,
      "slug": "hau-giang",
      "district": [
          {
              "id": 930,
              "name": "Thành phố Vị Thanh",
              "province_id": 93,
              "created_at": "2024-01-28T17:20:52.480Z",
              "updated_at": "2024-10-29T10:15:11.100Z",
              "popular": false,
              "slug": "thanh-pho-vi-thanh",
              "deleted": null
          },
          {
              "id": 931,
              "name": "Thành phố Ngã Bảy",
              "province_id": 93,
              "created_at": "2024-01-28T17:20:52.494Z",
              "updated_at": "2024-10-29T10:15:11.104Z",
              "popular": false,
              "slug": "thanh-pho-nga-bay",
              "deleted": null
          },
          {
              "id": 932,
              "name": "Huyện Châu Thành A",
              "province_id": 93,
              "created_at": "2024-01-28T17:20:52.508Z",
              "updated_at": "2024-10-29T10:15:11.109Z",
              "popular": false,
              "slug": "huyen-chau-thanh-a",
              "deleted": null
          },
          {
              "id": 933,
              "name": "Huyện Châu Thành",
              "province_id": 93,
              "created_at": "2024-01-28T17:20:52.523Z",
              "updated_at": "2024-10-29T10:15:11.115Z",
              "popular": false,
              "slug": "huyen-chau-thanh",
              "deleted": null
          },
          {
              "id": 934,
              "name": "Huyện Phụng Hiệp",
              "province_id": 93,
              "created_at": "2024-01-28T17:20:52.534Z",
              "updated_at": "2024-10-29T10:15:11.130Z",
              "popular": false,
              "slug": "huyen-phung-hiep",
              "deleted": null
          },
          {
              "id": 935,
              "name": "Huyện Vị Thuỷ",
              "province_id": 93,
              "created_at": "2024-01-28T17:20:52.546Z",
              "updated_at": "2024-10-29T10:15:11.136Z",
              "popular": false,
              "slug": "huyen-vi-thuy",
              "deleted": null
          },
          {
              "id": 936,
              "name": "Huyện Long Mỹ",
              "province_id": 93,
              "created_at": "2024-01-28T17:20:52.560Z",
              "updated_at": "2024-10-29T10:15:11.140Z",
              "popular": false,
              "slug": "huyen-long-my",
              "deleted": null
          },
          {
              "id": 937,
              "name": "Thị xã Long Mỹ",
              "province_id": 93,
              "created_at": "2024-01-28T17:20:52.573Z",
              "updated_at": "2024-10-29T10:15:11.145Z",
              "popular": false,
              "slug": "thi-xa-long-my",
              "deleted": null
          }
      ]
  },
  {
      "id": 94,
      "name": "Sóc Trăng",
      "region": "south",
      "created_at": "2024-01-28T17:20:42.937Z",
      "updated_at": "2024-10-29T10:15:06.683Z",
      "lat": 9.603554,
      "lon": 105.980209,
      "deleted": null,
      "slug": "soc-trang",
      "district": [
          {
              "id": 941,
              "name": "Thành phố Sóc Trăng",
              "province_id": 94,
              "created_at": "2024-01-28T17:20:52.588Z",
              "updated_at": "2024-10-29T10:15:11.151Z",
              "popular": false,
              "slug": "thanh-pho-soc-trang",
              "deleted": null
          },
          {
              "id": 942,
              "name": "Huyện Châu Thành",
              "province_id": 94,
              "created_at": "2024-01-28T17:20:52.606Z",
              "updated_at": "2024-10-29T10:15:11.156Z",
              "popular": false,
              "slug": "huyen-chau-thanh",
              "deleted": null
          },
          {
              "id": 943,
              "name": "Huyện Kế Sách",
              "province_id": 94,
              "created_at": "2024-01-28T17:20:52.619Z",
              "updated_at": "2024-10-29T10:15:11.162Z",
              "popular": false,
              "slug": "huyen-ke-sach",
              "deleted": null
          },
          {
              "id": 944,
              "name": "Huyện Mỹ Tú",
              "province_id": 94,
              "created_at": "2024-01-28T17:20:52.634Z",
              "updated_at": "2024-10-29T10:15:11.167Z",
              "popular": false,
              "slug": "huyen-my-tu",
              "deleted": null
          },
          {
              "id": 945,
              "name": "Huyện Cù Lao Dung",
              "province_id": 94,
              "created_at": "2024-01-28T17:20:52.652Z",
              "updated_at": "2024-10-29T10:15:11.173Z",
              "popular": false,
              "slug": "huyen-cu-lao-dung",
              "deleted": null
          },
          {
              "id": 946,
              "name": "Huyện Long Phú",
              "province_id": 94,
              "created_at": "2024-01-28T17:20:52.665Z",
              "updated_at": "2024-10-29T10:15:11.179Z",
              "popular": false,
              "slug": "huyen-long-phu",
              "deleted": null
          },
          {
              "id": 947,
              "name": "Huyện Mỹ Xuyên",
              "province_id": 94,
              "created_at": "2024-01-28T17:20:52.684Z",
              "updated_at": "2024-10-29T10:15:11.185Z",
              "popular": false,
              "slug": "huyen-my-xuyen",
              "deleted": null
          },
          {
              "id": 948,
              "name": "Thị xã Ngã Năm",
              "province_id": 94,
              "created_at": "2024-01-28T17:20:52.696Z",
              "updated_at": "2024-10-29T10:15:11.191Z",
              "popular": false,
              "slug": "thi-xa-nga-nam",
              "deleted": null
          },
          {
              "id": 949,
              "name": "Huyện Thạnh Trị",
              "province_id": 94,
              "created_at": "2024-01-28T17:20:52.708Z",
              "updated_at": "2024-10-29T10:15:11.199Z",
              "popular": false,
              "slug": "huyen-thanh-tri",
              "deleted": null
          },
          {
              "id": 950,
              "name": "Thị xã Vĩnh Châu",
              "province_id": 94,
              "created_at": "2024-01-28T17:20:52.721Z",
              "updated_at": "2024-10-29T10:15:11.205Z",
              "popular": false,
              "slug": "thi-xa-vinh-chau",
              "deleted": null
          },
          {
              "id": 951,
              "name": "Huyện Trần Đề",
              "province_id": 94,
              "created_at": "2024-01-28T17:20:52.734Z",
              "updated_at": "2024-10-29T10:15:11.213Z",
              "popular": false,
              "slug": "huyen-tran-de",
              "deleted": null
          }
      ]
  },
  {
      "id": 95,
      "name": "Bạc Liêu",
      "region": "south",
      "created_at": "2024-01-28T17:20:42.948Z",
      "updated_at": "2024-10-29T10:15:06.689Z",
      "lat": 9.285275,
      "lon": 105.724729,
      "deleted": null,
      "slug": "bac-lieu",
      "district": [
          {
              "id": 954,
              "name": "Thành phố Bạc Liêu",
              "province_id": 95,
              "created_at": "2024-01-28T17:20:52.748Z",
              "updated_at": "2024-10-29T10:15:11.221Z",
              "popular": false,
              "slug": "thanh-pho-bac-lieu",
              "deleted": null
          },
          {
              "id": 956,
              "name": "Huyện Hồng Dân",
              "province_id": 95,
              "created_at": "2024-01-28T17:20:52.764Z",
              "updated_at": "2024-10-29T10:15:11.226Z",
              "popular": false,
              "slug": "huyen-hong-dan",
              "deleted": null
          },
          {
              "id": 957,
              "name": "Huyện Phước Long",
              "province_id": 95,
              "created_at": "2024-01-28T17:20:52.782Z",
              "updated_at": "2024-10-29T10:15:11.230Z",
              "popular": false,
              "slug": "huyen-phuoc-long",
              "deleted": null
          },
          {
              "id": 958,
              "name": "Huyện Vĩnh Lợi",
              "province_id": 95,
              "created_at": "2024-01-28T17:20:52.794Z",
              "updated_at": "2024-10-29T10:15:11.235Z",
              "popular": false,
              "slug": "huyen-vinh-loi",
              "deleted": null
          },
          {
              "id": 959,
              "name": "Thị xã Giá Rai",
              "province_id": 95,
              "created_at": "2024-01-28T17:20:52.808Z",
              "updated_at": "2024-10-29T10:15:11.240Z",
              "popular": false,
              "slug": "thi-xa-gia-rai",
              "deleted": null
          },
          {
              "id": 960,
              "name": "Huyện Đông Hải",
              "province_id": 95,
              "created_at": "2024-01-28T17:20:52.825Z",
              "updated_at": "2024-10-29T10:15:11.244Z",
              "popular": false,
              "slug": "huyen-dong-hai",
              "deleted": null
          },
          {
              "id": 961,
              "name": "Huyện Hoà Bình",
              "province_id": 95,
              "created_at": "2024-01-28T17:20:52.839Z",
              "updated_at": "2024-10-29T10:15:11.249Z",
              "popular": false,
              "slug": "huyen-hoa-binh",
              "deleted": null
          }
      ]
  },
  {
      "id": 96,
      "name": "Cà Mau",
      "region": "south",
      "created_at": "2024-01-28T17:20:42.962Z",
      "updated_at": "2024-10-29T10:15:06.695Z",
      "lat": 9.176822,
      "lon": 105.151365,
      "deleted": null,
      "slug": "ca-mau",
      "district": [
          {
              "id": 964,
              "name": "Thành phố Cà Mau",
              "province_id": 96,
              "created_at": "2024-01-28T17:20:52.856Z",
              "updated_at": "2024-10-29T10:15:11.255Z",
              "popular": false,
              "slug": "thanh-pho-ca-mau",
              "deleted": null
          },
          {
              "id": 966,
              "name": "Huyện U Minh",
              "province_id": 96,
              "created_at": "2024-01-28T17:20:52.871Z",
              "updated_at": "2024-10-29T10:15:11.260Z",
              "popular": false,
              "slug": "huyen-u-minh",
              "deleted": null
          },
          {
              "id": 967,
              "name": "Huyện Thới Bình",
              "province_id": 96,
              "created_at": "2024-01-28T17:20:52.883Z",
              "updated_at": "2024-10-29T10:15:11.265Z",
              "popular": false,
              "slug": "huyen-thoi-binh",
              "deleted": null
          },
          {
              "id": 968,
              "name": "Huyện Trần Văn Thời",
              "province_id": 96,
              "created_at": "2024-01-28T17:20:52.896Z",
              "updated_at": "2024-10-29T10:15:11.271Z",
              "popular": false,
              "slug": "huyen-tran-van-thoi",
              "deleted": null
          },
          {
              "id": 969,
              "name": "Huyện Cái Nước",
              "province_id": 96,
              "created_at": "2024-01-28T17:20:52.912Z",
              "updated_at": "2024-10-29T10:15:11.282Z",
              "popular": false,
              "slug": "huyen-cai-nuoc",
              "deleted": null
          },
          {
              "id": 970,
              "name": "Huyện Đầm Dơi",
              "province_id": 96,
              "created_at": "2024-01-28T17:20:52.923Z",
              "updated_at": "2024-10-29T10:15:11.288Z",
              "popular": false,
              "slug": "huyen-dam-doi",
              "deleted": null
          },
          {
              "id": 971,
              "name": "Huyện Năm Căn",
              "province_id": 96,
              "created_at": "2024-01-28T17:20:52.938Z",
              "updated_at": "2024-10-29T10:15:11.294Z",
              "popular": false,
              "slug": "huyen-nam-can",
              "deleted": null
          },
          {
              "id": 972,
              "name": "Huyện Phú Tân",
              "province_id": 96,
              "created_at": "2024-01-28T17:20:52.951Z",
              "updated_at": "2024-10-29T10:15:11.299Z",
              "popular": false,
              "slug": "huyen-phu-tan",
              "deleted": null
          },
          {
              "id": 973,
              "name": "Huyện Ngọc Hiển",
              "province_id": 96,
              "created_at": "2024-01-28T17:20:52.964Z",
              "updated_at": "2024-10-29T10:15:11.305Z",
              "popular": false,
              "slug": "huyen-ngoc-hien",
              "deleted": null
          }
      ]
  }
]
export const SERVICE = [
  {
      "id": 147483649,
      "name": "Niềng răng",
      "created_at": "2024-01-28T17:20:53.320Z",
      "updated_at": "2024-10-29T10:15:06.716Z",
      "sort": 1,
      "deleted": null,
      "slug": "nieng-rang"
  },
  {
      "id": 147483650,
      "name": "Trồng răng Implant",
      "created_at": "2024-01-28T17:20:53.332Z",
      "updated_at": "2024-10-29T10:15:06.786Z",
      "sort": 2,
      "deleted": null,
      "slug": "trong-rang-implant"
  },
  {
      "id": 147483647,
      "name": "Bọc răng sứ",
      "created_at": "2024-01-28T17:20:53.296Z",
      "updated_at": "2024-10-29T10:15:06.721Z",
      "sort": 3,
      "deleted": null,
      "slug": "boc-rang-su"
  },
  {
      "id": 3,
      "name": "Nhổ răng",
      "created_at": "2024-01-31T02:34:44.213Z",
      "updated_at": "2024-10-29T10:15:06.795Z",
      "sort": 4,
      "deleted": null,
      "slug": "nho-rang"
  },
  {
      "id": 147483653,
      "name": "Tẩy trắng răng",
      "created_at": "2024-01-28T17:20:53.369Z",
      "updated_at": "2024-10-29T10:15:06.790Z",
      "sort": 5,
      "deleted": null,
      "slug": "tay-trang-rang"
  },
  {
      "id": 147483652,
      "name": "Trám răng",
      "created_at": "2024-01-28T17:20:53.358Z",
      "updated_at": "2024-10-29T10:15:06.725Z",
      "sort": 6,
      "deleted": null,
      "slug": "tram-rang"
  },
  {
      "id": 147483648,
      "name": "Dán răng sứ",
      "created_at": "2024-01-28T17:20:53.308Z",
      "updated_at": "2024-10-29T10:15:06.730Z",
      "sort": 7,
      "deleted": null,
      "slug": "dan-rang-su"
  },
  {
      "id": 21,
      "name": "Lấy cao răng",
      "created_at": "2024-02-01T06:31:44.127Z",
      "updated_at": "2024-10-29T10:15:06.804Z",
      "sort": 8,
      "deleted": null,
      "slug": "lay-cao-rang"
  },
  {
      "id": 12,
      "name": "Điều trị cười hở lợi",
      "created_at": "2024-01-31T08:16:43.977Z",
      "updated_at": "2024-10-29T10:15:06.799Z",
      "sort": 9,
      "deleted": null,
      "slug": "dieu-tri-cuoi-ho-loi"
  },
  {
      "id": 15,
      "name": "Chụp X-Quang răng",
      "created_at": "2024-01-31T13:31:38.718Z",
      "updated_at": "2024-10-29T10:15:06.773Z",
      "sort": null,
      "deleted": null,
      "slug": "chup-x-quang-rang"
  },
  {
      "id": 24,
      "name": "Điều trị rối loạn khớp thái dương hàm",
      "created_at": "2024-03-18T09:08:13.124Z",
      "updated_at": "2024-10-29T10:15:06.777Z",
      "sort": null,
      "deleted": null,
      "slug": "dieu-tri-roi-loan-khop-thai-duong-ham"
  },
  {
      "id": 25,
      "name": "Phẫu thuật hàm mặt",
      "created_at": "2024-03-21T02:22:26.843Z",
      "updated_at": "2024-10-29T10:15:06.781Z",
      "sort": null,
      "deleted": null,
      "slug": "phau-thuat-ham-mat"
  },
  {
      "id": 17,
      "name": "Đính đá",
      "created_at": "2024-01-31T13:32:08.023Z",
      "updated_at": "2024-10-29T10:15:06.749Z",
      "sort": null,
      "deleted": null,
      "slug": "dinh-da"
  },
  {
      "id": 147483655,
      "name": "Điều trị nha chu",
      "created_at": "2024-01-28T17:20:53.395Z",
      "updated_at": "2024-10-29T10:15:06.736Z",
      "sort": null,
      "deleted": null,
      "slug": "dieu-tri-nha-chu"
  },
  {
      "id": 11,
      "name": "Điều trị tủy",
      "created_at": "2024-01-31T08:16:22.894Z",
      "updated_at": "2024-10-29T10:15:06.741Z",
      "sort": null,
      "deleted": null,
      "slug": "dieu-tri-tuy"
  },
  {
      "id": 4,
      "name": "Khám răng miệng",
      "created_at": "2024-01-31T08:02:05.216Z",
      "updated_at": "2024-10-29T10:15:06.745Z",
      "sort": null,
      "deleted": null,
      "slug": "kham-rang-mieng"
  },
  {
      "id": 147483651,
      "name": "Nhổ răng khôn",
      "created_at": "2024-01-28T17:20:53.345Z",
      "updated_at": "2024-10-29T10:15:06.711Z",
      "sort": null,
      "deleted": null,
      "slug": "nho-rang-khon"
  },
  {
      "id": 20,
      "name": "Phục hình răng cố định",
      "created_at": "2024-01-31T14:04:10.668Z",
      "updated_at": "2024-10-29T10:15:06.754Z",
      "sort": null,
      "deleted": null,
      "slug": "phuc-hinh-rang-co-dinh"
  },
  {
      "id": 22,
      "name": "Đánh bóng răng",
      "created_at": "2024-02-01T06:31:50.658Z",
      "updated_at": "2024-10-29T10:15:06.758Z",
      "sort": null,
      "deleted": null,
      "slug": "danh-bong-rang"
  },
  {
      "id": 23,
      "name": "Răng giả tháo lắp",
      "created_at": "2024-02-01T06:36:26.593Z",
      "updated_at": "2024-10-29T10:15:06.763Z",
      "sort": null,
      "deleted": null,
      "slug": "rang-gia-thao-lap"
  },
  {
      "id": 16,
      "name": "Điều trị viêm nướu",
      "created_at": "2024-01-31T13:31:53.931Z",
      "updated_at": "2024-10-29T10:15:06.768Z",
      "sort": null,
      "deleted": null,
      "slug": "dieu-tri-viem-nuou"
  }
]
export const BLOG_CATEGORIES = [
  {
      "term_id": 14,
      "name": "Khuyến mãi",
      "slug": "khuyen-mai",
      "term_group": 0,
      "term_taxonomy_id": 14,
      "taxonomy": "category",
      "description": "Cập nhật các tin tức khuyến mãi, ưu đãi của các nha khoa tại Việt Nam, giúp bạn tiếp cận được những gói giảm giá tốt nhất!",
      "parent": 0,
      "count": 8,
      "filter": "raw"
  },
  {
      "term_id": 2,
      "name": "Kiến thức nha khoa",
      "slug": "kien-thuc-nha-khoa",
      "term_group": 0,
      "term_taxonomy_id": 2,
      "taxonomy": "category",
      "description": "",
      "parent": 0,
      "count": 273,
      "filter": "raw"
  },
  {
      "term_id": 4,
      "name": "Kinh doanh nha khoa",
      "slug": "kinh-doanh-nha-khoa",
      "term_group": 0,
      "term_taxonomy_id": 4,
      "taxonomy": "category",
      "description": "",
      "parent": 0,
      "count": 7,
      "filter": "raw"
  },
  {
      "term_id": 3,
      "name": "Review nha khoa",
      "slug": "review-nha-khoa",
      "term_group": 0,
      "term_taxonomy_id": 3,
      "taxonomy": "category",
      "description": "",
      "parent": 0,
      "count": 128,
      "filter": "raw"
  }
]
export const FIND_CLINIC_BY = [
  {
    id: 1,
    name: "Tìm theo khu vực",
    slug: "tim-theo-tinh",
  },
  {
    id: 2,
    name: "Tìm theo dịch vụ",
    slug: "tim-theo-dich-vu",
  },
];
